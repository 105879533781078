import { makeStyles } from 'tss-react/mui';
import { Grid, Theme } from '@mui/material';
import { DocumentsPanel, InspectionLeftPanel, ReportPanel } from 'components/pageElements';
import { RightPanel } from 'components';
import { GVSnackbar } from 'components/common';
import { getStyleVariables } from 'styles/vars';
import DifferenceExpansionPanel from 'components/DifferenceExpansionPanel/DifferenceExpansionPanel';
import { isResultUrl } from 'utils/location';
import { getIsResultsPanelHidden } from 'store';
import { useSelector } from 'react-redux';
import { useRef } from 'react';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    rightPanel: {
      flex: 1,
      height: '100%',
      maxHeight: '100%',
    },
    leftPanel: {
      width: styleVariables.leftPanel.width,
      height: '100%',
      maxHeight: '100%',
    },

    contents: {
      height: `calc(100% - ${theme.spacing(8)})`,
    },
  };
});

export const InspectionPage = () => {
  const { classes } = useStyles();
  const isResultsHidden = useSelector(getIsResultsPanelHidden);
  const leftPanelRef = useRef(null);

  return (
    <Grid item container className={classes.contents}>
      <Grid item className={classes.leftPanel} sx={{ marginLeft: isResultsHidden && isResultUrl() && '-380px' }}>
        <InspectionLeftPanel />
        <div ref={leftPanelRef} />
      </Grid>
      <Grid item className={classes.rightPanel}>
        <RightPanel>
          <>
            <ReportPanel />
            <DocumentsPanel />
            <DifferenceExpansionPanel anchorEl={leftPanelRef} />
          </>
        </RightPanel>
      </Grid>
      <GVSnackbar />
    </Grid>
  );
};
