import { Grid, Theme, alpha, List, Typography, ListItemButton } from '@mui/material';
import { VisibilityOutlined, VisibilityOffOutlined, ErrorOutline } from '@mui/icons-material';
import { GVTypography } from 'components/lib';
import { getStyleVariables, opacities } from 'styles/vars';
import cx from 'classnames';
import { DocumentType, Layer, DocumentTypes, Separation } from 'types';
import { useDispatch, useSelector } from 'react-redux';
import {
  inspection,
  getSourceLayers,
  getTargetLayers,
  getTextProcessStatus,
  getSourceSeparations,
  getTargetSeparations,
} from 'store';
import { PDFManagerFactory } from 'pdftron';
import Layers from 'pdftron/core/Layers';
import { makeStyles } from 'tss-react/mui';
import Separations from 'pdftron/core/Separations';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    list: {
      padding: theme.spacing(0),
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    listItem: {
      borderRadius: '1px',
      color: theme.palette.text.primary,
      height: theme.spacing(2.5),
      display: 'flex',
      padding: theme.spacing(0, 0, 0, 1),
      margin: theme.spacing(0.25, 0),
      gap: theme.spacing(1),
      '&:hover': {
        backgroundColor: alpha(theme.palette.text.secondary, 0.25),
        cursor: 'pointer',
      },
    },
    textButton: {
      fontWeight: 600,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    visible: {
      opacity: opacities.superhigh,
    },
    hidden: {
      opacity: opacities.disabled,
      '&:hover': { opacity: opacities.disabled },
    },
    emptyStateText: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      gap: theme.spacing(0.6),
      padding: theme.spacing(1, 6),
      marginTop: theme.spacing(6),
    },
    emptyStateIcon: {
      color: theme.palette.text.primary,
    },
    title: {
      color: styleVariables.colors.hint,
      margin: theme.spacing(0.25, 0),
      fontWeight: 600,
    },
    colorSwatch: {
      minWidth: theme.spacing(1.25),
      height: theme.spacing(1.25),
      alignSelf: 'center',
    },
    childLayer: {
      marginLeft: theme.spacing(3.5),
    },
    grandchildLayer: {
      marginLeft: theme.spacing(7),
    },
  };
});

interface OptionalContentItemProps {
  action: Function;
  name: string;
  visible: boolean;
  rgb?: number[];
  index: number;
}

const OptionalContentItem = (props: OptionalContentItemProps) => {
  const { classes } = useStyles();
  const documentProcessed = useSelector(getTextProcessStatus);
  const handleClick = () => {
    props.action(props.index);
  };
  return (
    <ListItemButton
      onClick={handleClick}
      className={cx(classes.listItem, props.visible ? classes.visible : classes.hidden)}
      disabled={!documentProcessed}
    >
      {props.visible ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
      {props.rgb && (
        <span
          style={props.rgb && { backgroundColor: `rgb(${props.rgb[0]}, ${props.rgb[1]}, ${props.rgb[2]})` }}
          className={classes.colorSwatch}
        ></span>
      )}
      <Typography className={cx(classes.textButton, props.visible ? classes.visible : classes.hidden)}>
        {props.name}
      </Typography>
    </ListItemButton>
  );
};

const OptionalContentEmpty = () => {
  const { classes } = useStyles();
  const text = 'Either the file does not contain layers OR layer control is unavailable for this file type';

  return (
    <Grid item className={classes.emptyStateText}>
      <ErrorOutline className={classes.emptyStateIcon} />
      <GVTypography variant="body1" color="primary" emphasis="medium">
        {text}
      </GVTypography>
    </Grid>
  );
};

interface OptionalContentListProps {
  documentType: DocumentType;
  separationsEnabled: boolean;
  handleSeparationsToggle: (enable: boolean) => void;
}

const OptionalContentList = ({
  documentType,
  separationsEnabled,
  handleSeparationsToggle,
}: OptionalContentListProps) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const layers = useSelector(documentType === DocumentTypes.source ? getSourceLayers : getTargetLayers);
  const separations = useSelector(documentType === DocumentTypes.source ? getSourceSeparations : getTargetSeparations);
  const otherDocSeparations = useSelector(
    documentType === DocumentTypes.source ? getTargetSeparations : getSourceSeparations,
  );

  const toggleVisibility = (
    index: number,
    contentType: 'layer' | 'separation',
    childIndex?: number,
    grandchildIndex?: number,
  ) => {
    const instance = PDFManagerFactory.getViewer(documentType)?.instance;
    if (!instance) return;

    if (contentType === 'layer') {
      const { refreshLayerView } = new Layers(instance);

      const updatedLayers: Layer[] = JSON.parse(JSON.stringify(layers));

      // must check for undefined bc index value can be 0
      let layerToToggle;
      if (grandchildIndex !== undefined && childIndex !== undefined) {
        layerToToggle = updatedLayers[index].children?.[childIndex]?.children?.[grandchildIndex] as Layer;
      } else if (childIndex !== undefined) {
        layerToToggle = updatedLayers[index].children?.[childIndex] as Layer;
      } else {
        layerToToggle = updatedLayers[index];
      }
      layerToToggle = toggleLayerAndChild(layerToToggle, !layerToToggle.visible);
      dispatch(inspection.actions.setLayers({ documentType, layers: updatedLayers }));
      refreshLayerView(updatedLayers);
    } else {
      const { refreshSeparationView } = new Separations(instance);

      const updatedSeparations: Separation[] = JSON.parse(JSON.stringify(separations));
      updatedSeparations[index].enabled = !updatedSeparations[index].enabled;

      const updatedSeparation: Separation = updatedSeparations[index];

      // update visibility
      refreshSeparationView(updatedSeparation);
      dispatch(inspection.actions.setSeparations({ documentType, separations: updatedSeparations }));

      // then enable/disable seps if needed
      const allSeparations = [...(updatedSeparations || []), ...(otherDocSeparations || [])];
      const separationDisabled = allSeparations.some((separation) => !separation.enabled);

      if (separationDisabled !== separationsEnabled) {
        handleSeparationsToggle(separationDisabled);
      }
    }
  };

  const toggleLayerAndChild = (layer: Layer, visibility: boolean) => {
    layer.visible = visibility;
    if (layer.children?.length) {
      layer.children.forEach((child) => {
        child.visible = visibility;
        toggleLayerAndChild(child, visibility);
      });
    }
    return layer;
  };

  const renderLayers = (layer: Layer, index: number) => {
    if (layer.children) {
      return (
        <div key={`${layer.name} ${index}`}>
          <OptionalContentItem
            action={() => toggleVisibility(index, 'layer')}
            name={layer.name}
            visible={layer.visible}
            index={index}
          />
          {layer.children.map(renderChildLayers(index))}
        </div>
      );
    } else {
      return (
        <OptionalContentItem
          action={() => toggleVisibility(index, 'layer')}
          name={layer.name}
          visible={layer.visible}
          index={index}
          key={`${layer.name} ${index}`}
        />
      );
    }
  };

  const renderChildLayers = (layerIndex: number) => (child: Layer, childIndex: number) => {
    if (child.children) {
      return (
        <div key={`${child.name} ${childIndex}`}>
          <div className={classes.childLayer}>
            <OptionalContentItem
              action={() => toggleVisibility(layerIndex, 'layer', childIndex)}
              name={child.name}
              visible={child.visible}
              index={childIndex}
            />
          </div>
          {child.children.map(renderGrandchildLayers(layerIndex, childIndex))}
        </div>
      );
    } else {
      return (
        <div className={classes.childLayer} key={`${child.name} ${childIndex}`}>
          <OptionalContentItem
            action={() => toggleVisibility(layerIndex, 'layer', childIndex)}
            name={child.name}
            visible={child.visible}
            index={childIndex}
          />
        </div>
      );
    }
  };

  // Unlikely but CTF supports so we will support
  const renderGrandchildLayers =
    (layerIndex: number, childIndex: number) => (grandChild: Layer, grandChildIndex: number) =>
      (
        <div className={classes.grandchildLayer} key={`${grandChild.name} ${grandChildIndex}`}>
          <OptionalContentItem
            action={() => toggleVisibility(layerIndex, 'layer', childIndex, grandChildIndex)}
            name={grandChild.name}
            visible={grandChild.visible}
            index={grandChildIndex}
          />
        </div>
      );

  return (
    <Grid container>
      {/* if both layers and separations are missing from the document, display OptionalContentEmpty */}
      {(!layers || layers?.length === 0) && (!separations || separations?.length === 0) && <OptionalContentEmpty />}

      {layers && layers.length > 0 && (
        <>
          <Typography variant="caption" className={classes.title}>
            LAYERS
          </Typography>
          <List className={classes.list}>{layers.map(renderLayers)}</List>
        </>
      )}

      {separations && separations.length > 0 && (
        <>
          <Typography variant="caption" className={classes.title}>
            SPOT COLORS
          </Typography>
          <List className={classes.list}>
            {separations.map((separation, index) => {
              return (
                <OptionalContentItem
                  action={() => toggleVisibility(index, 'separation')}
                  name={separation.name}
                  visible={separation.enabled}
                  rgb={separation.rgb}
                  index={index}
                  // eslint-disable-next-line
                  key={`${separation.name} ${index}`}
                />
              );
            })}
          </List>
        </>
      )}
    </Grid>
  );
};

export default OptionalContentList;
