import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Grid, Box, Theme, Popper, Button } from '@mui/material';
import {
  getOpenPanel,
  inspection,
  getIsSingleFile,
  getDocumentsState,
  getUnmatchedPages,
  getLastJobInput,
  getDocumentsLoaded,
  getDifferenceViewOptions,
  getIsResultsPanelHidden,
  getFilesIds,
  getInspectionId,
} from 'store';
import { DocumentStates, DocumentTypes } from 'types';
import isReportsUrl from 'utils/location/isReportsUrl';
import { PagePreviewOffIcon, PagePreviewOnIcon } from 'components/icons';
import CloseIcon from '@mui/icons-material/Close';
import GVIconButton from 'components/lib/GVIconButton/GVIconButton';
import ThumbnailsPanel from 'components/pageElements/thumbnails/ThumbnailsPanel';
import { GVToggleButton } from 'components/common';
import { getStyleVariables, menuLightGrey, opacities } from 'styles/vars';
import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import RotatePageControl from './RotatePageControl';
import ZoomControls from './ZoomControls';
import PageSelection from './PageSelection';
import SyncScrollingButton from './SyncScrollingButton';
import CogMenu from './CogMenu';
import { makeStyles } from 'tss-react/mui';
import GVChip, { GVChipColor, GVChipSize } from 'components/GVChip/GVChip';
import { GVTextButton, GVTypography } from 'components/lib';
import { listToRange } from 'utils/pageRanges/pageRangeConversion';
import { colors } from 'components/lib/global/styles';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { isResultUrl } from 'utils/location';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    root: {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.common.black,
      padding: theme.spacing(0.5, 0.5, 0.5, 0),
      borderLeft: 'none',
      height: theme.spacing(6),
      width: '100%',
    },
    topBarContainer: {
      height: '100%',
    },
    itemsContainer: {
      marginRight: theme.spacing(2),
    },
    hidden: {
      display: 'none',
    },
    previewPopper: {
      ...styleVariables.leftPanel.previewPopper,
      width: '350px',
    },
    previewPopperSingleFile: {
      width: '200px',
      ...styleVariables.leftPanel.previewPopper,
    },
    graphicsPopper: {
      backgroundColor: menuLightGrey,
      borderRadius: '4px',
      width: '287px',
      color: '#FFFFFFDE',
      padding: '16px',
      marginTop: '4px',
    },
    graphicsPopperButtons: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      gap: theme.spacing(1),
    },
    backToPrepButton: {
      backgroundColor: 'transparent',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.04)',
        border: '1px solid rgba(0, 0, 0, 0.23)',
      },
    },
    closeIcon: {
      position: 'fixed',
      right: theme.spacing(1.5),
      top: theme.spacing(1.5),
      '&.MuiButtonBase-root': {
        borderRadius: 0,
      },
      '&:hover': {
        backgroundColor: 'rgb(255,255,255,0.24)',
      },
      zIndex: '100',
    },
    textButton: {
      color: theme.palette.common.white,
      opacity: opacities.high,
      textDecoration: 'underline',
      fontWeight: 700,
      fontStyle: 'italic',
    },
    previewButton: {
      margin: `0 ${theme.spacing(2)}`,
    },
    buttonWrapper: {
      background: styleVariables.global.darkBorder,
      borderRadius: '4px',
      justifyContent: 'center',
      alignItems: 'center',
      flexBasis: 'fit-content',
      height: 'auto',
    },
    button: {
      margin: theme.spacing(0.25),
      color: theme.palette.text.primary,
      padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
      background: 'transparent',
      fontWeight: 600,
      boxShadow: 'none',
      '&:hover': {
        background: styleVariables.colors.menuLightGrey,
        boxShadow: 'none',
      },
    },
    buttonActive: {
      background: theme.palette.secondary.main,
      '&:hover': {
        background: theme.palette.secondary.main,
        cursor: 'default',
      },
    },
    graphicsWarning: {
      position: 'fixed',
      zIndex: 1,
      width: '16px',
      height: '16px',
      fontWeight: 600,
      fontSize: '10px',
      marginLeft: '40px',
      marginTop: '-6px',
      '& .MuiChip-label': { overflow: 'visible' },
    },
    graphicsPopup: {
      zIndex: 1,
      width: '16px',
      height: '16px',
      fontWeight: 600,
      fontSize: '10px',
      padding: '16px',
    },
    tip: {
      fontWeight: 700,
    },
    bold: {
      fontWeight: 700,
      fontStyle: 'italic',
    },
    unmatchedList: {
      fontWeight: 700,
      color: colors.gvRed,
    },
  };
});

const GVDocumentsTopBar = () => {
  const { classes, cx } = useStyles();
  const navigate = useNavigate();
  const anchorEl = useRef(null);
  const graphicsAnchorEl = useRef(null);
  const openPanel = useSelector(getOpenPanel);
  const isSingleFile = useSelector(getIsSingleFile);
  const documentsState = useSelector(getDocumentsState);
  const unmatchedPages = useSelector(getUnmatchedPages);
  const isDocumentsLoaded = useSelector(getDocumentsLoaded);
  const lastJobInput = useSelector(getLastJobInput);
  const isResultsPanelHidden = useSelector(getIsResultsPanelHidden);
  const inspectionId = useSelector(getInspectionId);
  const dispatch = useDispatch();
  const [showGraphicsPopup, setShowGraphicsPopup] = useState<boolean>(false);
  const hideDisplay = useSelector(getDifferenceViewOptions).hideDisplayPanels;
  const filesId = useSelector(getFilesIds);
  const filesLoaded =
    (isSingleFile && filesId.sampleFileId) || (!isSingleFile && filesId.masterFileId && filesId.sampleFileId);

  const graphicsWarningMessage = useMemo(() => {
    if (unmatchedPages.length === 0 || !lastJobInput) {
      return null;
    }

    const masterInput = lastJobInput.documents[0];
    const includedPages = new Set(masterInput.pages.filter((page) => page.included).map((page) => page.number));
    const pagesWithZones = new Set((masterInput.graphicZones || []).map((zone) => zone.pageNumber));

    if (
      [...includedPages].every((pageNumber) => unmatchedPages.includes(pageNumber) && !pagesWithZones.has(pageNumber))
    ) {
      // if every page that ran matches did not find a match, we specify that every match failed
      return (
        <>
          <GVTypography fontSize={16} fontWeight={600} marginTop={'4px'} marginBottom={'16px'}>
            Graphics inspection failed
          </GVTypography>
          <GVTypography fontSize={12} fontWeight={400} marginBottom={'16px'}>
            We were unable to find graphics matches for any of your pages. Try adjusting your cropped regions and
            checking your graphics settings.
          </GVTypography>
        </>
      );
    }

    const unmatchedList = listToRange(unmatchedPages, 'p');
    return (
      <>
        <GVTypography fontSize={16} fontWeight={600} marginTop={'4px'} marginBottom={'16px'}>
          Graphics match incomplete
        </GVTypography>
        <GVTypography fontSize={12} fontWeight={400} marginBottom={'16px'}>
          We couldn't find matches for the following pages:{' '}
          <span className={classes.unmatchedList}>{unmatchedList}</span>. Try adjusting your cropped regions and
          checking your graphics settings.
        </GVTypography>
      </>
    );
  }, [unmatchedPages]);

  useEffect(() => {
    const hasUnmatchedPages = unmatchedPages.length > 0 && isDocumentsLoaded;
    setShowGraphicsPopup(hasUnmatchedPages);
  }, [unmatchedPages, isDocumentsLoaded]);

  const handleClick = () => {
    if (showGraphicsPopup && isResultUrl()) return;
    dispatch(inspection.actions.setOpenPanel(!openPanel));
  };

  const handleProofreaderClick = () => {
    const documentsLoading =
      documentsState.source === DocumentStates.LOADING || documentsState.target === DocumentStates.LOADING;
    if (!documentsLoading) {
      dispatch(inspection.actions.setSingleFileInspection());
    }
  };

  const bothDocumentsLoaded =
    (!isSingleFile &&
      documentsState.source === DocumentStates.LOADED &&
      documentsState.target === DocumentStates.LOADED) ||
    (isSingleFile && documentsState.target === DocumentStates.LOADED);

  const renderDocumentControls = (documentType: DocumentTypes) => {
    return (
      documentsState[documentType] === DocumentStates.LOADED && (
        <Grid
          container
          direction="row"
          justifyContent={documentType === DocumentTypes.source ? 'flex-end' : 'flex-start'}
        >
          <Grid item>
            <PageSelection documentType={documentType} />
          </Grid>
          <Grid item>
            <ZoomControls document={documentType} />
          </Grid>
          <Grid item>
            <RotatePageControl documentType={documentType} />
          </Grid>
        </Grid>
      )
    );
  };

  const renderHideResultPanelButton = () => {
    if (!isResultUrl() || !isDocumentsLoaded) {
      // add placeholder spacing so the UI doesn't shift when navigating tabs
      return <Grid sx={{ marginRight: '22.5px' }} />;
    }
    return (
      <Button
        variant="outlined"
        sx={{
          padding: '7px 0',
          minWidth: '2px',
          marginRight: '0',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }}
        id="collapse-results-button"
        onClick={() => {
          dispatch(inspection.actions.setIsResultsPanelHidden(!isResultsPanelHidden));
        }}
      >
        {isResultsPanelHidden ? <ArrowRightIcon fontSize="small" /> : <ArrowLeftIcon fontSize="small" />}
      </Button>
    );
  };

  return (
    <Box boxShadow={2} className={classes.root} ref={anchorEl} position="relative" display="flex" alignItems="center">
      <Grid position="absolute" width="100%">
        {!filesLoaded && (
          <Grid container width={'100%'} height={'100%'} justifyContent={'center'} alignItems={'center'}>
            <Grid container item className={classes.buttonWrapper}>
              <Button
                variant="contained"
                className={cx(classes.button, { [classes.buttonActive]: !isSingleFile })}
                onClick={() => dispatch(inspection.actions.setInspectionSettings({ singleFile: false }))}
                startIcon={<img src="/icons/single-file/ic-comparison.svg" width={'16px'} height={'auto'} />}
                data-testid="comparison_button"
              >
                Comparison
              </Button>
              <Button
                variant="contained"
                className={cx(classes.button, { [classes.buttonActive]: isSingleFile })}
                onClick={handleProofreaderClick}
                startIcon={<img src="/icons/single-file/ic-single-doc.svg" width={'16px'} height={'auto'} />}
                data-testid="proofreader_button"
              >
                Proofreader
              </Button>
            </Grid>
          </Grid>
        )}
        <Grid container wrap="nowrap" height={'100%'} alignItems={'center'}>
          {renderHideResultPanelButton()}
          <Grid container item xs={hideDisplay.target ? undefined : 5} wrap="nowrap">
            {bothDocumentsLoaded && (
              <GVTooltip title="Thumbnail Panel" placement="top">
                <Grid item>
                  {unmatchedPages.length > 0 && (
                    <GVChip
                      label={unmatchedPages.length}
                      className={classes.graphicsWarning}
                      chipSize={GVChipSize.SMALL}
                      chipColor={GVChipColor.ALERT}
                    />
                  )}
                  <GVToggleButton
                    value="preview-panel"
                    id="previewPanelToggleButton"
                    small
                    selected={openPanel}
                    onChange={handleClick}
                    className={classes.previewButton}
                    ref={graphicsAnchorEl}
                  >
                    {openPanel ? <PagePreviewOnIcon fontSize="small" /> : <PagePreviewOffIcon fontSize="small" />}
                  </GVToggleButton>
                </Grid>
              </GVTooltip>
            )}
            {!hideDisplay.source &&
              !isSingleFile &&
              bothDocumentsLoaded &&
              renderDocumentControls(DocumentTypes.source)}
          </Grid>
          <Grid item container justifyContent="center" xs={2} wrap="nowrap">
            {bothDocumentsLoaded && !isSingleFile && !hideDisplay.source && !hideDisplay.target && (
              <Grid item>
                <SyncScrollingButton />
              </Grid>
            )}
          </Grid>
          <Grid
            item
            container
            justifyContent={isSingleFile ? 'space-between' : 'flex-end'}
            xs={isSingleFile || hideDisplay.source ? undefined : 5}
            wrap="nowrap"
          >
            {!hideDisplay.target && bothDocumentsLoaded && renderDocumentControls(DocumentTypes.target)}
            <Grid item className={isReportsUrl() || !bothDocumentsLoaded ? classes.hidden : classes.itemsContainer}>
              <CogMenu loaded={bothDocumentsLoaded} />
            </Grid>
          </Grid>
        </Grid>
        <Popper
          id="leftPanelPopper"
          open={(!showGraphicsPopup || !isResultUrl()) && openPanel}
          anchorEl={anchorEl ? anchorEl.current : null}
          placement="bottom-start"
          className={isSingleFile ? classes.previewPopperSingleFile : classes.previewPopper}
        >
          <ThumbnailsPanel />
        </Popper>
        {isResultUrl() && (
          <Popper
            id="close graphics popper"
            open={showGraphicsPopup}
            anchorEl={graphicsAnchorEl ? graphicsAnchorEl.current : null}
            placement="bottom-start"
            className={classes.graphicsPopper}
            modifiers={[
              {
                name: 'offset',
                options: {
                  offset: [-33, 8],
                },
              },
            ]}
          >
            <Grid item>
              <GVIconButton
                className={classes.closeIcon}
                size="small"
                onClick={() => {
                  setShowGraphicsPopup(false);
                }}
                icon={<CloseIcon color="primary" />}
              />
            </Grid>
            {graphicsWarningMessage}
            <GVTypography fontSize={12} fontWeight={400} marginBottom={'16px'}>
              <span className={classes.bold}>Still having trouble? Check out our graphics prep tips & tricks </span>
              <a
                className={classes.textButton}
                href={`${import.meta.env.VITE_HELP_URL}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              <span className={classes.bold}>.</span>
            </GVTypography>
            <Grid container className={classes.graphicsPopperButtons}>
              <GVTextButton
                className={classes.backToPrepButton}
                variant="outlined"
                text="Back to Prep"
                onClick={() => {
                  setShowGraphicsPopup(false);
                  navigate(`/inspection/${inspectionId}`);
                }}
              />
              <GVTextButton
                variant="contained"
                color="secondary"
                text="Close"
                onClick={() => {
                  setShowGraphicsPopup(false);
                }}
              />
            </Grid>
          </Popper>
        )}
      </Grid>
    </Box>
  );
};

export default GVDocumentsTopBar;
