import { Chip, ChipProps, alpha, Theme } from '@mui/material';
import { colors } from '../lib/global/styles';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
  root: { fontSize: '12px', lineHeight: '16px', fontWeight: 600 },
  default: {
    color: alpha(colors.gvWhite, 0.87),
    backgroundColor: alpha(colors.gvWhite, 0.32),
  },
  warning: {
    color: colors.gvYellow,
    backgroundColor: alpha(colors.gvYellow, 0.32),
  },
  success: {
    color: colors.gvGreen,
    backgroundColor: alpha(colors.gvGreen, 0.32),
  },
  error: {
    color: colors.gvDarkred,
    backgroundColor: alpha(colors.gvDarkred, 0.32),
  },
  expired: {
    color: colors.gvExpired,
    backgroundColor: alpha(colors.gvExpired, 0.32),
  },
  alert: {
    color: colors.gvWhite,
    backgroundColor: colors.gvYellow,
  },
  normal: {
    height: theme.spacing(3),
  },
  small: {
    height: theme.spacing(2),
  },
  xsmall: {
    height: theme.spacing(0.5),
  },
  normalCircle: {
    height: theme.spacing(3),
    width: theme.spacing(3),
  },
  smallCircle: {
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
  xsmallCircle: {
    height: theme.spacing(0.5),
    width: theme.spacing(0.5),
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  // Square styling for the "new" chip label
  square: {
    margin: theme.spacing(0, 0.5),
    borderRadius: '0px',
    padding: theme.spacing(0.125, 0.5, 0.25, 0.5),
    fontSize: '10px',
    fontWeight: 400,
    height: '16px',
    '& .MuiChip-label': { padding: '0px' },
  },
  circle: {
    borderRadius: '50%',
    '& .MuiChip-label': { padding: '0px' },
  },
}));

export enum GVChipColor {
  DEFAULT = 'default',
  WARNING = 'warning',
  SUCCESS = 'success',
  ERROR = 'error',
  EXPIRED = 'expired',
  ALERT = 'alert',
}

export enum GVChipSize {
  NORMAL = 'normal',
  SMALL = 'small',
  XSMALL = 'xsmall',
}

export interface GVChipProps extends ChipProps {
  chipSize?: GVChipSize;
  chipColor?: GVChipColor;
  className?: string;
  capitalize?: boolean;
  square?: boolean;
  circle?: boolean;
}

const GVChip = ({
  chipSize = GVChipSize.NORMAL,
  chipColor = GVChipColor.DEFAULT,
  className = '',
  capitalize,

  // exclude these from props to not conflict with our own props
  variant,

  size,
  color,
  ...props
}: GVChipProps) => {
  const { classes, cx } = useStyles();
  // Removing square prop from being passed down
  const { square, circle, ...passedProps } = props;
  return (
    <Chip
      className={cx(
        className || classes.root,
        capitalize && classes.capitalize,
        props.square && classes.square,
        props.circle && classes.circle,
        props.circle ? classes[`${chipSize}Circle`] : classes[chipSize],
        classes[chipColor],
      )}
      {...passedProps}
    />
  );
};

export default GVChip;
