import { Flip } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ClickAwayListener, Menu, MenuItem, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { GVToggleButton } from 'components/common';
import OCRIcon from 'components/icons/OCRIcon/OCRIcon';
import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import { bindMenu, bindToggle, usePopupState } from 'material-ui-popup-state/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { app, getAllAnnotationsLoaded, getFilesIds, getIsOCR, getIsSingleFile } from 'store';
import { startOCR } from 'store/request/files/actions';
import { getStyleVariables } from 'styles/vars';
import { DocumentTypes } from 'types';
import { useState } from 'react';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    menuList: {
      height: '60px',
      boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
      color: theme.palette.text.primary,
      margin: theme.spacing(0.25, 0),
      padding: theme.spacing(0.375),
      '& > li': {
        display: 'flex',
        alignItems: 'center',
        borderRadius: '2px',
        height: '24px',
        width: '142px',
        margin: theme.spacing(0.25, 0.5),
        padding: '0',
        '&:hover': {
          backgroundColor: styleVariables.userMenu.hoverItem.background,
        },
      },
    },
    ocrMenuList: {
      '& > li': {
        width: '184px!important',
      },
    },
    toggleButton: { padding: '0px', height: '48px', width: '48px' },
    zoneIcon: {
      margin: theme.spacing(0.5),
    },
    zoneInfoIcon: {
      marginLeft: 'auto',
      margin: theme.spacing(1),
      fontSize: '10px',
    },
    ocrIcon: { fontSize: '40px', padding: '0' },
    flippedOCRIcon: { transform: 'rotate(180deg)', margin: theme.spacing(0.5) },
    tooltip: {
      maxWidth: 200,
    },
  };
});

interface OCRToolProps {
  handleClearAll: () => void;
}

const OCRTool = ({ handleClearAll }: OCRToolProps) => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const ocrMenuState = usePopupState({ variant: 'popover', popupId: 'zoneMenu' });

  const isOCR = useSelector(getIsOCR);
  const isSingleFile = useSelector(getIsSingleFile);
  const annotationLoaded = useSelector(getAllAnnotationsLoaded);
  const { masterFileId, sampleFileId } = useSelector(getFilesIds);

  const handleStartOCR = (documentType: DocumentTypes) => {
    handleClearAll();
    dispatch(startOCR(documentType === DocumentTypes.source ? masterFileId : sampleFileId, documentType));
    dispatch(app.actions.setShowLiveText(false));
  };

  let ocrTitle = 'Process Flattened Text';

  if (isSingleFile && (!annotationLoaded || isOCR.targetIsOCR)) {
    ocrTitle = 'You have already performed OCR on your document';
  } else if (!isSingleFile && isOCR.sourceIsOCR && isOCR.targetIsOCR) {
    ocrTitle = 'You have already performed OCR on both of your documents';
  }

  return (
    <span>
      <GVTooltip title={ocrTitle} placement="bottom">
        <span>
          {isSingleFile ? (
            <GVToggleButton
              value="annotation_tools"
              data-testid="apply_ocr"
              id="applyOCR"
              disabled={!annotationLoaded || isOCR.targetIsOCR}
              className={classes.toggleButton}
              onChange={() => handleStartOCR(DocumentTypes.target)}
            >
              <OCRIcon className={classes.ocrIcon} />
            </GVToggleButton>
          ) : (
            <ClickAwayListener onClickAway={() => ocrMenuState.close()}>
              <GVToggleButton
                value="annotation_tools"
                data-testid="apply_ocr"
                id="applyOCR"
                disabled={!annotationLoaded || (isOCR.sourceIsOCR && isOCR.targetIsOCR)}
                className={classes.toggleButton}
                {...bindToggle(ocrMenuState)}
              >
                <OCRIcon className={classes.ocrIcon} />
              </GVToggleButton>
            </ClickAwayListener>
          )}
        </span>
      </GVTooltip>

      <Menu
        {...bindMenu(ocrMenuState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        classes={{
          list: `${classes.menuList} ${classes.ocrMenuList} `,
        }}
      >
        <MenuItem
          onClick={() => {
            handleStartOCR(DocumentTypes.source);
            ocrMenuState.close();
          }}
          disabled={isOCR.sourceIsOCR}
        >
          <Flip fontSize="small" className={classes.zoneIcon} />
          Apply OCR to Source
          <GVTooltip
            title="Applying OCR will remove all your current Text zones, Ignored Text and Graphic zones."
            classes={{ tooltip: classes.tooltip }}
          >
            <InfoOutlinedIcon fontSize="small" className={classes.zoneInfoIcon} />
          </GVTooltip>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleStartOCR(DocumentTypes.target);
            ocrMenuState.close();
          }}
          disabled={isOCR.targetIsOCR}
        >
          <Flip fontSize="small" className={(classes.zoneIcon, classes.flippedOCRIcon)} />
          Apply OCR to New
          <GVTooltip
            title="Applying OCR will remove all your current Text zones, Ignored Text and Graphic zones."
            classes={{ tooltip: classes.tooltip }}
          >
            <InfoOutlinedIcon className={classes.zoneInfoIcon} fontSize="inherit" />
          </GVTooltip>
        </MenuItem>
      </Menu>
    </span>
  );
};

export default OCRTool;
