// eslint-disable-next-line import/prefer-default-export
export function getTypeCounts<Item extends { displayName: string }>(items: Item[]) {
  const itemTypeCounts: { [displayName: string]: number } = {};
  items.forEach(item => {
    if (!(item.displayName in itemTypeCounts)) {
      itemTypeCounts[item.displayName] = 1;
    } else {
      itemTypeCounts[item.displayName]++;
    }
  });
  return itemTypeCounts;
}
