import { getMutationSelector, MutationState } from '@redux-requests/core';
import { createSelector } from '@reduxjs/toolkit';
import { PDFManagerFactory } from 'pdftron';
import {
  DocumentData,
  DocumentTextProcessing,
  DocumentToastState,
  DocumentTypes,
  InputDocuments,
  InputPage,
  InspectionState,
  InspectionStatuses,
  PDFTronTools,
  PDFTronToolsDBVals,
  ReportDocumentTypes,
  StoreState,
  ZoomLockLevel,
  AnnotationCustomData,
  Dictionaries,
  GraphicsSensitivity,
  AllDocumentTypes,
  GraphicsMatchingMode,
  InputAnnotation,
} from 'types';
import { excludedRange } from 'utils/pageRanges/pageRangeConversion';
import { updateDifferenceViewOptions, CreateInspectionData } from 'store/request/inspections/actions';
import { getFilesIds } from '../files/filesSelectors';
import { cropToolEnabled } from 'pdftron/docManager/AnnotationTools/utils/CropToolUtils';

const getInspectionstate = (state: StoreState) => state.inspection;

const getSourcePageRange = (state: StoreState) => state.inspection.source.pageRange;
const getTargetPageRange = (state: StoreState) => state.inspection.target.pageRange;

const getSourceLoaded = (state: StoreState) => state.inspection.source.state === 'LOADED';
const getTargetLoaded = (state: StoreState) => state.inspection.target.state === 'LOADED';
const getReportLoaded = (state: StoreState) => state.inspection.report.state === 'LOADED';

const getSourceLiveText = (state: StoreState) => state.inspection.source.liveText;
const getTargetLiveText = (state: StoreState) => state.inspection.target.liveText;

const getInternalAnnotationVisibility = (state: StoreState) => state.inspection.internalAnnotationsVisibility;
const getInternalAnnotationsVisibilityDefault = (state: StoreState) =>
  state.inspection.internalAnnotationsVisibilityDefault;

const getShowLibrarySource = (state: StoreState) => state.inspection.showLibrary.source;
const getShowLibraryTarget = (state: StoreState) => state.inspection.showLibrary.target;

const getSourceTextProcess = (state: StoreState) =>
  state.inspection.source.documentTextProcessing === DocumentTextProcessing.COMPLETED;
const getTargetTextProcess = (state: StoreState) =>
  state.inspection.target.documentTextProcessing === DocumentTextProcessing.COMPLETED;

const getName = (state: StoreState) => state.inspection.name;

const getInspectionId = (state: StoreState) => state.inspection.inspectionId;

const getSourceTotalPage = (state: StoreState) => state.inspection.source.totalPageCount;
const getTargetTotalPage = (state: StoreState) => state.inspection.target.totalPageCount;

const getManualSelectedZoneId = (state: StoreState) => state.inspection.manualSelectedZoneId;

const getManualSelectedAnnotationId = (state: StoreState) => state.inspection.manualSelectedAnnotationId;

const getZoomOptionSource = (state: StoreState) => state.inspection.zoomOption.source;
const getZoomOptionTarget = (state: StoreState) => state.inspection.zoomOption.target;
const getZoomOptionReport = (state: StoreState) => state.inspection.zoomOption.report;

const getSourceLayers = (state: StoreState) => state.inspection.source.layers;
const getTargetLayers = (state: StoreState) => state.inspection.target.layers;

const getSourceSeparations = (state: StoreState) => state.inspection.source.separations;
const getTargetSeparations = (state: StoreState) => state.inspection.target.separations;

const getFlashMode = (state: StoreState) => state.inspection.flashMode;

const getSeparationHidden = createSelector([getSourceSeparations, getTargetSeparations], (source, target) => {
  const allSeparations = [...(source || []), ...(target || [])];
  return allSeparations.some((separation) => !separation.enabled);
});

function getPagesData(documentData: DocumentData): InputPage[] {
  const pages: InputPage[] = [];
  const pageNumbers = documentData.pageRange.filter((x) => x <= documentData.totalPageCount); // Make sure we don't include pages that doesn't exist
  for (let i = 1; i <= documentData.totalPageCount; i++) {
    const page = {
      number: i,
      included: pageNumbers.length === 0 || pageNumbers.includes(i), // Either all pages are included or a specified subset
    };
    pages.push(page);
  }

  if (documentData.annotations && Array.isArray(documentData.annotations)) {
    documentData.annotations.forEach((annotation) => {
      const page = pages[annotation.page - 1];
      // Make sure we don't include annotations on excluded pages
      if (!page.included) {
        return;
      }
      switch (annotation.usedTool) {
        case PDFTronTools.CROSSOUT:
          if (!page.crossout) {
            page.crossout = [];
          }
          if (annotation.quads) {
            page.crossout.push({
              number: annotation.number || 0,
              quads: annotation.quads,
            });
          }
          break;
        case PDFTronTools.CROP:
          if (!page.crop) {
            page.crop = [];
          }
          if (annotation.cropZone) {
            page.crop.push({ ...annotation.cropZone });
          }
          break;
        default:
      }
    });
  }
  return pages;
}

function addMarqueeZones(state: InspectionState, sourcePages: InputPage[], targetPages: InputPage[]) {
  const sourceData = state[DocumentTypes.source];
  const targetData = state[DocumentTypes.target];

  sourceData.annotations.forEach((sourceAnnotation) => {
    if (sourceAnnotation && sourceAnnotation.quads && sourceAnnotation.usedTool === PDFTronTools.MARQUEE_ZONE) {
      const sourcePage = sourcePages[sourceAnnotation.page - 1];
      if (!sourcePage.zone) {
        sourcePage.zone = [];
      }
      const zoneNumber = sourceAnnotation.number;
      if (!zoneNumber) {
        return;
      }

      sourcePage.zone.push({
        number: zoneNumber,
        quads: sourceAnnotation.quads,
        usedTool: PDFTronToolsDBVals.MARQUEE_ZONE,
        annotationId: sourceAnnotation.annotationId,
      });
    }
  });
  targetData.annotations.forEach((targetAnnotation) => {
    if (targetAnnotation && targetAnnotation.quads && targetAnnotation.usedTool === PDFTronTools.MARQUEE_ZONE) {
      const targetPage = targetPages[targetAnnotation.page - 1];
      if (!targetPage.zone) {
        targetPage.zone = [];
      }
      const zoneNumber = targetAnnotation.number;
      if (!zoneNumber) {
        return;
      }

      targetPage.zone.push({
        number: zoneNumber,
        quads: targetAnnotation.quads,
        usedTool: PDFTronToolsDBVals.MARQUEE_ZONE,
        annotationId: targetAnnotation.annotationId,
      });
    }
  });
}

function addZonesData(state: InspectionState, sourcePages: InputPage[], targetPages: InputPage[]) {
  const sourceData = state[DocumentTypes.source];
  const targetData = state[DocumentTypes.target];
  if (
    sourceData.zoneSelectedTexts &&
    Array.isArray(sourceData.zoneSelectedTexts) &&
    sourceData.annotations &&
    Array.isArray(sourceData.annotations) &&
    targetData.annotations &&
    Array.isArray(targetData.annotations) &&
    sourcePages.length &&
    targetPages.length
  ) {
    sourceData.zoneSelectedTexts.forEach((zoneText) => {
      if (!zoneText.selected || zoneText.searchedAnnotations.length !== 1) {
        return; // No selected text match
      }
      const sourceAnnotation = sourceData.annotations.find((annot) => annot.annotationId === zoneText.annotationId);
      if (!sourceAnnotation || !sourceAnnotation.quads) {
        return; // We couldn't find the related annotation
      }
      const targetAnnotation = targetData.annotations.find(
        (annot) => annot.annotationId === zoneText.searchedAnnotations[0].annotationId,
      );
      if (!targetAnnotation || !targetAnnotation.quads) {
        return; // We couldn't find the related annotation on target document
      }
      const sourcePage = sourcePages[sourceAnnotation.page - 1];
      const targetPage = targetPages[targetAnnotation.page - 1];
      // Make sure we don't include annotations on excluded pages
      if (!sourcePage.included || !targetPage.included) {
        return;
      }
      const zoneNumber = sourceAnnotation.number;
      if (!zoneNumber) {
        return;
      }
      // Add zone markup on source
      if (!sourcePage.zone) {
        sourcePage.zone = [];
      }
      sourcePage.zone.push({
        number: zoneNumber,
        quads: sourceAnnotation.quads,
        usedTool: PDFTronToolsDBVals.ZONE,
        annotationId: sourceAnnotation.annotationId,
      });
      // Add zone markup on target
      if (!targetPage.zone) {
        targetPage.zone = [];
      }
      targetPage.zone.push({
        number: zoneNumber,
        quads: targetAnnotation.quads,
        usedTool: PDFTronToolsDBVals.ZONE,
        annotationId: targetAnnotation.annotationId,
      });
    });
  }
}

const getSelectingZone = createSelector([getInspectionstate], (inspection) => inspection.selectingZone || false);

const getInspectionInput = (state: StoreState): InputDocuments => {
  const sourcePages = getPagesData(state.inspection[DocumentTypes.source]);
  const targetPages = getPagesData(state.inspection[DocumentTypes.target]);
  addZonesData(state.inspection, sourcePages, targetPages);
  addMarqueeZones(state.inspection, sourcePages, targetPages);
  const pdfDocManager = PDFManagerFactory.getPDFDocManager();
  const sourceGraphicZones = pdfDocManager?.getSerializedGraphicZones();
  const targetGraphicZones = pdfDocManager?.getSerializedGraphicZones(DocumentTypes.target);
  const sourceLayers = getSourceLayers(state);
  const targetLayers = getTargetLayers(state);
  const sourceSeparations = getSourceSeparations(state);
  const targetSeparations = getTargetSeparations(state);
  const input: InputDocuments = {
    documents: [
      {
        pages: sourcePages,
        graphicZones: sourceGraphicZones,
        layers: sourceLayers,
        separations: sourceSeparations,
        type: 'master',
      },
      {
        pages: targetPages,
        graphicZones: targetGraphicZones,
        layers: targetLayers,
        separations: targetSeparations,
        type: 'sample',
      },
    ],
  };
  return input;
};
const getInspectionStatus = (state: StoreState) => state.inspection.inspectionStatus;

const getLoadingDifferences = (state: StoreState) => state.inspection.reloadingDifferences;

const getSearchingForGraphic = (state: StoreState) => state.inspection.searchingForGraphicZone;

const getZoneSelectedTexts = (state: StoreState) => state.inspection.source.zoneSelectedTexts;

const getSelectedTool = (state: StoreState) => state.inspection.selectedTool;

const getSourceAnnotations = (state: StoreState) => state.inspection.source.annotations;
const getTargetAnnotations = (state: StoreState) => state.inspection.target.annotations;

const getSourceCurrentPage = (state: StoreState) => state.inspection.source.currentPage;
const getTargetCurrentPage = (state: StoreState) => state.inspection.target.currentPage;

const getSourcePages = (state: StoreState) => state.inspection.source.pages;
const getTargetPages = (state: StoreState) => state.inspection.target.pages;

const getSyncScrolling = (state: StoreState) => state.inspection.syncScrolling;

const getZoomChanging = (state: StoreState) => state.inspection.zoomChanging;

const getDifferencePanelOpen = (state: StoreState) => state.inspection.differencePanelOpen;

const getChangeGridDirection = (state: StoreState) => state.inspection.changeGridDirection;
const getZoomOption = createSelector(
  [getZoomOptionSource, getZoomOptionTarget, getZoomOptionReport],
  (source, target, report) => ({
    source,
    target,
    report,
  }),
);

const getInvalidScaledGraphicZoneRatio = (state: StoreState) => state.inspection.invalidScaledGraphicZoneRatio;
const getUnconfirmTextZoneError = (state: StoreState) => state.inspection.unconfirmTextZoneError;
const getUnconfirmGraphicZoneError = (state: StoreState) => state.inspection.unconfirmedGraphicZoneError;
const getUnconfirmedMarqueeZoneError = (state: StoreState) => state.inspection.unconfirmedMarqueeZoneError;
const getNoResultAnnotationId = (state: StoreState) => state.inspection.noResultAnnotationId;

const getSelectedSubDifferenceID = (state: StoreState) => state.inspection.selectedSubDifferenceId;
const getSubDifferenceComments = (state: StoreState) => state.inspection.subDifferenceComments;
const getPersistedDifferenceComments = (state: StoreState) => state.inspection.persistedDifferenceComments;

const getSourceState = (state: StoreState) => state.inspection.source.state;
const getTargetState = (state: StoreState) => state.inspection.target.state;
const getReportState = (state: StoreState) => state.inspection.report.state;

const getInspectionLastJobId = (state: StoreState) => state.inspection.lastJobId;

const getLastJobInput = (state: StoreState) => state.inspection.lastJobInput;

const getShowDiscarded = (state: StoreState) => state.inspection.showDiscarded;

const getOpenPanel = (state: StoreState) => state.inspection.openPanel;

const getPageRangeOption = (state: StoreState) => state.inspection.pageRangeOption;

const getSourceDocumentToast = (state: StoreState) => state.inspection.documentToast[DocumentTypes.source];
const getTargetDocumentToast = (state: StoreState) => state.inspection.documentToast[DocumentTypes.target];

const getZoomLockLevelSource = (state: StoreState) => state.inspection.zoomLockLevel[DocumentTypes.source];
const getZoomLockLevelTarget = (state: StoreState) => state.inspection.zoomLockLevel[DocumentTypes.target];
const getZoomLockLevelReport = (state: StoreState) => state.inspection.zoomLockLevel[ReportDocumentTypes.report];

const getNextTextZoneId = (state: StoreState) => state.inspection.nextTextZoneId;
const getNextGraphicZoneId = (state: StoreState) => state.inspection.nextGraphicZoneId;
const getNextCropZoneId = (state: StoreState) => state.inspection.nextCropZoneId;
const getNextMarqueeZoneId = (state: StoreState) => state.inspection.nextMarqueeZoneId;
const getNextCrossoutZoneId = (state: StoreState) => state.inspection.nextCrossoutZoneId;

const filterAnnotationsByTools = (annotations: InputAnnotation[], tools: PDFTronTools[]) => {
  const filteredAnnotations = [];
  for (let i = 0; i < annotations.length; i++) {
    if (tools.includes(annotations[i].usedTool as PDFTronTools)) {
      filteredAnnotations.push(annotations[i]);
    }
  }
  return filteredAnnotations;
};

// check if the inspection used graphic zones but now has crop tool enabled.
const getInspectionHasOutdatedGraphicZones = createSelector([getLastJobInput], (lastJob) => {
  return !!(
    cropToolEnabled() &&
    lastJob &&
    lastJob.documents &&
    lastJob.documents.some((doc) => doc.graphicZones && doc.graphicZones.length > 0)
  );
});

// check if the inspection used crop zones but now has crop tool disabled.
const getInspectionHasOutdatedCropZones = createSelector(
  [getSourceAnnotations, getTargetAnnotations],
  (source, target) => {
    const cropZoneAnnotations = filterAnnotationsByTools(source.concat(target), [PDFTronTools.CROP]);
    return cropZoneAnnotations.length > 0 && !cropToolEnabled();
  },
);

const getIsFilesLoaded = (state: StoreState) => state.inspection.isFilesLoaded;

const getCtfJobs = (state: StoreState) => state.inspection.ctfJobs;

const getInspectionSettings = createSelector([getInspectionstate], (inspectionState) => inspectionState.settings);

const getIsSingleFile = createSelector([getInspectionstate], (inspectionState) => {
  return inspectionState.settings.singleFile;
});

const getSelectedCustomDictionaryIDs = createSelector(
  [getInspectionstate],
  (inspectionState) => inspectionState.customDictionaryIDs,
);

const getAutoMatchGraphic = createSelector([getInspectionstate], (inspectionState) => inspectionState.autoMatchGraphic);

const getTableEnhancementEnabled = createSelector(
  [getInspectionstate],
  (inspectionState) => inspectionState.tableEnhancementEnabledState,
);

const getShowLibrary = createSelector([getShowLibrarySource, getShowLibraryTarget], (source, target) => ({
  source,
  target,
}));

const getPageRangeSelector = createSelector([getSourcePageRange, getTargetPageRange], (source, target) => ({
  source,
  target,
}));

const getExcludedPage = (state: StoreState) => ({
  source: excludedRange(state.inspection.source.pageRange, state.inspection.source.totalPageCount),
  target: excludedRange(state.inspection.target.pageRange, state.inspection.target.totalPageCount),
});

const getPageLoadedSelector = createSelector([getSourceLoaded, getTargetLoaded], (source, target) => ({
  source,
  target,
}));

// Check if the Live text process is completed and the the live text is not existing
const getLiveTextNotFound = createSelector(
  [getSourceTextProcess, getTargetTextProcess, getSourceLiveText, getTargetLiveText],
  (sourceProcess, targetProcess, source, target) => (sourceProcess && !source) || (targetProcess && !target),
);

const getEitherLiveText = createSelector(
  [getSourceTextProcess, getTargetTextProcess, getSourceLiveText, getTargetLiveText],
  (sourceProcess, targetProcess, source, target) => (sourceProcess && source) || (targetProcess && target),
);

const getLiveTextStatus = createSelector(
  [getSourceTextProcess, getTargetTextProcess, getSourceLiveText, getTargetLiveText],
  (sourceProcess, targetProcess, source, target) => ({
    source: sourceProcess && !source,
    target: targetProcess && !target,
  }),
);

// Check if the document is loaded and the Live text process is completed
const getTextProcessStatus = createSelector(
  [getSourceTextProcess, getTargetTextProcess, getInspectionSettings],
  (sourceProcess, targetProcess, inspectionSettings) => {
    if (inspectionSettings.singleFile) {
      return targetProcess;
    }
    return sourceProcess && targetProcess;
  },
);

// TODO: totalPages should be taken from webViewerInstance. It's not changing and there is not point in storing it redux.
const getTotalPageCount = createSelector([getSourceTotalPage, getTargetTotalPage], (source, target) => ({
  source,
  target,
}));

const getDocumentDisplayedSelector = createSelector(
  [getSourceLoaded, getTargetLoaded, getSourceTotalPage, getTargetTotalPage, getInspectionSettings],
  (sourceLoaded, targetLoaded, sourceTotalPages, targetTotalPages, inspectionSettings) => {
    if (inspectionSettings.singleFile) {
      return targetLoaded && targetTotalPages > 0;
    }
    return sourceLoaded && targetLoaded && sourceTotalPages > 0 && targetTotalPages > 0;
  },
);

const getCreateInspectionData = createSelector(
  [getName, getFilesIds, getInspectionSettings],
  (name, filesIds, settings): CreateInspectionData => {
    const inspectionData: CreateInspectionData = {};
    inspectionData.name = name;
    if (filesIds.masterFileId) {
      inspectionData.masterFileId = filesIds.masterFileId;
    }
    if (filesIds.sampleFileId) {
      inspectionData.sampleFileId = filesIds.sampleFileId;
    }

    inspectionData.settings = settings;
    return inspectionData;
  },
);

const getDifferenceViewOptions = createSelector(
  [getInspectionstate],
  (inspectionState) => inspectionState.differenceViewOptions,
);

const getFocusedDifferenceId = createSelector(
  [getInspectionstate],
  (inspectionState) => inspectionState.focusedDifferenceId,
);

const getPreviousDifferenceId = createSelector(
  [getInspectionstate],
  (inspectionState) => inspectionState.previousDifferenceId,
);

const getDifferencesLoadedSelector = createSelector(
  [getInspectionstate],
  (inspectionState) => inspectionState.differencesLoaded,
);

const getInspectionStarted = createSelector(
  [getInspectionStatus],
  (status) => status === InspectionStatuses.queued || status === InspectionStatuses.inprogress,
);

const getInspectionCompleted = createSelector(
  [getInspectionStatus],
  (status) => status === InspectionStatuses.completed,
);

const getInspectionFailed = createSelector([getInspectionStatus], (status) => status === InspectionStatuses.error);

const getInspectionIdle = createSelector([getInspectionStatus], (status) => status === InspectionStatuses.idle);

const getDocumentsLoaded = createSelector(
  [getSourceLoaded, getTargetLoaded, getInspectionSettings],
  (source, target, settings) => {
    if (settings.singleFile) {
      return target;
    }
    return source && target;
  },
);

const getAnnotations = createSelector([getSourceAnnotations, getTargetAnnotations], (source, target) => ({
  source,
  target,
}));

const getCurrentPage = createSelector([getSourceCurrentPage, getTargetCurrentPage], (source, target) => ({
  source,
  target,
}));

const getPages = createSelector([getSourcePages, getTargetPages], (source, target) => ({
  source,
  target,
}));

const getDocumentsState = createSelector(
  [getSourceState, getTargetState, getReportState],
  (source, target, report) => ({
    source,
    target,
    report,
  }),
);

const getUpdateInspectionData = createSelector(
  [getInspectionInput, getFilesIds, getInspectionSettings, getSelectedCustomDictionaryIDs],
  (input, filesIds, settings, customDictionaryIDs) => ({
    input,
    status: InspectionStatuses.idle,
    masterFileId: filesIds.masterFileId || null,
    sampleFileId: filesIds.sampleFileId || null,
    settings,
    customDictionaryIDs,
  }),
);

const getInspectionHasRun = createSelector([getInspectionLastJobId], (lastJobId) => lastJobId !== null);

// check if the inspection has results but the markups have change
const getInspectionIsOutdated = createSelector(
  [getInspectionLastJobId, getInspectionStatus],
  (lastJobId, status) => lastJobId !== null && status !== InspectionStatuses.completed,
);

const getZoomLockLevel = createSelector(
  [getZoomLockLevelSource, getZoomLockLevelTarget, getZoomLockLevelReport],
  (source, target, report): ZoomLockLevel => ({
    [DocumentTypes.source]: source,
    [DocumentTypes.target]: target,
    [ReportDocumentTypes.report]: report,
  }),
);

const getAllPageLoadedSelector = createSelector(
  [getSourceLoaded, getTargetLoaded, getInspectionSettings, getDifferenceViewOptions],
  (source, target, settings, viewOptions) => {
    if (settings.singleFile) {
      return target;
    }
    if (viewOptions.hideDisplayPanels.source) {
      return target;
    } else if (viewOptions.hideDisplayPanels.target) {
      return source;
    } else {
      return source && target;
    }
  },
);

const getNumberOfAnnotations = createSelector([getAnnotations], (annotations) => {
  const annotationCounters = {
    [PDFTronTools.GRAPHIC]: {
      source: { shifted: 0, scaled: 0, autoGraphic: 0, matchedGraphic: 0 },
    },
    [PDFTronTools.ZONE]: {
      source: 0,
    },
    [PDFTronTools.MARQUEE_ZONE]: {
      source: 0,
    },
    [PDFTronTools.CROSSOUT]: {
      source: 0,
      target: 0,
    },
    [PDFTronTools.CROP]: {
      source: 0,
      target: 0,
    },
  };

  annotations.source.forEach((annot) => {
    const { usedTool } = annot;
    if (annotationCounters) {
      if (
        usedTool === PDFTronTools.ZONE ||
        usedTool === PDFTronTools.MARQUEE_ZONE ||
        usedTool === PDFTronTools.CROSSOUT ||
        usedTool === PDFTronTools.CROP
      ) {
        annotationCounters[usedTool].source++;
      } else if (usedTool === PDFTronTools.GRAPHIC) {
        if (annot.graphicZone?.scaled) {
          annotationCounters[PDFTronTools.GRAPHIC].source.scaled++;
        } else if (annot.graphicZone?.autoGraphic) {
          annotationCounters[PDFTronTools.GRAPHIC].source.autoGraphic++;
        } else if (annot.graphicZone?.matchedGraphic) {
          annotationCounters[PDFTronTools.GRAPHIC].source.matchedGraphic++;
        } else if (annot.graphicZone?.shifted) {
          annotationCounters[PDFTronTools.GRAPHIC].source.shifted++;
        }
      }
    }
  });
  annotations.target.forEach((annot) => {
    if (annotationCounters) {
      const { usedTool } = annot;
      if (usedTool === PDFTronTools.CROSSOUT) {
        annotationCounters[PDFTronTools.CROSSOUT].target++;
      }
      if (usedTool === PDFTronTools.CROP) {
        annotationCounters[PDFTronTools.CROP].target++;
      }
    }
  });
  return annotationCounters;
});

const getSpellingTrackingData = createSelector([getInspectionSettings], (settings): string => {
  if (!settings.dictionaryName) return 'false';
  const selectedDictionary = Object.values(Dictionaries).find(
    (dictionary: string) => dictionary === settings.dictionaryName,
  );
  return `true+${selectedDictionary}`;
});

const getIsFiltering = createSelector(
  [getMutationSelector({ type: updateDifferenceViewOptions, requestKey: 'updateFilters' })],
  (inspectionMutation: MutationState) => {
    return inspectionMutation.loading;
  },
);

const getDocumentToast = createSelector(
  [getSourceDocumentToast, getTargetDocumentToast],
  (sourceMessage, targetMessage): DocumentToastState => ({
    [DocumentTypes.source]: sourceMessage,
    [DocumentTypes.target]: targetMessage,
  }),
);

const getShiftedGraphicRefId = (state: StoreState) => state.inspection.shiftedGraphicRefId;

const getSelectedSubDifferenceComment = createSelector(
  [getSubDifferenceComments, getSelectedSubDifferenceID],
  (subDifferenceComments, selectedSubDifferenceID): string => subDifferenceComments[selectedSubDifferenceID],
);

const getisBarcode = (state: StoreState) => state.inspection.isBarcode;

const getUnmatchedPages = (state: StoreState) => state.inspection.unmatchedPages;

const getIsResultsPanelHidden = (state: StoreState) => state.inspection.isResultsPanelHidden;

const getGraphicsSensitivity = (state: StoreState) => state.inspection.settings.graphics?.sensitivity;

const getHighSensitivitySelected = createSelector(
  getGraphicsSensitivity,
  (sensitivity) => sensitivity === GraphicsSensitivity.HIGH,
);

const getGraphicsMatchingMode = (state: StoreState) => state.inspection.settings.graphics?.matchingMode;

const getOneToOneSelected = createSelector(
  getGraphicsMatchingMode,
  (matchingMode) => matchingMode === GraphicsMatchingMode.OneToOne,
);

export {
  getPageRangeSelector,
  getPageLoadedSelector,
  getLiveTextNotFound,
  getLiveTextStatus,
  getTextProcessStatus,
  getInspectionId,
  getName,
  getDifferencePanelOpen,
  getChangeGridDirection,
  getTotalPageCount,
  getDocumentDisplayedSelector,
  getDifferenceViewOptions,
  getFocusedDifferenceId,
  getDifferencesLoadedSelector,
  getInspectionStarted,
  getSearchingForGraphic,
  getZoneSelectedTexts,
  getInspectionCompleted,
  getInspectionFailed,
  getSelectedTool,
  getManualSelectedZoneId,
  getManualSelectedAnnotationId,
  getDocumentsLoaded,
  getReportLoaded,
  getAnnotations,
  getCurrentPage,
  getPages,
  getSyncScrolling,
  getZoomChanging,
  getZoomOption,
  getInvalidScaledGraphicZoneRatio,
  getUnconfirmTextZoneError,
  getUnconfirmGraphicZoneError,
  getUnconfirmedMarqueeZoneError,
  getNoResultAnnotationId,
  getSelectedSubDifferenceID,
  getSubDifferenceComments,
  getPersistedDifferenceComments,
  getDocumentsState,
  getUpdateInspectionData,
  getInspectionHasRun,
  getInspectionIsOutdated,
  getCreateInspectionData,
  getShowDiscarded,
  getSelectingZone,
  getOpenPanel,
  getInspectionIdle,
  getIsSingleFile,
  getPagesData,
  getExcludedPage,
  getPageRangeOption,
  getZoomLockLevel,
  getShowLibrary,
  getPreviousDifferenceId,
  getInternalAnnotationVisibility,
  getInternalAnnotationsVisibilityDefault,
  getNextTextZoneId,
  getNextGraphicZoneId,
  getNextCropZoneId,
  getNextMarqueeZoneId,
  getNumberOfAnnotations,
  getInspectionSettings,
  getSelectedCustomDictionaryIDs,
  getTableEnhancementEnabled,
  getTargetLiveText,
  getSourceLiveText,
  getSpellingTrackingData,
  getIsFiltering,
  getDocumentToast,
  getLastJobInput,
  getShiftedGraphicRefId,
  getNextCrossoutZoneId,
  getSelectedSubDifferenceComment,
  getAutoMatchGraphic,
  getIsFilesLoaded,
  getLoadingDifferences,
  getSourceLayers,
  getTargetLayers,
  getSourceSeparations,
  getTargetSeparations,
  getSeparationHidden,
  getisBarcode,
  getAllPageLoadedSelector,
  getUnmatchedPages,
  getEitherLiveText,
  getCtfJobs,
  getInspectionStatus,
  getIsResultsPanelHidden,
  getGraphicsSensitivity,
  getGraphicsMatchingMode,
  getHighSensitivitySelected,
  getOneToOneSelected,
  getFlashMode,
  getInspectionHasOutdatedGraphicZones,
  getInspectionHasOutdatedCropZones,
};
