
import { primaryTextColor, opacities, darkGreyBackground } from 'styles/vars';
import { Button, ButtonProps } from '@mui/material';
import { withStyles } from "tss-react/mui";

export interface GVTextButtonProps extends ButtonProps {
  text: string;
}

const textButton = ({ text, ...restProps }: GVTextButtonProps) => {
  return (
    <Button variant="text" {...restProps}>
      {text}
    </Button>
  );
};

const GVTextButton = withStyles(textButton, {
  root: {
    padding: '8px 16px',
    textTransform: 'capitalize',
    fontWeight: 'normal',
    fontSize: 12,
    letterSpacing: 0,
    lineHeight: '16px',
  },
  outlinedPrimary: {
    backgroundColor: darkGreyBackground,
    border: '1px solid #101113',
    '&.Mui-disabled': {
      color: primaryTextColor,
      backgroundColor: 'rgb(31, 33, 38, 0.50)',
      opacity: 0.5,
    },
    '&:hover': {
      border: '1px solid #101113',
      backgroundColor: '#2F3239',
    },
  },
  text: {
    padding: 0,
    fontWeight: 500,
    textAlign: 'center',
    letterSpacing: '0.025em',
    opacity: '0.7',
    '&:hover': {
      backgroundColor: 'transparent',
      opacity: opacities.superhigh,
    },
  },
  containedSecondary: {
    '&:hover': {
      backgroundColor: '#eb4542',
    },
    '&.Mui-disabled': {
      backgroundColor: '#77787d',
      color: `rgba(255,255,255, ${opacities.disabled})`,
    },
  },
});

export default GVTextButton;
