import { Difference } from './types';
import { getFlagValue } from 'utils/getFlagValue/getFlagValue';

const calculateSubDifferences = (differences: Difference[]): Difference[] => {
  const newDifferences: Difference[] = [];
  const subDiffList: Difference[] = [];
  const parentIds: string[] = [];
  const graphics2 = getFlagValue('graphics2') && getFlagValue('graphics_results_v2');

  differences.forEach((diff) => {
    if (diff.parentId) {
      if (graphics2) {
        // if we are dealing with differences from an inspection run prior to graphics2, we need to remove the parent/child structure
        if (!parentIds.includes(diff.parentId)) {
          parentIds.push(diff.parentId);
        }
        newDifferences.push(diff);
      } else {
        subDiffList.push(diff);
      }
    } else {
      newDifferences.push(diff);
    }
  });

  if (graphics2) {
    // exclude 'parent' diffs
    newDifferences.forEach((newDiff) => {
      if (parentIds.includes(newDiff.id)) {
        newDiff.excluded = true;
      }
    });
  } else {
    // Assign the children to their parent differences.
    subDiffList.forEach((subDiff) => {
      const parent = newDifferences.find((newDiff) => newDiff.id === subDiff.parentId);
      if (parent) {
        parent.subDiff.push(subDiff);
      }
    });
  }

  return newDifferences;
};

export default calculateSubDifferences;
