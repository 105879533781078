import React, { useEffect, useRef } from 'react';
import { Box, Grid, IconButton, Theme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { GVDocumentsTopBar } from 'components/common';
import GVIconButton from 'components/lib/GVIconButton/GVIconButton';
import { UploadPage } from 'components/pageElements';
import PDFTronAnnotationManager from 'components/PDFViewer/PDFAnnotationManager';
import PDFTronManager from 'components/PDFViewer/PDFTronManager';
import { PDFManagerFactory, useAnnotationTools } from 'pdftron';
import { actions } from 'pdftron/docManager/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  app,
  files,
  getConvertingFiles,
  getDifferenceViewOptions,
  getInspectionHasRun,
  getInspectionId,
  getIsFetchingInspectionFiles,
  getIsFilesLoaded,
  getIsSingleFile,
  getMultiFileNamesJoined,
  getUploadedFilesSelector,
  inspection,
} from 'store';
import { getStyleVariables } from 'styles/vars';
import { DocumentTypes, PDFTRON_DEFAULT_TOOL } from 'types';
import { useConfirmation } from 'utils';
import { isPrepUrl, isReportsUrl, isResultUrl } from 'utils/location';
import { DocumentToast } from 'components/DocumentToast/DocumentToast';
import store from 'store/store';
import { GVTypography } from 'components/lib';
import getFileIconFromExtension, { getMergedFileIcon } from 'components/pageElements/UploadPage/FileHelpers';
import getFileName from 'components/pageElements/UploadPage/FileNameHelper';
import { useQuery } from '@redux-requests/react';
import { fetchMultipleFiles } from 'store/request/files/actions';
import ReorderFiles from 'components/pageElements/UploadPage/ReorderFiles';
import PDFViewer from './PDFViewer';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    panel: styleVariables.splitPanel.panel,
    hiddenPanel: { visibility: 'hidden', width: styleVariables.splitPanel.hiddenPanel.width },
    panelElement: styleVariables.splitPanel.panelElement,
    singleFilePanelElement: styleVariables.splitPanel.singleFilePanelElement,
    fileIcon: styleVariables.splitPanel.fileIcon,
    fileTextName: styleVariables.splitPanel.fileTextName,
    buttonClose: styleVariables.splitPanel.buttonClose,
    hiddenDisplay: {
      visibility: 'hidden',
      width: 0,
    },
    panelView: {
      '&:hover .hidden_panel': {
        display: 'inline-flex',
      },
    },
    selectedPanel: {
      display: 'inline-flex',
    },
    panelIcon: {
      padding: 0,
      margin: '1px',
      display: 'none',
      opacity: 0.8,
      transition: '0.2s',
      '&:hover': {
        opacity: 1,
      },
    },
  };
});

const DocumentsPanel = () => {
  const sourceDocumentToastRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const targetDocumentToastRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const filesState = useSelector(getUploadedFilesSelector);
  const isFetchingInspection = useSelector(getIsFetchingInspectionFiles);
  const convertingFiles = useSelector(getConvertingFiles);
  const inspectionHasRun = useSelector(getInspectionHasRun);
  const confirm = useConfirmation();
  const pdfDocManager = PDFManagerFactory.getPDFDocManager();
  const unconfirmedZones = pdfDocManager?.getUnconfirmedZones();
  const inspectionId = useSelector(getInspectionId);
  const isFilesLoaded = useSelector(getIsFilesLoaded);
  const { data: multipleFilesData } = useQuery({ type: fetchMultipleFiles });
  const isSingleFile = useSelector(getIsSingleFile);
  const [documents, setDocuments] = React.useState([DocumentTypes.source, DocumentTypes.target]);
  const [panelStyles, setPanelStyles] = React.useState(classes.panelElement);
  const hideDisplay = useSelector(getDifferenceViewOptions).hideDisplayPanels;

  useAnnotationTools();
  const mergedFileNames = {
    [DocumentTypes.source]: useSelector(getMultiFileNamesJoined(DocumentTypes.source)),
    [DocumentTypes.target]: useSelector(getMultiFileNamesJoined(DocumentTypes.target)),
  };
  /**
   * Removing Left over and not confirmed zones
   */
  const deleteUnconfirmedZones = () => {
    // Deactivating manual selection for Marquee zones
    store.dispatch(inspection.actions.deactivateManualSelection());

    // Removing text zone and the searched text
    unconfirmedZones?.forEach((annot) => {
      PDFTronAnnotationManager.deleteAnnotationById(DocumentTypes.source, annot.Id);

      store.dispatch(
        inspection.actions.deleteSelectedZone({
          documentType: DocumentTypes.source,
          annotationId: annot.Id,
        }),
      );

      store.dispatch(inspection.actions.resetSelectedTexts());
    });
  };

  // If the inspection is empty and the user refreshes, redirected to home.
  useEffect(() => {
    if (inspectionId && !isFilesLoaded && !filesState.source.fileId && !filesState.target.fileId) {
      // Same as pressing the home button
      dispatch(app.actions.resetStore());
      dispatch(files.actions.resetStore());
      dispatch(inspection.actions.resetStore());
      dispatch(app.actions.setShowLiveText(false));
      // navigate('/home');
    }
  }, [inspectionId]);

  // @pdftron-refactor check if this useEffect can be removed and re-use the state of docManager slice somehow?
  useEffect(() => {
    // check if both files from new inspection are loaded
    if (isFetchingInspection && (filesState.source.fileId || filesState.target.fileId)) {
      dispatch(files.actions.setIsFetchingInspectionFiles(false));
    }
  }, [dispatch, isFetchingInspection, filesState]);

  /**
   *  When the url changes we need to hide/show annotations
   *  For prep url, we have to show Zones and Markups but hide Differences
   *  For results url, we have to hide Zones and Markups and show Differences
   *  @pdftron-refactor maybe in the future, we could know about this directly on the saga so we wouldn't need to have an effect?
   *  Or perhaps, handle this on the tab click?
   *  */
  useEffect(() => {
    if (unconfirmedZones) {
      if (!isPrepUrl(location) && unconfirmedZones.length > 0) {
        deleteUnconfirmedZones();
      }
    }

    dispatch(actions.reloadAnnotations());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    // setting the panel styles here to make sure they don't change before the number of documents changes
    if (isSingleFile) {
      setDocuments([DocumentTypes.target]);
      setPanelStyles(classes.singleFilePanelElement);
    } else {
      if (hideDisplay.source) {
        setPanelStyles(classes.singleFilePanelElement);
      } else if (hideDisplay.target) {
        setPanelStyles(classes.singleFilePanelElement);
      } else {
        setDocuments([DocumentTypes.source, DocumentTypes.target]);
        setPanelStyles(classes.panelElement);
      }
    }
  }, [isSingleFile, hideDisplay.source, hideDisplay.target]);

  // @pdftron-refactor perhaps this component shouldnt be mounted at all on this state, and perhaps this should happen outside this component?
  if (isFetchingInspection) {
    return null;
  }

  const renderViewButton = (docType: DocumentTypes) => {
    const sourceHidden = hideDisplay.source;
    const targetHidden = hideDisplay.target;

    if ((docType === DocumentTypes.target && !targetHidden) || (docType === DocumentTypes.source && targetHidden)) {
      const renderButton = ({ source, target }: { source: Boolean; target: Boolean }) => {
        const isSelected = source === !sourceHidden && target === !targetHidden;
        return (
          <IconButton
            onClick={() => {
              dispatch(
                inspection.actions.setDifferenceViewOptions({
                  hideDisplayPanels: { [DocumentTypes.source]: !source, [DocumentTypes.target]: !target },
                }),
              );
            }}
            className={` ${classes.panelIcon} ${!isSelected ? 'hidden_panel' : classes.selectedPanel}`}
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 6H5.99999C5.26666 6 4.66666 6.6 4.66666 7.33333V16.6667C4.66666 17.4 5.26666 18 5.99999 18H18C18.7333 18 19.3333 17.4 19.3333 16.6667V7.33333C19.3333 6.6 18.7333 6 18 6ZM18 16.6667H5.99999V7.33333H18V16.6667Z"
                fill="white"
                fillOpacity="0.87"
              />
              {source && (
                <rect x="7.33334" y="8.66667" width="4" height="6.66667" rx="1" fill="white" fillOpacity="0.87" />
              )}
              {target && (
                <rect x="12.6667" y="8.66667" width="4" height="6.66667" rx="1" fill="white" fillOpacity="0.87" />
              )}
            </svg>
          </IconButton>
        );
      };

      return (
        <Grid
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ height: '100%' }}
          className={classes.panelView}
        >
          <GVTypography sx={{ marginRight: '2px', fontWeight: 600, cursor: 'default' }}>View</GVTypography>
          {renderButton({ source: true, target: false })}
          {renderButton({ source: false, target: true })}
          {renderButton({ source: true, target: true })}
        </Grid>
      );
    }
  };

  return (
    <>
      {multipleFilesData && (
        <ReorderFiles
          uploadedFiles={multipleFilesData.uploadedFiles}
          failedFiles={multipleFilesData.failedFiles}
          documentType={multipleFilesData.documentType}
        />
      )}
      <Grid container direction="column" wrap="nowrap" className={`${isReportsUrl() ? classes.hiddenPanel : null}`}>
        <Grid item>
          <GVDocumentsTopBar />
        </Grid>
        <Grid className={classes.panel} justifyContent="space-between" display="flex">
          {documents.map((document) => {
            const file = filesState[document];
            let fileIconElement;
            if (file.mergedFileData && file.mergedFileData.length > 0) {
              fileIconElement = getMergedFileIcon(file.mergedFileData);
            } else {
              fileIconElement = getFileIconFromExtension(file.originalName);
            }

            let fileNameDocType = '';
            if (!isSingleFile) {
              fileNameDocType = document === DocumentTypes.source ? ' (Source)' : ' (New)';
            }

            const title = document === DocumentTypes.source ? 'Source' : 'New';
            const atLeastOneFileLoaded = file.fileId !== '' && !convertingFiles[document];

            let style = panelStyles;
            if (
              (hideDisplay.source && document === DocumentTypes.source) ||
              (hideDisplay.target && document === DocumentTypes.target)
            ) {
              style = classes.hiddenDisplay;
            }
            return (
              <Grid key={document} item className={style} columnSpacing={3} data-testid={`${document}_panel`}>
                {atLeastOneFileLoaded ? (
                  <>
                    <DocumentToast
                      anchorEl={
                        document === DocumentTypes.source
                          ? sourceDocumentToastRef.current
                          : targetDocumentToastRef.current
                      }
                      documentType={document}
                    />
                    <Grid
                      container
                      justifyContent="space-between"
                      ref={document === DocumentTypes.source ? sourceDocumentToastRef : targetDocumentToastRef}
                    >
                      <Grid item container xs={9}>
                        <Grid className={classes.fileIcon}>{fileIconElement}</Grid>
                        <Grid item xs={10} sx={{ width: 0 }}>
                          <GVTypography
                            variant="subtitle1"
                            color="textSecondary"
                            noWrap
                            data-testid={`${document}_file_name`}
                            className={classes.fileTextName}
                            emphasis="superhigh"
                          >
                            {getFileName(file)}
                            {fileNameDocType}
                          </GVTypography>
                        </Grid>
                      </Grid>

                      <Grid item xs={1} container justifyContent="flex-end">
                        {!isSingleFile && isResultUrl(location) ? (
                          renderViewButton(document)
                        ) : (
                          <GVIconButton
                            disableRipple
                            disableFocusRipple
                            id={`${document}-RemoveFileButton`}
                            data-testid={`${document}_remove_file_button`}
                            onClick={() => {
                              confirm({
                                title: 'Remove file',
                                variant: 'danger',
                                catchOnCancel: false,
                                description:
                                  'Are you sure you would like to remove this file? You will lose all the results of your Inspection.',
                                acceptLabel: 'Confirm',
                                autoConfirm: !inspectionHasRun,
                              }).then(() => {
                                // when we remove one file, we need to delete the annotations from both files
                                // and reset the selected tool
                                PDFTronAnnotationManager.deleteAllInputAnnotations();
                                PDFTronManager.setToolMode(PDFTRON_DEFAULT_TOOL);
                                PDFTronManager.removeLiveText(DocumentTypes.source);
                                PDFTronManager.removeLiveText(DocumentTypes.target);
                                PDFManagerFactory.getPDFDocManager()?.cleanDocumentHighlight();
                                PDFManagerFactory.getPDFDocManager()?.deleteExistingInspectedAnnotations(
                                  DocumentTypes.source,
                                );
                                PDFManagerFactory.getPDFDocManager()?.deleteExistingInspectedAnnotations(
                                  DocumentTypes.target,
                                );
                                store.dispatch(inspection.actions.resetLastJobInput());

                                // create segment event before document name is lost in state change
                                if (window.analytics) {
                                  window.analytics.track('document-deleted', {
                                    fileName:
                                      filesState[DocumentTypes[document]].originalName ||
                                      mergedFileNames[DocumentTypes[document]],
                                    panel: DocumentTypes[document] === 'target' ? 'new' : 'source',
                                  });
                                }

                                // Remove file
                                dispatch(
                                  files.actions.removeFile({
                                    documentType: DocumentTypes[document],
                                  }),
                                );

                                dispatch(files.actions.setOCRHasRun(false));

                                // Reset document state
                                dispatch(
                                  inspection.actions.resetDocumentState({
                                    documentType: DocumentTypes[document],
                                  }),
                                );
                                // Make sure preview panel is closed
                                dispatch(inspection.actions.setOpenPanel(false));
                                dispatch(inspection.actions.setInspectionSettings({ fullPageGraphics: false }));
                                dispatch(inspection.actions.setInspectionSettings({ brailleLanguage: '' }));
                                dispatch(inspection.actions.setInspectionSettings({ barcode: false }));
                                dispatch(inspection.actions.setInspectionSettings({ dictionaryName: undefined }));
                                dispatch(inspection.actions.setInspectionSettings({ text: false }));
                                dispatch(inspection.actions.setUnmatchedPages([]));
                              });
                            }}
                            className={classes.buttonClose}
                            size="small"
                            icon={<CloseIcon color="error" />}
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Box height="100%" boxShadow={2} style={{ position: 'relative' }}>
                      <PDFViewer
                        key={`documentviewer_${document}`}
                        file={file.url}
                        document={DocumentTypes[document]}
                        fileId={file.fileId}
                      />
                    </Box>
                  </>
                ) : (
                  <UploadPage title={title} document={document} />
                )}
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};

export default DocumentsPanel;
