import { GVToggleButton } from 'components/common';
import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import { useSelector } from 'react-redux';
import { getAllAnnotationsLoaded, getInspectionSettings, getSelectedTool } from 'store';
import { makeStyles } from 'tss-react/mui';
import { PDFTool, PDFTronTools } from 'types';
import { GraphicIcon } from 'components/icons';

const useStyles = makeStyles()(() => {
  return {
    toggleButton: { padding: '0px', height: '48px', width: '48px' },
    toggleIcon: { fontSize: '40px', padding: '0' },
  };
});

interface CrossoutToolProps {
  handleSelectTool: (tool: PDFTool) => void;
}
const GraphicTool = ({ handleSelectTool }: CrossoutToolProps) => {
  const { classes } = useStyles();

  const annotationLoaded = useSelector(getAllAnnotationsLoaded);
  const selectedTool = useSelector(getSelectedTool);

  return (
    <GVTooltip title={'Zone Graphics'} placement="bottom">
      <span>
        <GVToggleButton
          data-testid="markup_tools_graphics"
          value={PDFTronTools.GRAPHIC}
          id="zoneArea"
          disabled={!annotationLoaded}
          selected={selectedTool === PDFTronTools.GRAPHIC}
          onChange={() => {
            handleSelectTool(PDFTronTools.GRAPHIC);
          }}
          className={classes.toggleButton}
        >
          <GraphicIcon fontSize="inherit" className={classes.toggleIcon} />
        </GVToggleButton>
      </span>
    </GVTooltip>
  );
};

export default GraphicTool;
