import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const GraphicsDifferenceIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16" sx={{ width: '16px', height: '16px' }}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.0013 2H2.0013C1.33464 2 0.667969 2.66667 0.667969 3.33333V12.6667C0.667969 13.4 1.26797 14 2.0013 14H14.0013C14.668 14 15.3346 13.3333 15.3346 12.6667V3.33333C15.3346 2.66667 14.668 2 14.0013 2ZM14.0013 12.6133C13.988 12.6333 13.9613 12.6533 13.948 12.6667H2.0013V3.38667L2.05464 3.33333H13.9413C13.9613 3.34667 13.9813 3.37333 13.9946 3.38667V12.6133H14.0013ZM7.33464 10.34L5.66797 8.33333L3.33464 11.3333H12.668L9.66797 7.33333L7.33464 10.34Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default GraphicsDifferenceIcon;
