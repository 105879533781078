import { SerializedGraphicAnnotation } from 'pdftron/docManager/AnnotationTools/GraphicZone';
import { DocumentToast } from 'components/DocumentToast/DocumentToast';
import { Dictionaries } from './dictionaries';
import { CtfMatchResult } from 'store/request/match/types';
import { SerializedCropZoneAnnotation } from 'pdftron/docManager/AnnotationTools/CropZone';

export enum DocumentStates {
  INITIAL = 'INITIAL',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
}

export enum DocumentTextProcessing {
  INITIAL = 'INITIAL',
  COMPLETED = 'COMPLETED',
}

export interface SubDifferenceComment {
  diffId: string;
  comment: string;
}

export interface PersistedDifferenceComment {
  diffId: string;
  comment: string;
}

export interface DifferenceViewOptions {
  filters: { [index: string]: boolean };
  sortBy?: SortDifferencesOption;
  selectedModule: CTFModuleTypes | 'All';
  hideDisplayPanels: { [key in DocumentTypes]: boolean };
}

export interface Quad {
  getPoints(): any;
  toRect(): any;
  x1: number;
  x2: number;
  x3: number;
  x4: number;
  y1: number;
  y2: number;
  y3: number;
  y4: number;
}

export interface InputZone {
  number: number;
  quads: Quad[];
  usedTool?: PDFTronToolsDBVals;
  annotationId: string;
}

export interface Crossout {
  number: number;
  quads: Quad[];
}

export interface Crop {
  pageNumber: number;

  x1: number;

  x2: number;

  y1: number;

  y2: number;
}

export interface InputPage {
  number: number;
  included: boolean;
  underline?: Quad[][] | null;
  crossout?: Crossout[] | null;
  crop?: Crop[] | null;
  zone?: InputZone[] | null;
}

export interface InputDocument {
  pages: InputPage[];
  graphicZones?: SerializedGraphicAnnotation[];
  cropZones?: SerializedCropZoneAnnotation[];
  layers?: Layer[];
  separations?: Separation[];
  type: 'master' | 'sample';
}

export interface InputDocuments {
  documents: InputDocument[];
}

export interface SearchedAnnotation {
  annotationId: string;
  text: string;
  zoneId: number;
}

export interface SelectedText {
  annotationId: string;
  text: string;
  zoneId: string;
  selected: boolean;
  hasResult: boolean;
  searchedAnnotations: Array<SearchedAnnotation>;
}

export interface Layer {
  name: string;
  visible: boolean;
  children?: Layer[];
}

export interface Separation {
  name: string;
  enabled: boolean;
  rgb: number[];
}

export interface DocumentData {
  state: DocumentStates.INITIAL | DocumentStates.LOADING | DocumentStates.LOADED;
  pageRange: Array<number>;
  // perhaps this should be renamed to inputAnnotations instead of annotations, representing input tool annotations
  annotations: Array<InputAnnotation>;
  liveText: boolean;
  documentTextProcessing: DocumentTextProcessing.INITIAL | DocumentTextProcessing.COMPLETED;
  zoneSelectedTexts: Array<SelectedText>;
  pages: { [key: number]: PageState };
  totalPageCount: number;
  currentPage: number;
  layers?: Array<Layer>;
  separations?: Array<Separation>;
}

export enum PDFTronTools {
  PAN = 'Pan',
  UNDERLINE = 'AnnotationCreateTextUnderline',
  CROSSOUT = 'AnnotationCreateTextStrikeout',
  ZONE = 'TextZoneCreateTool',
  HIGHLIGHT = 'AnnotationCreateTextHighlight',
  GRAPHIC = 'GraphicCreateTool',
  MARQUEE_ZONE = 'MarqueeZoneCreateTool',
  SHIFTED_GRAPHIC = 'ShiftedGraphicCreateTool',
  SCALED_GRAPHIC = 'ScaledGraphicCreateTool',
  CROP = 'CropTool',
}

export enum PDFTronToolsDBVals {
  PAN = 'PAN',
  UNDERLINE = 'UNDERLINE',
  CROSSOUT = 'CROSSOUT',
  ZONE = 'ZONE',
  HIGHLIGHT = 'HIGHLIGHT',
  GRAPHIC = 'GRAPHIC',
  MARQUEE_ZONE = 'MARQUEE_ZONE',
}

export const PDFTRON_DEFAULT_TOOL = PDFTronTools.PAN;

export enum PDFTronZoomTools {
  MARQUEE = 'MarqueeZoomTool',
}

export type GraphicTool = PDFTronTools.GRAPHIC;

export type CropTool = PDFTronTools.CROP;

export type TextTool =
  | PDFTronTools.PAN
  | PDFTronTools.UNDERLINE
  | PDFTronTools.CROSSOUT
  | PDFTronTools.ZONE
  | PDFTronTools.HIGHLIGHT
  | PDFTronTools.MARQUEE_ZONE
  | PDFTronTools.SHIFTED_GRAPHIC
  | PDFTronTools.SCALED_GRAPHIC;

export type PDFTool = TextTool | GraphicTool | CropTool;

export enum DocumentTypes {
  source = 'source',
  target = 'target',
}

export enum ReportDocumentTypes {
  report = 'report',
}

export type AllDocumentTypes = DocumentTypes | ReportDocumentTypes;

export const OPPOSITE_DOCUMENT = {
  [DocumentTypes.source]: DocumentTypes.target,
  [DocumentTypes.target]: DocumentTypes.source,
  [ReportDocumentTypes.report]: ReportDocumentTypes.report,
};

export enum PageRangeOption {
  ALL = 'all',
  RANGE = 'range',
}

export type DocumentType = DocumentTypes.source | DocumentTypes.target;

export interface InputAnnotation {
  annotationId: string;
  usedTool?: PDFTool;
  page: number;
  quads?: Quad[];
  graphicZone?: SerializedGraphicAnnotation;
  cropZone?: SerializedCropZoneAnnotation;
  number?: number;
}

export enum GVAnnotationType {
  excludedPage = 'Excluded_Grey_Out',
}

export enum AnnotationVisibilityOptions {
  hidden = 'hidden',
  placeholder = 'placeholder', // same properties as a visible annotation but hidden by default
  visible = 'visible',
}

export interface Inspection {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  inspectionStatus: InspectionStatuses;
}

export enum CTFModuleTypes {
  text = 'Text',
  spelling = 'Spelling',
  graphics = 'Graphics',
  barcode = 'Barcode',
  // for retrocompatibility
  annotation = 'Annotation',
  braille = 'Braille',
}

interface CTFJobRawResults {
  // TODO: type this?
  differences: Array<any>;
  matches?: CtfMatchResult[];
}

interface CTFJob {
  id: string;
  type: CTFModuleTypes;
  rawResults: CTFJobRawResults;
  status: InspectionStatuses;
  error?: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface Job {
  id: string;
  inspectionId: string;
  input?: InputDocuments;
  createdAt: string;
  metadata: JobMetaData;
  ctfJobs: CTFJob[];
}

export interface JobMetaData {
  errors?: JobErrors;
  isBarcode: boolean;
  isFullPageGraphics: boolean;
}

export interface JobErrors {
  spelling?: string;
  barcodes?: string;
  text2?: string;
  graphics?: string;
}

export interface ShowLibrary {
  [DocumentTypes.source]: boolean;
  [DocumentTypes.target]: boolean;
}

export interface ZoomOptionState {
  [ReportDocumentTypes.report]: '' | PageWidthFit;
  [DocumentTypes.source]: '' | PageWidthFit;
  [DocumentTypes.target]: '' | PageWidthFit;
}

export interface InspectionSettings {
  tableEnhancement?: boolean;
  dictionaryName?: Dictionaries;
  fullPageGraphics?: boolean;
  barcode?: boolean;
  brailleLanguage?: string;
  singleFile?: boolean;
  text?: boolean;
  graphics?: GraphicsOptions;
}

export interface AutoMatchGraphic {
  autoMatchFail?: number;
  autoMatchPass?: number;
}

export enum GraphicsOptionKey {
  Sensitivity = 'sensitivity',
  MatchingMode = 'matchingMode',
}
export enum GraphicsSensitivity {
  HIGH = 'High',
  LOW = 'Low',
}

export enum GraphicsMatchingMode {
  OneToOne = 'OneToOne',
  OneToMany = 'OneToMany',
}

export type GraphicsOptionValue = GraphicsSensitivity | GraphicsMatchingMode;

export interface GraphicsOptions {
  [GraphicsOptionKey.Sensitivity]?: GraphicsSensitivity;
  [GraphicsOptionKey.MatchingMode]?: GraphicsMatchingMode;
}

export interface InspectionState {
  source: DocumentData;
  target: DocumentData;
  report: DocumentData;
  selectedTool: PDFTool;
  differenceViewOptions: DifferenceViewOptions;
  differencesLoaded: boolean;
  focusedDifferenceId: string;
  previousDifferenceId: string;
  selectedSubDifferenceId: string;
  subDifferenceComments: Record<string, string>;
  persistedDifferenceComments: Record<string, string>;
  syncScrolling: boolean;
  zoomChanging: boolean;
  noResultAnnotationId: string;
  invalidScaledGraphicZoneRatio: boolean;
  unconfirmTextZoneError: boolean;
  unconfirmedGraphicZoneError: boolean;
  unconfirmedMarqueeZoneError: boolean;
  loadingInspection: boolean;
  name: string;
  inspectionId: string;
  inspectionStatus: InspectionStatuses | '';
  manualSelectedZoneId: number;
  manualSelectedAnnotationId: string;
  lastJobId: string | null;
  lastJobInput: InputDocuments | null;
  ctfJobs: (CTFModuleTypes | 'All')[];
  zoomOption: ZoomOptionState;
  showDiscarded: boolean;
  selectingZone: boolean;
  openPanel: boolean;
  pageRangeOption: PageRangeOption;
  zoomLockLevel: ZoomLockLevel;
  showLibrary: ShowLibrary;
  internalAnnotationsVisibility: boolean;
  internalAnnotationsVisibilityDefault: boolean;
  tableEnhancementEnabledState: boolean;
  nextTextZoneId: number;
  nextGraphicZoneId: number;
  nextCropZoneId: number;
  nextMarqueeZoneId: number;
  nextCrossoutZoneId: number;
  settings: InspectionSettings;
  autoMatchGraphic: AutoMatchGraphic;
  customDictionaryIDs: string[];
  updatedAt: string;
  differencePanelOpen: boolean;
  changeGridDirection: string;
  documentToast: DocumentToastState;
  // REFACTOR: should be moved out of inspection store to somewhere more relevant
  shiftedGraphicRefId: string;
  searchingForGraphicZone: boolean;
  isFilesLoaded: boolean;
  reloadingDifferences: boolean;
  isBarcode: boolean;
  brailleLanguage: string;
  // Error: inspection did not complete
  // all: every page was unmatched
  // none: no pages were unmatched
  unmatchedPages: number[];
  isResultsPanelHidden: boolean;
  flashMode: boolean;
}

export interface PageState {
  thumbnail: string;
  loaded: boolean;
  fullImage?: string;
}

export enum PageWidthFit {
  fitPage = 'FitPage',
  fitWidth = 'FitWidth',
}
export interface StartInspectionData {
  noLiveText: boolean;
}

export enum InspectionStatuses {
  idle = 'PENDING',
  queued = 'QUEUED',
  inprogress = 'IN_PROGRESS',
  completed = 'COMPLETE',
  error = 'ERROR',
  finalizing = 'FINALIZING',
}

export enum ZoomLevelLimits {
  // values represent zoom % / 100 with the way pdftron handles numbered zoom values
  maxZoom = 99.99,
  minZoom = 0.05,
  defaultZoom = 1,
  differenceMax = 5,
}

export enum ContentDifferences {
  Insertion = 'Insertion',
  Deletion = 'Deletion',
  Change = 'Change',
  Case = 'Case',
  Space = 'Space',
  ReadingOrder = 'ReadingOrder',
  Hyphen = 'Hyphen',
}

export enum FormattingDifferences {
  Bold = 'Bold',
  Italic = 'Italic',
  Underlined = 'Underlined',
  Subscript = 'Subscript',
  Superscript = 'Superscript',
  FontType = 'FontType',
  FontColor = 'FontColor',
  Size = 'Size',
  FontSize = 'FontSize',
  SoftHyphen = 'SoftHyphen',
  LineBreakSpace = 'LineBreakSpace',
}

export enum OtherDifferences {
  Annotation = 'Annotation',
  Spelling = 'Spelling',
  Barcode = 'Barcode',
  Braille = 'Braille',
  Graphics = 'Graphics',
}

export enum DifferenceTypeNames {
  Insertion = 'Insertion',
  Deletion = 'Deletion',
  Change = 'Change',
  Space = 'Space',
  Case = 'Case',
  Hyphen = 'Hyphen',
  Bold = 'Bold',
  Italic = 'Italic',
  Size = 'Size',
  FontSize = 'FontSize',
  Underlined = 'Underlined',
  Subscript = 'Subscript',
  Superscript = 'Superscript',
  FontType = 'FontType',
  FontColor = 'FontColor',
  SoftHyphen = 'SoftHyphen',
  LineBreakSpace = 'LineBreakSpace',
  ReadingOrder = 'ReadingOrder',
  Annotation = 'Annotation',
  Spelling = 'Spelling',
  Barcode = 'Barcode',
  Braille = 'Braille',
  Graphics = 'Graphics',
}

const TYPE_TO_DISPLAY_NAME: { [key in keyof typeof DifferenceTypeNames]?: string } = {
  FontType: 'Font Type',
  FontSize: 'Font Size',
  Size: 'Font Size',
  FontColor: 'Font Color',
  Underlined: 'Underline',
  Subscript: 'Sub/Super',
  Superscript: 'Sub/Super',
  LineBreakSpace: 'Line Break',
  SoftHyphen: 'Soft Hyphen',
  Hyphen: 'Change',
  ReadingOrder: 'Reading Order',
};

export const getDifferenceDisplayName = (type: DifferenceTypeNames) => {
  return TYPE_TO_DISPLAY_NAME[type] || type;
};

export enum SortDifferencesOption {
  readingOrder = 'order',
  differenceType = 'type',
}

export interface UpdateInspectionData {
  input?: InputDocuments;
  name?: string;
  sortOptions?: {
    sortBy: SortDifferencesOption;
    sortOrder?: string;
  };
  filterOptions?: { [key: string]: boolean };
  status?: InspectionStatuses;
  masterFileId?: string | null;
  sampleFileId?: string | null;
  settings?: InspectionSettings;
  autoMatchGraphic?: AutoMatchGraphic;
  customDictionaryIDS?: string[];
}

export enum ZoneCustomData {
  zoneId = 'zoneId',
  confirmed = 'confirmed',
  zoneAnnotation = 'zoneAnnotation',
  zoneSearch = 'zoneSearch',
  noResult = 'noResult',
}

export enum MarqueeZoneCustomData {
  zoneId = 'zoneId',
  confirmed = 'confirmed',
  marqueeZoneAnotation = 'marqueeZoneAnotation',
}

export enum CropZoneCustomData {
  cropZoneAnnotation = 'cropZoneAnnotation',
}

export enum GraphicZoneCustomData {
  graphicAnnotation = 'graphicAnnotation',
  zoneNumber = 'zoneNumber',
  confirmed = 'graphicConfirmed',
  readOnly = 'readOnly',
  shifted = 'shifted',
  scaled = 'scaled',
  matchedGraphicsAnnotation = 'matchedGraphicsAnnotation',
  autoGraphicsAnnotations = 'autoGraphicsAnnotations',
  rotationIndex = 'rotationIndex',
}

export enum SubDifferenceCustomData {
  subDifferenceParentId = 'subDifferenceParentId',
}

export enum InspectedAnnotationCustomData {
  inspectedAnnotation = 'inspectedAnnotation',
}

export enum ShiftedHighlightCustomData {
  overlayAnnotation = 'overlayAnnotation',
  windowAnnotation = 'windowAnnotation',
  refAnnotationIds = 'refAnnotationIds',
}

export enum AnnotationCustomData {
  zoneNumber = 'zoneNumber',
  strikeoutAnnotation = 'strikeoutAnnotation',
  drawMarkups = 'drawMarkups',
  difference = 'difference',
  liveText = 'liveText',
  highContrast = 'highContrast',
  refAnnotationIds = 'refAnnotationIds',
  deletionMarker = 'deletionMarker',
}

export enum GVAnnotationCustomData {
  // identifying annotations we create
  gvAnnotation = 'gvAnnotation',
}

export type ZoomLockLevel = {
  [key in AllDocumentTypes]: number | PageWidthFit | null;
};

export interface Group {
  id: string;
  inspectionId: string;
  createdAt: string;
  deletedAt: string;
  updatedAt: string;
  differenceList: string[];
  comment: string;
  excluded: boolean;
  viewed: boolean;
}

export interface GridCharacter {
  index: number;
  character: string;
  diff: boolean;
}
export interface Grids {
  masterGrid: GridCharacter[];
  sampleGrid: GridCharacter[];
}

export enum GridTypes {
  Change = 'Change',
  Hyphen = 'Hyphen',
  Space = 'Space',
  Case = 'Case',
}

export type DocumentToastState = {
  [key in DocumentTypes]: DocumentToast | null;
};

export interface AnnotationComment {
  author: string;
  content: string;
  updatedAt: Date;
}
