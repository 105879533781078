import { AppBar, Toolbar, Theme } from '@mui/material';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { GVIconText } from 'components/icons';
import { inspection, app, files } from 'store';
import { UserInfo, ProjectName } from 'components';
import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import { useTracker } from '../Tracker/TrackerProvider';
import { makeStyles } from 'tss-react/mui';

const useStyle = makeStyles()((theme: Theme) => ({
  avatar: {
    marginRight: theme.spacing(1),
  },
  icon: {
    height: theme.spacing(4.8),
    width: 'auto',
  },
}));

const TopNav = () => {
  const { classes } = useStyle();
  const dispatch = useDispatch();
  const tracker = useTracker();

  const handleResetInspection = () => {
    tracker.track({
      name: 'Home-page',
    });
    dispatch(app.actions.resetStore());
    dispatch(files.actions.resetStore());
    dispatch(inspection.actions.resetStore());
    dispatch(app.actions.setShowLiveText(false));
  };

  return (
    <>
      <AppBar elevation={0} color="secondary">
        <Toolbar>
          <GVTooltip title="Home" placement="bottom">
            <Link data-testid="home_link" to="/" onClick={handleResetInspection}>
              <GVIconText className={classes.icon} />
            </Link>
          </GVTooltip>
          <ProjectName />
          <UserInfo />
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
};

export default TopNav;
