import { createTheme, alpha, adaptV4Theme } from '@mui/material/styles';
import {
  paperBackgroundColor,
  mainBackgroundColor,
  primaryTextColor,
  lightGrey,
  menuLightGrey,
  opacities,
  darkGreyBackground,
} from 'styles/vars';
import { colors } from '../components/lib/global/styles';

const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: '#fff',
      },
      secondary: {
        main: '#ea3632',
      },
      background: {
        paper: paperBackgroundColor,
        default: mainBackgroundColor,
      },
      text: {
        primary: primaryTextColor,
        secondary: '#8a8c9f',
      },
      action: {
        disabled: lightGrey,
      },
      success: {
        main: '#2ED2AB',
      },
      error: {
        main: '#BA0C2F',
      },
    },
    typography: {
      fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: 12,
      h1: { fontWeight: 600, fontSize: 40, letterSpacing: 0, lineHeight: '56px' },
      h2: { fontWeight: 600, fontSize: 32, letterSpacing: 0, lineHeight: '44px' },
      h3: { fontWeight: 600, fontSize: 24, letterSpacing: 0, lineHeight: '32px' },
      h4: { fontWeight: 600, fontSize: 18, letterSpacing: 0, lineHeight: '24px' },
      subtitle1: { fontWeight: 600, fontSize: 12, letterSpacing: 0, lineHeight: '16px' },
      subtitle2: { fontWeight: 600, fontSize: 16, letterSpacing: 0, lineHeight: '16px' },
      body1: { fontSize: 12, letterSpacing: 0, lineHeight: '16px' },
      caption: {
        fontWeight: 700,
        fontSize: 10,
        letterSpacing: 1.25,
        lineHeight: '10px',
        color: '#ea3632',
        textTransform: 'uppercase',
      },
      button: { fontWeight: 600, fontSize: 10, letterSpacing: 0, lineHeight: '14px', textTransform: 'none' },
    },
    overrides: {
      // make the expansion panel still when expanding
      MuiPaper: {
        root: {
          backgroundImage: 'unset',
          // Date pickers:
          ' .MuiDateCalendar-root': {
            maxHeight: '335px',
          },
          ' .MuiPickersLayout-root': {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#303236',
            ' .MuiPickersCalendarHeader-switchViewButton': {
              color: '#fff',
              padding: '3px',
            },
            ' .MuiPickersArrowSwitcher-button': {
              color: '#fff',
              '&.Mui-disabled': {
                color: 'rgb(135, 146, 171) !important',
              },
            },
            ' .MuiDayCalendar-weekContainer button.MuiPickersDay-root': {
              color: '#fff',
              width: '36px',
              height: '36px',
              padding: 0,
              fontSize: '12px',
              fontFamily: `"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif`,
              fontWeight: 400,
              lineHeight: '16px',
              borderRadius: '50%',
              letterSpacing: '1.25px',
            },
            '.Mui-disabled': {
              color: 'rgb(135, 146, 171) !important',
            },
          },
          ' .MuiPickersDay-root': {
            '&.Mui-selected': {
              backgroundColor: '#ea3632',
              '&:focus': {
                backgroundColor: '#ea3632',
              },
            },
            '&:focus': {
              backgroundColor: '#ea3632',
            },
          },
        },
      },

      MuiAccordion: {
        root: {
          '&.Mui-expanded': {
            margin: '0',
          },
        },
      },
      MuiAlert: {
        filledInfo: {
          backgroundColor: colors.background,
          color: alpha(colors.gvWhite, 0.87),
          border: 'none',
        },
      },
      MuiCardHeader: {
        avatar: {
          marginRight: '10px',
        },
      },
      MuiCardContent: {
        root: {
          padding: '0',
          '&:last-child': {
            paddingBottom: '0',
          },
        },
      },
      MuiAccordionSummary: {
        root: {
          minHeight: 56,
          '&.Mui-expanded': {
            minHeight: 56,
          },
        },
        content: {
          '&.Mui-expanded': {
            margin: '0',
          },
        },
        expanded: {},
      },
      MuiInputBase: {
        root: {
          '&.Mui-disabled': {
            color: '#8a8c9f',
          },
        },
      },
      MuiInputAdornment: {
        root: {
          color: 'currentcolor',
        },
      },
      MuiAutocomplete: {
        inputFocused: {
          '&.MuiInputBase-inputAdornedStart': {
            height: '14px !important',
            padding: '20px 11px 6px 11px !important',
          },
        },
        inputRoot: {
          padding: '20px 50px 6px 11px',
        },
      },
      MuiSelect: {
        icon: {
          color: primaryTextColor,
        },
      },
      MuiMenu: {
        paper: {
          backgroundColor: menuLightGrey,
          // positions MyTeam advanced security settings first two dropdowns.
          '&:has(ul[aria-labelledby^=sign-request-algorithm])': {
            transform: 'translateY(-40px) !important',
          },
          // positions 3rd dropdown
          '&:has(ul[aria-labelledby=protocol-binding-input-label])': {
            transform: 'translateY(-40px) !important',
          },
          // positions Braille Language List dropdown
          '&:has(ul[aria-labelledby=braille-language-input-label])': {
            transform: 'translateY(-2px) !important',
            maxHeight: '245px',
            scrollbarGutter: 'stable',
            '&::-webkit-scrollbar': {
              width: '10px',
              height: '10px',
            },
            '&::-webkit-scrollbar-track': {
              background: '#393B3F',
              boxShadow: '0 0 1px 1px #474747',
            },
            '&::-webkit-scrollbar-thumb': {
              borderRadius: '10px',
              background: 'rgba(100, 102, 105, 0.6)',
              boxShadow: '0 0 1px 1px #393B3F',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: '#4e4e4e',
            },
          },
        },
      },
      MuiMenuItem: {
        root: {
          height: '24px',
          backgroundColor: menuLightGrey,
          '&:hover': {
            background: '#1a1d21',
          },
          '&.Mui-selected': {
            backgroundColor: 'rgba(255,255,255,0.24)',
            '&:hover': {
              background: 'rgba(255,255,255,0.1)',
            },
          },
          '+.MuiDivider-root': {
            margin: 0,
          },
        },
      },
      MuiSwitch: {
        track: {
          backgroundColor: '#949494',
        },
      },
      MuiDialog: {
        paper: {
          backgroundColor: menuLightGrey,
        },
      },
      MuiDialogContent: {
        root: {
          padding: '0',
        },
      },
      MuiDialogTitle: {
        root: {
          padding: '16px',
        },
      },
      MuiDialogContentText: {
        root: {
          color: primaryTextColor,
        },
      },
      MuiIconButton: {
        root: {
          padding: 12,
          '&.Mui-disabled': {
            color: 'rgba(255,255,255,0.38)',
          },
          '&.MuiIconButton-sizeLarge': {
            padding: '3px',
          },
        },
      },
      MuiTab: {
        root: {
          minWidth: 160,
          padding: '6px 12px',
          '&.Mui-disabled': {
            opacity: 0.5,
            color: primaryTextColor,
          },
        },
      },
      MuiButtonBase: {
        root: {
          '&.MuiTab-root': {
            color: 'inherit',
            opacity: 0.7,
          },
          '&.Mui-selected': {
            opacity: 1,
          },
        },
      },
      MuiToggleButton: {
        root: {
          '&.Mui-selected.MuiButtonBase-root.MuiToggleButton-sizeMedium': {
            color: '#fff',
          },
        },
      },
      MuiButton: {
        root: {
          padding: '8px 16px',
          textTransform: 'capitalize',
          fontWeight: 'normal',
          fontSize: 12,
          letterSpacing: 0,
          lineHeight: '16px',
        },
        outlinedPrimary: {
          backgroundColor: darkGreyBackground,
          border: '1px solid #101113',
          '&.Mui-disabled': {
            color: primaryTextColor,
            backgroundColor: 'rgb(31, 33, 38, 0.50)',
            opacity: 0.5,
          },
          '&:hover': {
            border: '1px solid #101113',
            backgroundColor: '#2F3239',
          },
        },
        outlinedSizeSmall: {
          padding: '3px 9px',
        },
        textSizeSmall: {
          padding: '4px 5px',
          fontSize: '0.7rem',
        },
        containedSizeLarge: {
          padding: '8px 22px',
          fontSize: '0.8rem',
        },
        text: {
          padding: 0,
          fontWeight: 500,
          textAlign: 'center',
          letterSpacing: '0.025em',
          opacity: '0.7',
          '&:hover': {
            backgroundColor: 'transparent',
            opacity: opacities.superhigh,
          },
        },
        containedSecondary: {
          '&:hover': {
            backgroundColor: '#eb4542',
          },
          '&.Mui-disabled': {
            backgroundColor: '#77787d',
            color: `rgba(255,255,255, ${opacities.disabled})`,
          },
        },
      },
      MuiFab: {
        root: {
          textTransform: 'capitalize',
          fontWeight: 600,
          fontSize: 12,
          letterSpacing: 0,
          lineHeight: '16px',
          color: '#101113',
          '&.Mui-disabled': {
            backgroundColor: 'rgb(255, 255, 255, 0.50)',
            opacity: 0.5,
          },
        },
        primary: {
          '&:hover': {
            backgroundColor: 'rgb(255, 255, 255, 0.85)',
          },
        },
        extended: {
          padding: '0 40px',
        },
      },
      MuiAppBar: {
        colorPrimary: {
          backgroundColor: mainBackgroundColor,
          color: primaryTextColor,
        },
        colorSecondary: {
          backgroundColor: paperBackgroundColor,
        },
      },
      MuiTooltip: {
        tooltip: {
          backgroundColor: '#FFFFFF',
          color: '#0E0F12',
          fontFamily: 'Open Sans',
          fontSize: '10px',
          fontWeight: 600,
          letterSpacing: 0,
          lineHeight: '16px',
        },
        arrow: {
          color: '#FFFFFF',
        },
        popper: {
          pointerEvents: 'none',
        },
      },
      MuiDivider: {
        root: {
          width: '100%',
        },
        light: {
          backgroundColor: 'rgba(255,255,255,0.12)',
        },
      },
      MuiFormHelperText: {
        root: {
          fontFamily: '"Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
          fontSize: '12px',
          lineHeight: '16px',
          textTransform: 'none',
          fontWeight: 600,
          '&.Mui-error': {
            color: '#BA0C2F',
            letterSpacing: '0.01em',
            marginLeft: '10px',
          },
        },
      },
      // Table Section
      MuiTable: {
        root: {
          minWidth: 750,
          borderBottom: 'none',
          borderTop: 'none',
        },
      },
      MuiTableRow: {
        root: {
          transition: `background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms`,
          '&:hover': {
            backgroundColor: 'rgba(255,255,255,0.1)',
          },
        },
      },
      MuiTableCell: {
        root: {
          borderRadius: '2px',
          borderBottom: 'none',
          '& > .MuiCheckbox-root': {
            '& > .MuiIconButton-label': {
              '& > .MuiSvgIcon-root': {
                color: '#ffffff',
              },
            },
            '&.Mui-disabled': {
              '& > .MuiIconButton-label': {
                '& > .MuiSvgIcon-root': {
                  color: '#8a8c9f',
                },
              },
            },
          },
        },
        stickyHeader: {
          backgroundColor: menuLightGrey,
          borderBottom: '1px solid rgba(255,255,255,0.08)',
          height: 52,
          fontWeight: 600,
        },
        sizeSmall: {
          padding: '6px 24px 6px 16px',
        },
      },
      MuiTablePagination: {
        root: {
          borderTop: '1px solid rgba(255,255,255,0.08)',
        },
        actions: {
          '& .Mui-disabled': {
            color: 'rgba(255,255,255,0.38)',
          },
          '& .MuiButtonBase-root:hover': {
            backgroundColor: 'rgba(255,255,255,0.08)',
          },
        },
        selectRoot: {
          border: '1px solid rgba(255,255,255,0.08)',
          borderRadius: 4,
          '&:hover': {
            border: '1px solid #ffffff',
          },
          '&.Mui-focused': {
            border: '1px solid #ffffff',
          },
        },
      },
      // MuiPickersBasePicker: {
      //   root: {
      //     backgroundColor: '#303236',
      //   },
      // },
      // MuiPickersCalendarHeader: {
      //   root: {
      //     '& .MuiPickersCalendarHeader-yearSelectionSwitcher ': {
      //       color: '#FFFFFF',
      //       paddingTop: 0,
      //     },
      //   },
      // },
      // MuiPickersDesktopDateRangeCalendar: {
      //   calendar: {
      //     minHeight: 270,
      //   },
      //   root: {
      //     backgroundColor: '#303236',
      //   },
      // },
      // MuiPickersDay: {
      //   root: {
      //     fontSize: '12px',
      //     lineHeight: '16px',
      //     fontWeight: 400,
      //     backgroundColor: '#303236',
      //     '&:focus': {
      //       '&.Mui-selected': {
      //         backgroundColor: '#ea3632',
      //         color: '#ffffff',
      //       },
      //     },
      //     '&$selected': {
      //       backgroundColor: '#ea3632',
      //       color: '#ffffff',
      //     },
      //     '&:hover': {
      //       // unable the access the .MuiPickersDateRangeDay-dayOutsideRangeInterval:hover selector to remove the border, so using !important for now
      //       border: '0 !important',
      //       backgroundColor: 'rgba(255,255,255,0.08)',
      //       color: '#ffffff',
      //     },
      //     '&.Mui-disabled': {
      //       color: colors.gvDisabled,
      //     },
      //   },
      //   today: {
      //     '&:not(.Mui-selected)': {
      //       border: `2px solid ${colors.gvLightBlue}`,
      //     },
      //   },
      // },
      // MuiPickersArrowSwitcher: {
      //   iconButton: {
      //     color: '#ffffff',
      //     backgroundColor: 'inherit',
      //     borderRadius: '2px',
      //     height: '32px',
      //     width: '32px',
      //     '&:hover': {
      //       backgroundColor: 'rgba(255,255,255,0.08)',
      //     },
      //     '&:active': {
      //       backgroundColor: 'rgba(255,255,255,0.32)',
      //     },
      //     '&.Mui-disabled': {
      //       color: colors.gvDisabled,
      //     },
      //   },
      //   previousMonthButtonMargin: {
      //     marginRight: 8,
      //   },
      // },
      // MuiPickersCalendar: {
      //   root: {
      //     minHeight: 248,
      //   },
      //   weekDayLabel: {
      //     fontSize: '12px',
      //     lineHeight: '16px',
      //     fontWeight: 400,
      //   },
      //   week: {
      //     '& .MuiPickersDateRangeDay-rangeIntervalDayHighlight': {
      //       backgroundColor: 'rgba(234,54,50,0.24)',
      //       color: '#ffffff',
      //     },
      //     '& .MuiPickersDateRangeDay-dayInsideRangeInterval': {
      //       color: '#ffffff',
      //     },
      //   },
      // },
      MuiFormControl: {
        root: {
          '&:focus': {
            outline: 'none',
          },
        },
      },
      MuiFormControlLabel: {
        label: {
          '&.Mui-disabled': {
            color: '#ffffff',
            opacity: 0.38,
          },
        },
      },
      MuiRadio: {
        root: {
          color: '#ffffff',
          '&:hover': {
            backgroundColor: 'rgb(255,255,255,0.08)',
          },
          '&.Mui-disabled': {
            color: 'rgba(255,255,255,0.38)',
          },
        },
        colorSecondary: {
          '&.Mui-checked:hover': {
            backgroundColor: 'rgba(255,255,255,0.08) !important',
          },
        },
      },
      MuiChip: {
        deleteIcon: {
          width: '18px',
        },
      },
      MuiCheckbox: {
        root: {
          '& .MuiSvgIcon-root': {
            zIndex: 1,
          },
          // This places a white square behind the checkboxes so the check is white
          '&.Mui-checked': {
            '& .PrivateSwitchBase-input': {
              width: 'auto',
              height: 'auto',
              top: 'auto',
              left: 'auto',
              opacity: '1',
              visibility: 'hidden',
              '&::before': {
                content: '""',
                position: 'absolute',
                background: 'white',
                height: '100%',
                width: '100%',
                visibility: 'visible',
              },
            },
          },
          '&:hover': {
            backgroundColor: 'rgb(255,255,255,0.08)',
          },
        },
        colorSecondary: {
          '&.Mui-checked:hover': {
            backgroundColor: 'rgb(255,255,255,0.08) !important',
          },
        },
      },
      MuiSvgIcon: {
        colorError: {
          color: '#EA3632',
        },
      },
    },
  }),
);

export default theme;
