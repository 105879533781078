import { configureStore, getDefaultMiddleware, combineReducers, StoreEnhancer } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import axios from 'axios';
import { handleRequests, createRequestsStore } from '@redux-requests/core';
import { createDriver } from '@redux-requests/axios';
import docManagerReducer from 'pdftron/docManager/reducer';
import rootSaga from './sagas';
import { app, inspection, files, report, trackerMetadata } from '.';
import { onRequest, onError, onSuccess } from './interceptors';
import * as Sentry from '@sentry/react';

const sagaMiddleware = createSagaMiddleware();

export const axiosInstance = axios.create({
  headers: {
    Accept: 'application/json',
  },
});

const { requestsReducer, requestsMiddleware } = handleRequests({
  driver: createDriver(axiosInstance),
  onRequest,
  onError,
  onSuccess,
});

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

const store = configureStore({
  reducer: combineReducers({
    app: app.reducer,
    inspection: inspection.reducer,
    files: files.reducer,
    reports: report.reducer,
    requests: requestsReducer,
    docManager: docManagerReducer,
    trackerMetadata: trackerMetadata.reducer,
  }),
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
    ...requestsMiddleware,
    sagaMiddleware,
  ],
  enhancers: [sentryReduxEnhancer],
});
sagaMiddleware.run(rootSaga);

export const requestsStore = createRequestsStore(store);

export default store;
