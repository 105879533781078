import React, { useState, useEffect, useMemo } from 'react';
import NoCommentsIcon from '@mui/icons-material/ModeCommentOutlined';
import CommentsIcon from '@mui/icons-material/ModeComment';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearIcon from '@mui/icons-material/Clear';
import RestoreIcon from '@mui/icons-material/Replay';
import { Theme, Grid, Typography, CircularProgress } from '@mui/material';
import { getStyleVariables } from 'styles/vars';
import { Difference, DifferenceTypeNames } from 'types';
import {
  getShowDiscarded,
  getSelectedSubDifferenceID,
  getFocusedDifferenceId,
  inspection,
  getSelectedCustomDictionaryIDs,
} from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { Lens } from '@mui/icons-material';
import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import GVIconButton from 'components/lib/GVIconButton/GVIconButton';
import { useMutation } from '@redux-requests/react';
import { restoreAllDifferences, updateDifference } from 'store/request/differences/actions';
import { patchGroup } from 'store/request/difference-group/actions';
import DifferenceComments from './DifferenceComments';
import DifferenceDescription from './DifferenceDescription/DifferenceDescription';
import GroupChip from './GroupChip';
import SubDifferenceView from './SubDifferences/SubDifferenceView';
import GraphicDifference from './differenceTypes/GraphicDifference';
import AnnotationDifference from './differenceTypes/AnnotationDifference';
import InsertionDifference from './differenceTypes/InsertionDifference';
import SpellingDifference from './differenceTypes/SpellingDifference';
import DeletionDifference from './differenceTypes/DeletionDifference';
import BarcodeDifference from './differenceTypes/BarcodeDifference';
import { makeStyles } from 'tss-react/mui';
import BrailleDifference from './differenceTypes/BrailleDifference';
import { useSuggestedWords } from 'store/queries/dictionaries/suggestedWords';

interface DifferenceElementViewProps {
  elementRef: React.RefObject<HTMLDivElement>;
  numberOfDifference: number;
  selectDifference: () => void;
  isSelected: boolean;
  isCommentOpen: boolean;
  handleToggleComment: (e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>, open: boolean) => void;
  handleToggleDiscard: (e: React.MouseEvent<Element, MouseEvent>) => void;
  updateSubdiffOpen: (open: boolean) => void;
  isSubdiffOpen: boolean;
  difference: Difference;
  groupLength: number;
}

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    root: {
      borderTop: `1px solid rgba(0,0,0,0)`,
      borderLeft: `3px solid rgba(0,0,0,0)`,
      padding: theme.spacing(1, 2),
      '&:hover': {
        backgroundColor: theme.palette.action.disabled,
      },
      '&:hover $number': {
        color: theme.palette.getContrastText(theme.palette.primary.main),
        backgroundColor: theme.palette.primary.main,
      },
      backgroundColor: styleVariables.colors.menuLightGrey,
      margin: theme.spacing(0, 2),
      width: 380 - Number.parseInt(theme.spacing(2)) * 3,
      borderRadius: '4px',
    },
    selected: {
      borderLeft: `3px solid ${theme.palette.secondary.main}`,
      backgroundColor: theme.palette.action.disabled,
    },
    annotationSelected: {
      borderLeft: `3px solid ${styleVariables.colors.lightOrange}`,
      backgroundColor: theme.palette.action.disabled,
    },
    title: {
      flex: 1,
      marginBottom: theme.spacing(2),
    },
    number: {
      padding: theme.spacing(0.25, 0.5),
      borderRadius: '4px',
      backgroundColor: theme.palette.action.disabled,
    },
    numberSelected: {
      color: theme.palette.getContrastText(theme.palette.primary.main),
      backgroundColor: theme.palette.primary.main,
    },
    icons: {
      maxWidth: theme.spacing(2),
      maxHeight: theme.spacing(2),
      margin: theme.spacing(0.25),
    },
    rightMargin: {
      marginRight: theme.spacing(1),
    },
    differenceType: {
      fontWeight: 600,
    },
    disabledButton: {
      '&.Mui-disabled': {
        pointerEvents: 'auto',
      },
    },
    indicator: {
      fontSize: theme.spacing(1),
    },
    indicatorGrid: {
      left: theme.spacing(-1.5),
      position: 'relative',
      width: 0,
    },
    indicatorUnread: {
      color: styleVariables.global.viewedIndicator,
    },
    indicatorRead: {
      visibility: 'hidden',
    },
    loading: {
      color: 'rgba(255,255,255,0.38)',
    },
    spinnerContainer: {
      padding: theme.spacing(0.625),
    },
    loadingOpacity: {
      opacity: 0.38,
    },
    subDifferenceClass: {
      width: '26px',
      textAlign: 'center',
      padding: '3px',
      position: 'relative',
      cursor: 'pointer',
    },
    subDifferenceCount: {
      position: 'absolute',
      top: '0px',
      right: '0px',
      width: '15px',
      height: '15px',
      background: 'red',
      borderRadius: '15px',
      fontSize: '9px',
      color: '#fff',
      lineHeight: '15px',
      textAlign: 'center',
    },
    textContainer: {
      // TEMP: used for annotationDifference
      paddingBottom: theme.spacing(1),
    },
    // TEMP: used for annotationDifference and graphic
    text: {
      color: styleVariables.colors.hint,
    },
    suggestWordIcon: {
      marginRight: theme.spacing(1),
    },
    success: {
      color: theme.palette.success.main,
      cursor: 'default',
    },
  };
});

export interface DifferenceProps {
  difference: Difference;
  isSelected: boolean;
  classes: Omit<ReturnType<typeof useStyles>, 'theme' | 'css' | 'cx'>;
}
const descriptionComponents: Partial<Record<DifferenceTypeNames, React.FC<DifferenceProps>>> = {
  [DifferenceTypeNames.Spelling]: SpellingDifference,
  [DifferenceTypeNames.Insertion]: InsertionDifference,
  [DifferenceTypeNames.Deletion]: DeletionDifference,
  [DifferenceTypeNames.Annotation]: AnnotationDifference,
  [DifferenceTypeNames.Graphics]: GraphicDifference,
  [DifferenceTypeNames.Barcode]: BarcodeDifference,
  [DifferenceTypeNames.Braille]: BrailleDifference,
};

/* difference description types currently being conditionally changed
  1. spelling // DONE
  2. insertion // DONE
  3. deletion // DONE
  3. graphic // TODO
  3. annotation // DONE
   */

const DifferenceElementView = (props: DifferenceElementViewProps) => {
  const {
    elementRef,
    numberOfDifference,
    selectDifference,
    isSelected,
    difference,
    handleToggleComment,
    handleToggleDiscard,
    isCommentOpen,
    updateSubdiffOpen,
    isSubdiffOpen,
  } = props;
  const dispatch = useDispatch();
  const { classes, cx } = useStyles();
  const showDiscarded = useSelector(getShowDiscarded);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const focusedDiffID = useSelector(getFocusedDifferenceId);
  const selectedSubDiffID = useSelector(getSelectedSubDifferenceID);
  const customDictionaryId = useSelector(getSelectedCustomDictionaryIDs)[0];
  const { suggestWordMutation, suggestedWordsList } = useSuggestedWords();

  // Has been suggested if the word is already in the list with a matching customeDictionaryId OR word is in the list and no custom dictionary is selected for this inspection
  const hasBeenSuggested = useMemo(() => {
    return (
      difference.type === DifferenceTypeNames.Spelling &&
      suggestedWordsList.find((suggestedWord) => {
        return (
          (suggestedWord.suggestedWord === difference.source.text &&
            suggestedWord.suggestedDictionaryId === customDictionaryId) ||
          (suggestedWord.suggestedWord === difference.source.text && !customDictionaryId)
        );
      })
    );
  }, [difference.type, difference.source.text, suggestedWordsList]);

  const { loading: loadingRestoreAllDifferences, error: loadingRestoreAllError } = useMutation({
    type: restoreAllDifferences,
  });
  const { loading: differenceLoading, error: updateDifferenceError } = useMutation({
    type: updateDifference,
    requestKey: difference.id,
  });
  const { loading: groupLoading, error: patchGroupError } = useMutation({
    type: patchGroup,
    requestKey: difference.groupId,
  });
  const isSupportSubDiff = difference.type === DifferenceTypeNames.Graphics && difference.subDiff.length > 0;

  useEffect(() => {
    if (loadingRestoreAllDifferences) {
      setIsLoading(true);
      // it isn't necessary to disable loading once finished loading because component should be unmounted when request is finished, so only disable loading if theres an error
    } else if (loadingRestoreAllError || updateDifferenceError || patchGroupError) {
      setIsLoading(false);
    }
  }, [
    loadingRestoreAllDifferences,
    loadingRestoreAllError,
    patchGroupError,
    updateDifferenceError,
    differenceLoading,
    groupLoading,
  ]);

  async function handleToggleDiscardClick(e: React.MouseEvent) {
    e.stopPropagation();
    setIsLoading(true);
    handleToggleDiscard(e);
    handleToggleComment(e, false);
  }

  function toggleShowSubDiff(e: React.MouseEvent) {
    e.stopPropagation();
    updateSubdiffOpen(!isSubdiffOpen);
  }

  function handleSelectDifference(e: React.MouseEvent) {
    e.stopPropagation();
    updateSubdiffOpen(true);
    selectDifference();
    if (difference.id !== focusedDiffID) {
      dispatch(inspection.actions.setSelectedSubDifferenceId(''));
    }
  }

  function handleSuggestWord(e: React.MouseEvent) {
    if (hasBeenSuggested) return;

    const suggestWord = {
      suggestedWord: difference.source.text,
      suggestedDictionaryId: customDictionaryId || null,
    };
    suggestWordMutation.mutate(suggestWord);

    if (!showDiscarded) {
      handleToggleDiscardClick(e);
    }
  }

  let iconButton;
  if (showDiscarded) {
    iconButton = (
      // display restore button on the discarded differences tab
      <GVTooltip title="Restore" placement="bottom">
        <GVIconButton
          testId={`difference_card_restore_button_${1 + props.numberOfDifference}`}
          className={`${!hasBeenSuggested && classes.success && classes.disabledButton}`}
          size="small"
          disabled={!!hasBeenSuggested}
          onClick={handleToggleDiscardClick}
          id={`${difference.groupId || difference.id}-restore`}
          icon={<RestoreIcon className={`${!hasBeenSuggested && classes.success}`} />}
        />
      </GVTooltip>
    );
  } else {
    // display the delete button in the differences tab
    iconButton = (
      <GVTooltip title="Discard" placement="bottom">
        <GVIconButton
          testId={`difference_card_discard_button_${1 + props.numberOfDifference}`}
          className={classes.disabledButton}
          color="secondary"
          size="small"
          onClick={handleToggleDiscardClick}
          id={`${difference.groupId || difference.id}-discard`}
          icon={<ClearIcon className={classes.icons} />}
        />
      </GVTooltip>
    );
  }

  const DescriptionComponent = descriptionComponents[difference.type as DifferenceTypeNames];

  let diffDesc;
  // TEMP: keep this condition here until we move logic from here to difference type components
  if (difference.type === DifferenceTypeNames.Graphics) {
    if (!isCommentOpen || difference.subDiff.length === 0) {
      diffDesc = <GraphicDifference difference={difference} classes={{ classes }} isSelected={isSelected} />;
    }
  } else if (DescriptionComponent) {
    diffDesc = <DescriptionComponent classes={{ classes }} difference={difference} isSelected={isSelected} />;
  } else {
    diffDesc = <DifferenceDescription difference={difference} />;
  }

  return (
    <Grid
      ref={elementRef}
      container
      direction="column"
      className={`${classes.root} ${
        isSelected &&
        (difference.type === DifferenceTypeNames.Annotation && difference.subDiff.length === 0
          ? classes.annotationSelected
          : classes.selected)
      } ${isLoading && classes.loading}`}
      onClick={handleSelectDifference}
      data-testid={`difference_card_${1 + props.numberOfDifference}`}
      id={difference.groupId ? `${difference.groupId}-group-element` : `${difference.id}-difference-element`}
    >
      {/* header */}
      <Grid item container>
        <Grid
          item
          className={classes.indicatorGrid}
          id={`${difference.groupId || difference.id}-${difference.viewed ? 'read' : 'unread'}`}
          data-testid={`difference_card_read_indicator_${1 + props.numberOfDifference}`}
        >
          <Lens
            className={`${classes.indicator} ${difference.viewed ? classes.indicatorRead : classes.indicatorUnread}`}
          />
        </Grid>
        <Grid item container className={classes.title}>
          <Grid item>
            <Typography
              data-testid={`difference_card_number_${1 + props.numberOfDifference}`}
              variant="button"
              component="span"
              className={`${classes.number} ${classes.differenceType} ${classes.rightMargin} ${
                isSelected ? classes.numberSelected : ''
              }`}
            >
              {`${numberOfDifference + 1}.`}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              data-testid={`difference_card_display_name_${1 + props.numberOfDifference}`}
              variant="button"
              component="span"
              className={classes.differenceType}
            >
              {difference.displayName}
            </Typography>
          </Grid>

          {props.groupLength !== 0 && (
            <Grid item data-testid={`difference_card_group_chip_parent_${1 + props.numberOfDifference}`}>
              <GroupChip number={props.groupLength} groupId={difference.groupId} />
            </Grid>
          )}
        </Grid>
        {!isSupportSubDiff || (isSupportSubDiff && difference.subDiff.length === 0) ? (
          <Grid item className={classes.rightMargin}>
            {difference.type === DifferenceTypeNames.Spelling && (
              <Grid display={'inline'} className={!!hasBeenSuggested ? 'suggested' : 'notSuggested'}>
                <GVIconButton
                  testId={`difference_card_suggest_word_button_${1 + props.numberOfDifference}`}
                  color="primary"
                  className={cx(
                    classes.suggestWordIcon,
                    isLoading && classes.loading && classes.disabledButton,
                    hasBeenSuggested && classes.success,
                  )}
                  size="small"
                  onClick={hasBeenSuggested ? undefined : handleSuggestWord}
                  disableRipple={!!hasBeenSuggested}
                  id={`${difference.groupId || difference.id}-toggle-comments`}
                  icon={
                    hasBeenSuggested ? (
                      <CheckCircleIcon className={cx(classes.icons)} />
                    ) : (
                      <AddCircleIcon className={classes.icons} />
                    )
                  }
                />
              </Grid>
            )}
            <GVTooltip title="Comment" placement="bottom">
              <GVIconButton
                testId={`difference_card_comment_button_${1 + props.numberOfDifference}`}
                color="primary"
                className={`${isLoading && classes.loading && classes.disabledButton}`}
                disabled={difference.type === DifferenceTypeNames.Annotation}
                size="small"
                onClick={(e) => handleToggleComment(e, !isCommentOpen)}
                id={`${difference.groupId || difference.id}-toggle-comments`}
                icon={
                  difference.comment ? (
                    <CommentsIcon className={classes.icons} />
                  ) : (
                    <NoCommentsIcon className={classes.icons} />
                  )
                }
              />
            </GVTooltip>
          </Grid>
        ) : (
          <Grid item className={`${classes.rightMargin} ${classes.subDifferenceClass}`} onClick={toggleShowSubDiff}>
            <CommentsIcon className={classes.icons} />
            <div className={classes.subDifferenceCount}>
              {difference.subDiff.length > 99 ? '99+' : difference.subDiff.length}
            </div>
          </Grid>
        )}
        <Grid item>
          {isLoading ? (
            <Grid className={classes.spinnerContainer}>
              <CircularProgress size={16} color="secondary" />
            </Grid>
          ) : (
            iconButton
          )}
        </Grid>
      </Grid>
      {/* Difference description */}
      <Grid
        data-testid={`difference_card_description_${1 + props.numberOfDifference}`}
        className={`${isLoading && classes.loadingOpacity}`}
      >
        {diffDesc}
      </Grid>

      {/* Comments */}
      {isCommentOpen && !isSupportSubDiff && (
        <DifferenceComments
          numberOfDifference={numberOfDifference}
          difference={difference}
          handleToggleComment={handleToggleComment}
        />
      )}

      {/* Sub differences */}
      {isSupportSubDiff &&
        isSubdiffOpen &&
        difference.subDiff.map((d, i) => {
          return (
            <SubDifferenceView
              key={`${d.parentId}_${d.id}`}
              elementRef={elementRef}
              subDifference={d}
              isLoading={isLoading}
              index={i}
              parentFocused={isSelected}
              numberOfDifference={numberOfDifference}
              isSelected={selectedSubDiffID === d.id}
            />
          );
        })}
    </Grid>
  );
};

export default DifferenceElementView;
