import { makeStyles } from 'tss-react/mui';
import { Grid, Theme } from '@mui/material';
import { DocumentsPanel, UploadLeftPanelContent } from 'components/pageElements';
import { RightPanel } from 'components';
import { GVSnackbar } from 'components/common';
import { getStyleVariables } from 'styles/vars';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    rightPanel: {
      flex: 1,
      height: '100%',
      maxHeight: '100%',
    },
    leftPanel: {
      width: styleVariables.leftPanel.width,
      height: '100%',
      maxHeight: '100%',
    },
    contents: {
      height: `calc(100% - ${theme.spacing(8)})`,
    },
  };
});

export const ListSessionsPage = () => {
  const { classes } = useStyles();

  return (
    <Grid item container className={classes.contents}>
      <Grid item className={classes.leftPanel}>
        <UploadLeftPanelContent />
      </Grid>
      <Grid item className={classes.rightPanel}>
        <RightPanel>
          <DocumentsPanel />
        </RightPanel>
      </Grid>
      <GVSnackbar />
    </Grid>
  );
};
