import { useState, useRef } from 'react';
import { Typography, ButtonBase, Menu, MenuItem, Theme, Grid, Divider } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { getStyleVariables } from 'styles/vars';
import { GVUserAvatar, GVUserRoleBadge, GVUserAvatarSizes } from 'components';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { isAdmin, isSuperAdmin, isTenantAdmin } from 'utils/auth';
import { useSelector } from 'react-redux';
import { getDisplayName } from 'store';
import { makeStyles } from 'tss-react/mui';
import { isInternal } from 'components/Guards/InternalGuard';
import { useUserStateStore } from 'zstore/userStateStore';
import { useSuggestedWords } from 'store/queries/dictionaries/suggestedWords';
import GVChip, { GVChipColor, GVChipSize } from 'components/GVChip/GVChip';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    menuIcon: {
      color: theme.palette.common.white,
      marginLeft: 'auto',
    },
    popover: {
      // important because material ui sets the values inside directly in the styles of the component so it always takes precedence
      top: '60px !important',
      overflow: 'visible !important',
      '&::before': {
        content: `''`,
        position: 'absolute',
        top: '-12px',
        right: '10px',
        borderBottom: `6px solid ${styleVariables.select.arrowBackground}`,
        borderRight: '6px solid transparent',
        borderLeft: '6px solid transparent',
        borderTop: '6px solid transparent',
        zIndex: '10',
      },
    },
    // @todo Some of the styles here would probably go in our theme once its defined what is custom for this component and what is for all
    menuList: {
      color: theme.palette.text.primary,
      '& > li': {
        minHeight: '32px',
      },
      // all except first
      '& > li:nth-of-type(n+2)': {
        '&:hover': {
          backgroundColor: styleVariables.userMenu.hoverItem.background,
        },
      },
      // first child
      '& > li:nth-of-type(1)': {
        marginBottom: theme.spacing(1),
      },
      '& > li:nth-of-type(1):hover': {
        backgroundColor: 'transparent',
      },
    },
    logoutIcon: {
      margin: theme.spacing(0, 0.5, 0, -0.5),
    },
    menuLink: {
      color: theme.palette.text.primary,
      textDecoration: 'none',
      width: '100%',
      padding: theme.spacing(1, 2),
    },
    menuLinkDecorated: {
      color: theme.palette.text.primary,
      textDecoration: 'none',
      width: '100%',
      padding: theme.spacing(1, 2, 1, 1),
    },
    menuAvatar: {
      margin: theme.spacing(0, 1, 0, 0),
    },
    chip: {
      position: 'absolute',
      left: '38px',
      top: '5px',
      '.MuiChip-label': { fontSize: '10px', fontWeight: 600 },
    },
    miniChip: {
      marginLeft: theme.spacing(0.5),
    },
  };
});

const UserInfo = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorEl = useRef<HTMLButtonElement>(null);
  const { classes } = useStyles();
  const { logout } = useAuth0();
  const { user } = useUserStateStore();
  const adminPermissions = isSuperAdmin(user) || isAdmin(user) || isTenantAdmin(user);
  const displayName = useSelector(getDisplayName);
  const { suggestedWordsList } = useSuggestedWords();
  const suggestedWordsCount = suggestedWordsList.length;
  const displayBreadcrumb = adminPermissions && suggestedWordsCount > 0;

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  if (!user) return null;

  return displayName ? (
    <Grid container>
      <ButtonBase
        disableRipple
        className={classes.menuIcon}
        onClick={handleToggleMenu}
        ref={anchorEl}
        id="user-menu"
        data-testid="user_menu_button"
      >
        <GVUserAvatar />
        {displayBreadcrumb && (
          <GVChip
            className={classes.chip}
            chipSize={GVChipSize.SMALL}
            chipColor={GVChipColor.ALERT}
            label={suggestedWordsCount > 99 ? '-' : suggestedWordsCount}
            circle
          />
        )}
        <Typography variant="body1">{displayName}</Typography>
        <KeyboardArrowDownIcon />
      </ButtonBase>
      <Menu
        anchorEl={anchorEl.current}
        keepMounted
        open={menuOpen}
        onClose={handleToggleMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PopoverClasses={{ paper: classes.popover }}
        classes={{
          list: classes.menuList,
        }}
      >
        <MenuItem>
          <GVUserAvatar size={GVUserAvatarSizes.SMALL} className={classes.menuAvatar} />
          <div>
            <Typography color="textPrimary" variant="subtitle1" display="block">{`${displayName}`}</Typography>
            <GVUserRoleBadge
              text
              textProps={{
                color: 'textSecondary',
                variant: 'body1',
                display: 'block',
              }}
            />
          </div>
        </MenuItem>
        {/* <Divider light /> */}
        {/* <MenuItem>Product Releases</MenuItem> */}
        <Divider light />
        <MenuItem disableGutters onClick={() => handleToggleMenu()}>
          {displayBreadcrumb && (
            <GVChip className={classes.miniChip} chipSize={GVChipSize.XSMALL} chipColor={GVChipColor.ALERT} circle />
          )}
          <Link
            className={`${displayBreadcrumb ? classes.menuLinkDecorated : classes.menuLink}`}
            to="/myaccount/settings"
            data-testid="menu_my_account"
          >
            My Account
          </Link>
        </MenuItem>
        {isInternal && [
          <Divider light key="1" />,
          <MenuItem disableGutters onClick={() => handleToggleMenu()} key="2">
            <Link className={classes.menuLink} to="/internal">
              Internal
            </Link>
          </MenuItem>,
        ]}

        {/* <MenuItem>License Agreement</MenuItem> */}
        <Divider light />
        <MenuItem
          onClick={() => {
            logout({
              clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
              logoutParams: {
                federated: true,
                returnTo: window.location.origin,
              },
            });
          }}
          id="menu-logout"
          data-testid="menu_logout"
        >
          <ExitToAppIcon className={classes.logoutIcon} />
          Logout
        </MenuItem>
      </Menu>
    </Grid>
  ) : null;
};

export default UserInfo;
