import React, { useState, useEffect } from 'react';
import { Settings } from '@mui/icons-material';
import { Grid, Menu, MenuItem, FormControlLabel } from '@mui/material';
import {
  getDocumentsLoaded,
  getInternalAnnotationVisibility,
  getOCRHasRun,
  getShowLiveText,
  inspection,
  app,
  getFlashMode,
} from 'store';
import { useDispatch, useSelector } from 'react-redux';
import GVTooltip from 'components/lib/GVToolTip/GVTooltip';
import GVSwitch from 'components/lib/GVSwitch/GVSwitch';
import GVToggleButton from '../GVToggleButton/GVToggleButton';
import { isResultUrl } from 'utils/location';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface CogProps {
  loaded: boolean | undefined;
}

const CogMenu = (props: CogProps) => {
  const [selected, setSelected] = useState<boolean>(false);
  const internalAnnotationVisibility = useSelector(getInternalAnnotationVisibility);
  const flashMode = useSelector(getFlashMode);
  const ocrHasRun = useSelector(getOCRHasRun);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const hasLoaded = useSelector(getDocumentsLoaded);
  const liveText = useSelector(getShowLiveText);
  const dispatch = useDispatch();
  const featureFlags = useFlags();

  const toggleSelected = (event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => {
    setSelected(!selected);
    setAnchorEl(event.currentTarget);
  };

  const handleLiveTextSwitch = () => {
    dispatch(app.actions.setShowLiveText(!liveText));
  };

  const handleInternalAnnotationSwitch = () => {
    dispatch(inspection.actions.setDefaultInternalAnnotationsVisibility(!internalAnnotationVisibility));
  };

  const handleFlashModeSwitch = () => {
    dispatch(inspection.actions.setFlashMode(!flashMode));
  };

  const handleClose = () => {
    setSelected(false);
  };

  useEffect(() => {
    if (!hasLoaded) {
      dispatch(app.actions.setShowLiveText(false));
    }
  }, [dispatch, hasLoaded]);

  useEffect(() => {
    if (hasLoaded && ocrHasRun) {
      dispatch(app.actions.setShowLiveText(true));
    }
  }, [dispatch, hasLoaded, ocrHasRun]);

  return (
    <Grid container alignItems="center">
      <GVToggleButton value="cogMenu" id="cogMenu" small selected={selected} onChange={toggleSelected}>
        <GVTooltip title="Settings" placement="top">
          <Settings fontSize="small" />
        </GVTooltip>
      </GVToggleButton>

      <Menu
        open={selected}
        keepMounted
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        <MenuItem data-testid="cog_show_live_text">
          <FormControlLabel
            control={
              <GVSwitch color="secondary" checked={liveText} onChange={handleLiveTextSwitch} disabled={!props.loaded} />
            }
            label="Show Live Text"
          />
        </MenuItem>
        <MenuItem data-testid="cog_show_annotations">
          <FormControlLabel
            control={
              <GVSwitch
                color="secondary"
                checked={internalAnnotationVisibility}
                onChange={handleInternalAnnotationSwitch}
                disabled={!props.loaded}
              />
            }
            label="Show Annotations"
          />
        </MenuItem>
        {featureFlags.graphics2 && featureFlags.graphicsResultsV2 && isResultUrl() && (
          <MenuItem data-testid="cog_flash_mode">
            <FormControlLabel
              control={
                <GVSwitch
                  color="secondary"
                  checked={flashMode}
                  onChange={handleFlashModeSwitch}
                  disabled={!props.loaded}
                />
              }
              label="Show Flashing"
            />
          </MenuItem>
        )}
      </Menu>
    </Grid>
  );
};

export default CogMenu;
