import React, { useEffect, useState } from 'react';
import { AppBar, Grid, Tab, Tabs, Theme } from '@mui/material';
import { PDFManagerFactory } from 'pdftron';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  app,
  getDifferencesLoadedSelector,
  getDocumentsLoaded,
  getFilesIds,
  getInspectionCompleted,
  getInspectionHasRun,
  getInspectionId,
  getInspectionIsOutdated,
  getInspectionHasOutdatedGraphicZones,
  getIsSingleFile,
  getLiveTextStatus,
  getSnackMessage,
  inspection,
  getInspectionHasOutdatedCropZones,
} from 'store';
import { getStyleVariables } from 'styles/vars';
import { LeftPanelTabIndexes, PDFTRON_DEFAULT_TOOL } from 'types';
import { isReportsUrl, isResultUrl } from 'utils/location';
import { fetchInspection } from 'store/request/inspections/actions';
import { GVTypography } from 'components/lib';
import ReportsTab from '../ResultPage/ReportsTab';
import ResultTab from '../ResultPage/ResultTab';
import { useTracker } from '../../Tracker/TrackerProvider';
import PrepTab from './PrepTab';
import TabLabel from './TabLabel';
import { makeStyles } from 'tss-react/mui';
import * as Sentry from '@sentry/react';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    root: {
      maxWidth: '100%',
      height: '100%',
      overflow: 'hidden',
    },
    tabs: {
      padding: theme.spacing(0, 2),
      maxHeight: styleVariables.leftPanel.tabsHeight,
    },
    tab: {
      minWidth: styleVariables.leftPanel.tabsWidth, // this comes from the fixed width of the left panel
    },
    tabContent: {
      width: '100%',
      maxWidth: '100%',
      height: '100%',
    },
  };
});

const segmentTabNames = {
  0: 'Prep-page',
  1: 'Results-page',
  2: 'Report-page',
};
interface TabPanelProps {
  children: React.ReactElement;
  value: number;
  index: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index } = props;
  const { classes } = useStyles();

  return (
    <div className={classes.tabContent} role="tabpanel" hidden={value !== index}>
      {children}
    </div>
  );
};

const InspectionLeftPanel = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const tracker = useTracker();

  const inspectionId = useSelector(getInspectionId);
  const inspectionHasRun = useSelector(getInspectionHasRun);
  const inspectionCompleted = useSelector(getInspectionCompleted);
  const inspectionIsOutdated = useSelector(getInspectionIsOutdated);
  const outdatedGraphicsTool = useSelector(getInspectionHasOutdatedGraphicZones);
  const outdatedCropTool = useSelector(getInspectionHasOutdatedCropZones);
  const documentsLoaded = useSelector(getDocumentsLoaded);
  const differenceLoaded = useSelector(getDifferencesLoadedSelector);
  const snackMessage = useSelector(getSnackMessage);
  const liveTextStatus = useSelector(getLiveTextStatus);
  const [alertText, setAlertText] = useState<(string | JSX.Element)[] | string>();
  const [tabIndex, setTabIndex] = useState(LeftPanelTabIndexes.prep);
  const location = useLocation();
  const isSingleFile = useSelector(getIsSingleFile);
  const eitherFileMissingLiveText = liveTextStatus.source || liveTextStatus.target;
  const bothFilesMissingLiveText = liveTextStatus.source && liveTextStatus.target && !isSingleFile;
  const filesId = useSelector(getFilesIds);
  const filesLoaded = filesId.sampleFileId || (!isSingleFile && filesId.masterFileId);

  // TODO: should this be inside an useEffect?
  // we keep the urls because a lot of other stuff is based on the url we are currently viewing (we get it from window.location)
  const urls = {
    [LeftPanelTabIndexes.prep]: `/inspection/${inspectionId}`,
    [LeftPanelTabIndexes.result]: `/inspection/${inspectionId}/result`,
    [LeftPanelTabIndexes.reports]: `/inspection/${inspectionId}/reports`,
  };

  const getTabUrl = (tabIndex: LeftPanelTabIndexes) => {
    let url = urls[tabIndex];
    if (location.search) {
      url = `${url}${location.search}`;
    }
    return url;
  };

  // Update the selected tab on mount
  useEffect(() => {
    const getValueFromUrl = () => {
      let valueFromUrl = LeftPanelTabIndexes.prep;
      if (isResultUrl(location)) {
        valueFromUrl = LeftPanelTabIndexes.result;
      } else if (isReportsUrl(location)) {
        valueFromUrl = LeftPanelTabIndexes.reports;
        dispatch(inspection.actions.setOpenPanel(false));
      }
      return valueFromUrl;
    };
    setTabIndex(getValueFromUrl());

    PDFManagerFactory.getPDFDocManager()?.resetPDFTronTool();
    dispatch(inspection.actions.setSelectedTool({ tool: PDFTRON_DEFAULT_TOOL }));
    dispatch(inspection.actions.unfocusDifference());
    dispatch(app.actions.setStartingInspection(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (id && id !== inspectionId) {
      Sentry.setTag('inspectionId', id)
      dispatch(fetchInspection(id, 'session'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    // redirect to results when differences are loaded
    if (!isReportsUrl() && inspectionCompleted && inspectionId && differenceLoaded) {
      navigate(getTabUrl(LeftPanelTabIndexes.result));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectionCompleted, inspectionId, differenceLoaded]);

  // 0 -> prep, 1 -> results, 2 -> report
  const handleChange = (_event: React.ChangeEvent<{}>, newTabIndex: 0 | 1 | 2) => {
    if (snackMessage && snackMessage.open && snackMessage.type === 'info') {
      dispatch(app.actions.clearSnackMessageType('info'));
    }
    dispatch(inspection.actions.expireAllToast());
    dispatch(inspection.actions.setInternalAnnotationsVisibilityToDefault());

    if (newTabIndex === 0 || newTabIndex === 1) {
      dispatch(
        inspection.actions.setDifferenceViewOptions({
          hideDisplayPanels: {
            source: false,
            target: false,
          },
        }),
      );
    }

    // segment event for corresponding tab page
    if (tabIndex !== newTabIndex) {
      tracker.track({ name: segmentTabNames[newTabIndex] });
    }

    setTabIndex(newTabIndex);
  };

  useEffect(() => {
    if (!filesLoaded) {
      setAlertText('');
    } else if (eitherFileMissingLiveText) {
      dispatch(inspection.actions.setInspectionSettings({ text: false }));
      let boldText;
      if (bothFilesMissingLiveText) {
        boldText = 'Both of your documents ';
      } else if (isSingleFile) {
        boldText = 'Your document ';
      } else {
        boldText = liveTextStatus.source ? 'Your Source document ' : 'Your New document ';
      }

      const newAlertText = [
        // eslint-disable-next-line react/jsx-indent
        <GVTypography key="bold-text-1" display="inline" variant="subtitle1" emphasis="superhigh">
          {boldText}
        </GVTypography>,
        `${bothFilesMissingLiveText ? 'do' : 'does'} not contain live text. `,
        // eslint-disable-next-line react/jsx-indent
        <GVTypography key="bold-text-2" display="inline" variant="subtitle1" emphasis="superhigh">
          Apply OCR
        </GVTypography>,
        ` to enable a ${isSingleFile ? 'spelling' : 'text'} inspection.`,
      ];

      setAlertText(newAlertText);
    } else {
      setAlertText('');
    }
  }, [liveTextStatus.source, liveTextStatus.target, isSingleFile, bothFilesMissingLiveText]);
  return (
    <Grid container className={classes.root}>
      <Grid item>
        <AppBar position="static">
          <Tabs
            indicatorColor="secondary"
            value={tabIndex}
            onChange={handleChange}
            scrollButtons={false}
            className={classes.tabs}
          >
            <Tab
              data-testid="left_panel_prep_tab"
              className={classes.tab}
              component={Link}
              to={getTabUrl(LeftPanelTabIndexes.prep)}
              label={
                <TabLabel
                  label="Prep"
                  warning={liveTextStatus.source || liveTextStatus.target}
                  tooltipTitle={alertText}
                />
              }
              disabled={!documentsLoaded}
            />
            <Tab
              data-testid="left_panel_results_tab"
              className={classes.tab}
              component={Link}
              to={getTabUrl(LeftPanelTabIndexes.result)}
              label={
                <TabLabel
                  label="Results"
                  warning={inspectionIsOutdated || outdatedGraphicsTool || outdatedCropTool}
                  tooltipTitle={
                    inspectionIsOutdated
                      ? 'Results do not reflect changes. Please press inspect in PREP stage to update.'
                      : 'Outdated zone tool. Please press inspect in PREP stage to update.'
                  }
                />
              }
              disabled={!inspectionHasRun || !documentsLoaded}
            />
            <Tab
              data-testid="left_panel_reports_tab"
              className={classes.tab}
              component={Link}
              to={getTabUrl(LeftPanelTabIndexes.reports)}
              label="Report"
              disabled={!inspectionHasRun || !documentsLoaded}
            />
          </Tabs>
        </AppBar>
      </Grid>
      <Grid item className={classes.tabContent}>
        <TabPanel value={tabIndex} index={LeftPanelTabIndexes.prep}>
          <PrepTab alertText={alertText} />
        </TabPanel>
        <TabPanel value={tabIndex} index={LeftPanelTabIndexes.result}>
          <ResultTab />
        </TabPanel>
        <TabPanel value={tabIndex} index={LeftPanelTabIndexes.reports}>
          <ReportsTab tabLoaded={tabIndex === LeftPanelTabIndexes.reports} />
        </TabPanel>
      </Grid>
    </Grid>
  );
};

export default InspectionLeftPanel;
