/* eslint-disable no-shadow */
/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */
import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { WebViewerWrapper, PDFManagerFactory } from 'pdftron';
import AnnotationMixin from 'pdftron/docManager/Annotation';
import store from 'store/store';
import {
  AnnotationCustomData,
  CropZoneCustomData,
  DocumentTypes,
  InputAnnotation,
  OPPOSITE_DOCUMENT,
  PDFTronTools,
  Quad,
} from 'types';
import { app, getSelectedTool, inspection } from 'store';
import ToolsMixin from '../Tools';
import drawMarkupLabel from './utils/drawMarkupLabel';
import GVAnnotationMixin from './utils/GVAnnotationMixin';
import { PREP_TOOL_LABEL, PREP_TOOL_ANNOTATION } from './utils/annotationConstants';
import { AnnotationAction } from 'components/PDFViewer/Utils';
import {
  CursorStyle,
  cursorIsInsideCropZone,
  getCursorPageNumber,
  getIframeDocument,
  getPageCropZone,
  resetCursors,
  setCursorStyle,
} from './utils/CropToolUtils';
import PDFAnnotationManager from 'components/PDFViewer/PDFAnnotationManager';
import quadsToDimensions from '../utils/quadsToDimensions';
import { getPageRanges } from './utils/getPageRanges';

export interface SerializedCropZoneAnnotation {
  pageNumber: number;
  x1: number;
  x2: number;
  y1: number;
  y2: number;
}

class CropZone {
  source: WebViewerWrapper | null = null;

  target: WebViewerWrapper | null = null;

  constructor(source: WebViewerWrapper | null, target: WebViewerWrapper | null) {
    this.source = source;
    this.target = target;
  }

  get toolsMixin() {
    return new ToolsMixin(this.source, this.target);
  }

  get annotationMixin() {
    return new AnnotationMixin(this.source, this.target);
  }

  createCropZoneAnnotation(instance: WebViewerInstance) {
    class CropZoneAnnotation extends GVAnnotationMixin(instance.Core.Annotations.RectangleAnnotation) {
      constructor() {
        super();
        const state = store.getState();
        this.setCustomData(CropZoneCustomData.cropZoneAnnotation, 'true');

        this.ToolName = PDFTronTools.CROP;
        this.IsHoverable = true;
        this.disableRotationControl();
      }

      draw(ctx: CanvasRenderingContext2D, pageMatrix: any) {
        this.setStyles(ctx, pageMatrix);
        const { DEFAULT_FILL_STYLE, SELECTED_FILL_STYLE } = PREP_TOOL_ANNOTATION;
        const annotationRectangle = new Path2D();
        annotationRectangle.rect(this.X, this.Y, this.Width, this.Height);
        ctx.strokeStyle = SELECTED_FILL_STYLE;
        ctx.stroke(annotationRectangle);
        ctx.fillStyle = 'rgba(255, 255, 255, 0.01)';
        ctx.fill(annotationRectangle);

        const selectedAnnotations = instance.Core.annotationManager.getSelectedAnnotations();
        const isSelected = selectedAnnotations.some((targetAnnot) => targetAnnot.Id.includes(this.Id));
        if (isSelected || (this as any)?.IsHovering) {
          const labelText = `Crop Zone`;
          const { HEIGHT, WIDTH_PADDING, GAP } = PREP_TOOL_LABEL;
          const fillStyle = isSelected ? SELECTED_FILL_STYLE : DEFAULT_FILL_STYLE;
          drawMarkupLabel(ctx, labelText, this.X, this.Y, HEIGHT, GAP, WIDTH_PADDING, fillStyle);
        }
      }
    }
    return CropZoneAnnotation;
  }

  annotationsExistOutsideNewCrop(
    existingAnnotations: InputAnnotation[] | { quads: any }[],
    newCrop: Core.Annotations.Annotation,
  ) {
    // Calculate the outermost boundaries of existing annotations
    let minX = Number.MAX_VALUE;
    let minY = Number.MAX_VALUE;
    let maxX = Number.MIN_VALUE;
    let maxY = Number.MIN_VALUE;

    existingAnnotations.forEach((existingAnnot) => {
      const { quads } = existingAnnot;
      const rect = quadsToDimensions(quads as Quad[]);

      if (rect) {
        minX = Math.min(minX, rect.X);
        minY = Math.min(minY, rect.Y);
        maxX = Math.max(maxX, rect.X + rect.Width);
        maxY = Math.max(maxY, rect.Y + rect.Height);
      }
    });
    // Outermost boundaries of existing annotations
    const existingBounds = { minX, minY, maxX, maxY };
    // Bounds of the new annotation
    const newCropRect = newCrop.getRect();
    // Check if any existing annotation falls outside the new crop boundaries
    return (
      existingBounds.minX < newCropRect.x1 ||
      existingBounds.minY < newCropRect.y1 ||
      existingBounds.maxX > newCropRect.x2 ||
      existingBounds.maxY > newCropRect.y2
    );
  }

  // called from saga
  cropZoneAnnotationChanged(documentType: DocumentTypes, annotation: Core.Annotations.Annotation, action: string) {
    const thisDocInputAnnotations =
      documentType === DocumentTypes.source
        ? PDFAnnotationManager.getInputAnnotations(DocumentTypes.source) || []
        : PDFAnnotationManager.getInputAnnotations(DocumentTypes.target) || [];

    if (action === AnnotationAction.ADD && annotation.getCustomData(AnnotationCustomData.drawMarkups) !== 'true') {
      const nonCropAnnotationsOnPage = thisDocInputAnnotations?.filter(
        (annot) => annot.usedTool !== PDFTronTools.CROP && annot.page === annotation.PageNumber,
      );
      // Show error and delete annotation if annotations exist outside the new crop zone
      if (
        nonCropAnnotationsOnPage?.length &&
        this.annotationsExistOutsideNewCrop(nonCropAnnotationsOnPage, annotation)
      ) {
        PDFAnnotationManager.deleteAnnotationById(documentType, annotation.Id);
        store.dispatch(
          app.actions.setSnackMessage({
            message: 'Please draw the crop around any existing markups on the page.',
            type: 'error',
          }),
        );
        return;
      }

      // Show success for the first crop zone added to inspection
      const bothDocsInputAnnots = thisDocInputAnnotations.concat(
        PDFAnnotationManager.getInputAnnotations(OPPOSITE_DOCUMENT[documentType]) || [],
      );
      const allCropZones = bothDocsInputAnnots?.filter((annot) => annot.usedTool === PDFTronTools.CROP);
      if (allCropZones?.length === 1) {
        store.dispatch(
          app.actions.setSnackMessage({
            message: `Your crop was created! You're ready to inspect.`,
            type: 'success',
          }),
        );
      }
      // move to back and add overlay
      const annotationManager =
        documentType === DocumentTypes.source ? this.source?.annotationManager : this.target?.annotationManager;
      annotationManager?.bringToBack(annotation);
    }
    this.addCropOverlay(documentType, annotation);
    this.annotationMixin.updateInputAnnotations();
  }

  createCropZoneAnnotationTools() {
    [this.source, this.target].forEach((wrapper: WebViewerWrapper | null) => {
      if (!wrapper) return;
      const { instance } = wrapper;
      class CropZoneCreateTool extends instance.Core.Tools.RectangleCreateTool {
        private canvas: HTMLCanvasElement | null = null;
        private context: CanvasRenderingContext2D | null = null;
        private lastMousePosition: { x: number; y: number } | null = null;

        constructor(cropZoneAnnotation: any) {
          super(instance.Core.documentViewer);
          instance.Core.Tools.GenericAnnotationCreateTool.call(this, instance.Core.documentViewer, cropZoneAnnotation);

          // Setup extended crosshairs
          this.setupCrosshairs();
        }

        private setupCrosshairs() {
          const viewerElement = instance.Core.documentViewer.getScrollViewElement();
          if (!viewerElement) return;

          // Ensure canvas covers the entire viewer element
          this.canvas = document.createElement('canvas');
          this.canvas.id = 'crosshair-canvas';
          this.canvas.style.position = 'absolute';
          this.canvas.style.top = '0';
          this.canvas.style.left = '0';
          this.canvas.style.pointerEvents = 'none';

          viewerElement.appendChild(this.canvas);
          this.context = this.canvas.getContext('2d') as CanvasRenderingContext2D;

          // Add event listeners
          window.addEventListener('resize', this.updateCanvasSize.bind(this));
          this.updateCanvasSize();

          viewerElement.addEventListener('mousemove', this.onMouseMove.bind(this) as EventListener);
          viewerElement.addEventListener('mouseleave', this.onMouseLeave.bind(this));
        }

        private drawCrosshairs(x: number, y: number) {
          if (!this.context || !this.canvas) return;

          this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

          this.context.beginPath();
          this.context.moveTo(x + 0.5, 0);
          this.context.lineTo(x + 0.5, this.canvas.height);
          this.context.strokeStyle = '#6DDAE2';
          this.context.lineWidth = 0.5;
          this.context.stroke();

          this.context.beginPath();
          this.context.moveTo(0, y + 0.5);
          this.context.lineTo(this.canvas.width, y + 0.5);
          this.context.strokeStyle = '#6DDAE2';
          this.context.lineWidth = 0.5;
          this.context.stroke();
        }

        private clearCanvas() {
          if (this.context && this.canvas) {
            this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
          }
        }

        private updateCanvasSize() {
          if (!this.canvas) return;

          const viewerElement = instance.Core.documentViewer.getScrollViewElement();
          if (!viewerElement) return;

          this.canvas.width = viewerElement.clientWidth;
          this.canvas.height = viewerElement.clientHeight;

          // Redraw crosshairs at last known mouse position
          if (this.lastMousePosition) {
            this.drawCrosshairs(this.lastMousePosition.x, this.lastMousePosition.y);
          }
        }

        private onMouseMove(event: MouseEvent) {
          const cursorStyle = window.getComputedStyle(event.target as Element).cursor;
          const currentPage = getCursorPageNumber(instance, toolObject, event);
          if (!currentPage) return; // this only happens if cursor is in the space between pages

          const pageCrop = getPageCropZone(instance, currentPage);

          if (
            pageCrop ||
            getSelectedTool(store.getState()) !== PDFTronTools.CROP ||
            cursorStyle !== CursorStyle.CROSSHAIR
          ) {
            this.clearCanvas();
            return;
          }

          const rect = this.canvas!.getBoundingClientRect();
          const x = event.clientX - rect.left;
          const y = event.clientY - rect.top;

          this.lastMousePosition = { x, y };
          this.drawCrosshairs(x, y);
        }

        private onMouseLeave() {
          this.clearCanvas();
        }

        switchOut(newTool: any) {
          super.switchOut(newTool);
          // Reset the cursors (to remove effects of mouseMove event handler)
          resetCursors();
        }
      }

      const toolObject = new CropZoneCreateTool(this.createCropZoneAnnotation(instance));
      const defaultMouseMoveEvent = toolObject.mouseMove.bind(toolObject);
      toolObject.mouseMove = (e) => {
        // get current page
        const currentPage = getCursorPageNumber(instance, toolObject, e);
        if (!currentPage) return; // this only happens if cursor is in the space between pages

        // check for crop zones
        const cropZone = getPageCropZone(instance, currentPage);
        const selectedAnnotation = instance.Core.annotationManager.getSelectedAnnotations()[0];
        const cropIsSelected = selectedAnnotation?.Id === cropZone?.Id;
        const iframeDoc = getIframeDocument(wrapper, currentPage);
        if (!iframeDoc) return;

        if (!cropZone) {
          const selectedTool = instance.Core.documentViewer.getToolMode();
          if (selectedTool) iframeDoc.style.cursor = selectedTool?.cursor;
          defaultMouseMoveEvent(e);
          return;
        }

        const cursorIsInsideCrop = cursorIsInsideCropZone(e, cropZone, wrapper, currentPage);
        const cursorIsInsideBufferZone = cursorIsInsideCropZone(e, cropZone, wrapper, currentPage, true);

        // Crop is selected and mouse is clicked: resizing or moving the crop zone
        if (cropIsSelected && e.buttons === 1 && !cropZone.Locked) {
          const documentType = wrapper === this.source ? DocumentTypes.source : DocumentTypes.target;
          this.removeCropOverlay(documentType, cropZone);
          defaultMouseMoveEvent(e);
          return;
        }

        // Ignore any other click event
        if (e.buttons === 1) {
          return;
        }

        // Crop is not selected. Select it if the cursor moves within the buffer zone
        if (!cropIsSelected) {
          if (cursorIsInsideBufferZone) {
            // deselect any previous annotation (prevents bug where 2 crops on diff pages get auto-selected)
            if (selectedAnnotation) instance.Core.annotationManager.deselectAnnotation(selectedAnnotation);
            instance.Core.annotationManager.selectAnnotation(cropZone);
            setCursorStyle(cropZone.Locked ? CursorStyle.POINTER : CursorStyle.DEFAULT, iframeDoc);
          } else {
            setCursorStyle(CursorStyle.NOT_ALLOWED, iframeDoc);
            defaultMouseMoveEvent(e);
          }
          return;
        }
        // Crop is selected
        // If cursor moves outside the crop zone, deselect it
        if (cursorIsInsideCrop) {
          setCursorStyle(cropZone.Locked ? CursorStyle.POINTER : CursorStyle.DEFAULT, iframeDoc);
        } else if (cursorIsInsideBufferZone) {
          setCursorStyle(CursorStyle.NOT_ALLOWED, iframeDoc);
        } else {
          instance.Core.annotationManager.deselectAnnotation(cropZone);
        }

        defaultMouseMoveEvent(e);
      };
      wrapper.registerTool({
        toolName: PDFTronTools.CROP,
        toolObject,
        buttonImage: '',
      });
    });
  }

  reloadCropZones(sourceCropZones: InputAnnotation[], targetCropZones: InputAnnotation[]) {
    const docManager = PDFManagerFactory.getPDFDocManager();
    if (!docManager) return;

    [this.source, this.target].forEach((webviewer: WebViewerWrapper | null) => {
      if (!webviewer) return;
      const annotManager = webviewer.annotationManager;
      const markups = webviewer === this.source ? sourceCropZones : targetCropZones;
      markups.forEach(async (markup) => {
        const existingAnnotation = annotManager.getAnnotationById(markup.annotationId);
        if (!existingAnnotation) {
          const annotation = new (docManager.createCropZoneAnnotation(webviewer.instance))();
          annotation.Id = markup.annotationId;
          annotation.PageNumber = markup.page;
          if (markup.cropZone) {
            const { x1, x2, y1, y2 } = markup.cropZone;
            annotation.setRect(new webviewer.instance.Core.Math.Rect(x1, y1, x2, y2));
          }

          annotation.ReadOnly = false;
          annotation.setCustomData(AnnotationCustomData.drawMarkups, 'true');

          annotManager.addAnnotation(annotation);
          annotManager.redrawAnnotation(annotation);
          await this.addCropOverlay(
            webviewer === this.source ? DocumentTypes.source : DocumentTypes.target,
            annotation,
          );
        }
      });
    });
    store.dispatch(inspection.actions.setCropZoneId(sourceCropZones.length + targetCropZones.length + 1));
  }

  cropZoneAnnotationSelected(documentType: DocumentTypes, _annotation: Core.Annotations.Annotation, _action: string) {
    const instance = documentType === DocumentTypes.source ? this.source?.instance : this.target?.instance;
    if (instance) {
      const totalPageCount = instance.Core.documentViewer.getPageCount();
      const popupButtons = [this.getDeleteCropZonePopup(instance)];
      if (totalPageCount > 1) {
        popupButtons.unshift(this.getApplyCropToAllPagesPopup(instance));
      }
      instance.UI.annotationPopup.update(popupButtons);
    }
  }

  getDeleteCropZonePopup(instance: WebViewerInstance) {
    return {
      type: 'actionButton',
      img: '/icons/zoneDelete.svg',
      title: 'Delete',
      onClick: () => {
        const selectedAnnotation = instance.Core.annotationManager.getSelectedAnnotations()[0];
        instance.Core.annotationManager.deleteAnnotation(selectedAnnotation);
      },
    };
  }

  getApplyCropToAllPagesPopup(instance: WebViewerInstance) {
    return {
      type: 'actionButton',
      img: '/icons/applyToAllPages.svg',
      title: 'Apply to all pages',
      onClick: () => {
        const selectedAnnotation = instance.Core.annotationManager.getSelectedAnnotations();
        this.applyCropToAllPages(instance, selectedAnnotation[0]);
        instance.Core.annotationManager.deselectAllAnnotations();
      },
    };
  }

  applyCropToAllPages(instance: WebViewerInstance, annotation: Core.Annotations.Annotation) {
    const documentType = instance === this.source?.instance ? DocumentTypes.source : DocumentTypes.target;
    const pageCount = instance.Core.documentViewer.getPageCount();
    const annotationManager = instance.Core.documentViewer.getAnnotationManager();
    const currentPage = annotation.PageNumber;
    let skippedPages = [];
    for (let page = 1; page <= pageCount; page++) {
      // skip the current page
      if (page === currentPage) {
        this.addCropOverlay(documentType, annotation);
        continue;
      }
      // Skip any page that already has a crop zone
      const existingCropZone = getPageCropZone(instance, page);
      if (existingCropZone) skippedPages.push(page);
      else {
        // Skip page if crop zone won't fit
        const cropDimensions = annotation.getRect();
        const { width: pageWidth, height: pageHeight } = instance.Core.documentViewer.getDocument().getPageInfo(page);
        if (
          cropDimensions.x1 < 0 ||
          cropDimensions.x2 > pageWidth ||
          cropDimensions.y1 < 0 ||
          cropDimensions.y2 > pageHeight
        ) {
          skippedPages.push(page);
          continue;
        }

        const newAnnotation = new (this.createCropZoneAnnotation(instance))();
        newAnnotation.setRect(annotation.getRect());
        newAnnotation.PageNumber = page;
        newAnnotation.setCustomData(AnnotationCustomData.drawMarkups, 'true');
        annotationManager.addAnnotation(newAnnotation);
        annotationManager.redrawAnnotation(newAnnotation);
        this.addCropOverlay(documentType, newAnnotation);
      }
    }

    if (skippedPages.length) {
      store.dispatch(
        app.actions.setSnackMessage({
          message: `The cropped region could not be applied to page${
            skippedPages.length > 1 ? 's' : ''
          } ${getPageRanges(skippedPages)}.`,
          type: 'error',
        }),
      );
    } else {
      store.dispatch(
        app.actions.setSnackMessage({
          message: 'Crop has successfully been applied to all pages.',
          type: 'success',
        }),
      );
    }
  }

  async addCropOverlay(documentType: DocumentTypes, cropZoneAnnotation: Core.Annotations.Annotation) {
    const box = cropZoneAnnotation.getRect();
    const documentWrapper = PDFManagerFactory.getViewer(documentType);

    if (!documentWrapper) return;
    const doc = documentWrapper.instance.Core.documentViewer.getDocument();
    if (!doc) return;
    const pdfDoc = await doc.getPDFDoc();
    if (!pdfDoc) return;

    const pageNumber = cropZoneAnnotation.PageNumber;
    const page = await pdfDoc.getPage(pageNumber);

    const pageHeight = await page.getPageHeight();
    const pageWidth = await page.getPageWidth();

    // Add page overlay to the annotation
    const boxHeight = box.y2 - box.y1;
    const boxWidth = box.x2 - box.x1;
    cropZoneAnnotation.draw = (ctx: CanvasRenderingContext2D) => {
      ctx.globalAlpha = 0.6;
      ctx.fillStyle = '#404040';
      ctx.save();
      ctx.fillRect(0, 0, pageWidth, pageHeight);
      ctx.clearRect(box.x1, box.y1, boxWidth, boxHeight);
      ctx.fillStyle = 'rgba(255, 255, 255, 0.01)';
      ctx.fillRect(box.x1, box.y1, boxWidth, boxHeight);
      ctx.globalAlpha = 1;
      ctx.strokeStyle = '#cd3434';
      ctx.lineWidth = 1;
      ctx.globalCompositeOperation = 'source-over';
      ctx.save();
      ctx.strokeRect(box.x1, box.y1, boxWidth, boxHeight);
    };

    documentWrapper.instance.Core.documentViewer.refreshAll();
    documentWrapper.instance.Core.documentViewer.updateView();
  }

  async removeCropOverlay(documentType: DocumentTypes, cropZoneAnnotation: Core.Annotations.Annotation) {
    const documentWrapper = PDFManagerFactory.getViewer(documentType);

    if (!documentWrapper) return;
    const doc = documentWrapper.instance.Core.documentViewer.getDocument();
    if (!doc) return;
    const pdfDoc = await doc.getPDFDoc();
    if (!pdfDoc) return;

    const pageNumber = cropZoneAnnotation.PageNumber;
    const page = await pdfDoc.getPage(pageNumber);
    const pageHeight = await page.getPageHeight();
    const pageWidth = await page.getPageWidth();

    // Remove the overlay
    cropZoneAnnotation.draw = (ctx: CanvasRenderingContext2D) => {
      ctx.clearRect(0, 0, pageWidth, pageHeight);
      ctx.save();
    };
    documentWrapper.instance.Core.documentViewer.refreshAll();
    documentWrapper.instance.Core.documentViewer.updateView();
  }
}

export default CropZone;
