import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DifferenceTypeNames, DocumentTypes, PDFTRON_DEFAULT_TOOL } from 'types';
import { inspection, getInspectionId, getGroupById, getSelectedSubDifferenceID, getIsSingleFile } from 'store';
import store from 'store/store';
import PDFTronManager from 'components/PDFViewer/PDFTronManager';
import { PDFManagerFactory } from 'pdftron';
import { updateDifference, UpdateDifferenceData } from 'store/request/differences/actions';
import { patchGroup } from 'store/request/difference-group/actions';
import { Difference } from 'store/request/differences/types';
import toastEvents from 'components/DocumentToast/toastEvents';
import DifferenceElementView from './DifferenceElementView';
import { useTracker } from '../../Tracker/TrackerProvider';

interface DifferenceElementProps {
  difference: Difference;
  numberOfDifference: number;
  isSelected?: boolean;
  onClickSelect: (value: boolean) => void;
  updateCommentOpen: (id: string, open: boolean) => void;
  isCommentOpen: boolean;
  updateSubdiffOpen: (id: string, open: boolean) => void;
  isSubdiffOpen: boolean;
}

const DifferenceElement = ({
  difference,
  numberOfDifference,
  isSelected = false,
  onClickSelect,
  updateCommentOpen,
  isCommentOpen,
  updateSubdiffOpen,
  isSubdiffOpen,
}: DifferenceElementProps) => {
  const tracker = useTracker();
  const dispatch = useDispatch();
  const group = useSelector(getGroupById(difference.groupId));
  const inspectionId = useSelector(getInspectionId);
  const selectedSubDiffID = useSelector(getSelectedSubDifferenceID);
  const isSingleFile = useSelector(getIsSingleFile);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const annotManager = PDFManagerFactory.getViewer(DocumentTypes.target)?.annotationManager;
    const annotations = annotManager?.getSelectedAnnotations();
    if (annotations?.length && !isSubdiffOpen) {
      annotManager?.selectAnnotation(annotations[0]);
    }

    if (difference.subDiff.find((subdiff) => subdiff.id === selectedSubDiffID)) updateSubdiffOpen(difference.id, true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [difference.subDiff, selectedSubDiffID, difference.id]);

  const resetDiffSelection = () => {
    // Force expire graphic annotation toast.
    store.dispatch(inspection.actions.expireAllToast());
    // Remove border highlight from target to match toast on difference change
    PDFManagerFactory.getViewer(DocumentTypes.target)?.removeAnnotationDocumentHighlight();
    // Reset tool incase we are marking graphic differences
    PDFTronManager.setToolMode(PDFTRON_DEFAULT_TOOL);
  };

  const handleToggleDiscard = (e: React.MouseEvent) => {
    e.preventDefault();

    resetDiffSelection();
    tracker.track({
      name: `difference-${difference.excluded ? 'restored' : 'discarded'}`,
      data: {
        differenceId: difference.id,
        differenceType: difference.type,
        groupType: difference.groupId ? 'group' : 'single',
      },
    });
    if (isSelected) {
      dispatch(inspection.actions.setInternalAnnotationsVisibilityToDefault());
    }
    if (!group) {
      const data: UpdateDifferenceData = { excluded: !difference.excluded, childList: [] };
      difference.subDiff.forEach((subDiff) => {
        data.childList?.push(subDiff.id);
      });
      dispatch(updateDifference(difference.id, data));
    } else {
      dispatch(patchGroup(inspectionId, difference.groupId, { excluded: !difference.excluded }));
    }
  };

  const onSelectDifference = () => {
    if (!difference.excluded) {
      resetDiffSelection();
      const sourceAnnotationManager = PDFManagerFactory.getViewer(DocumentTypes.source)?.annotationManager;
      const targetAnnotationManager = PDFManagerFactory.getViewer(DocumentTypes.target)?.annotationManager;
      sourceAnnotationManager?.deselectAllAnnotations();
      targetAnnotationManager?.deselectAllAnnotations();
      if (!isSelected) {
        if (difference.type === DifferenceTypeNames.Deletion) {
          store.dispatch(
            inspection.actions.setDocumentToast({
              documentType: DocumentTypes.target,
              toast: toastEvents.deletionDifference,
            }),
          );
        }

        const differenceAnnot =
          sourceAnnotationManager?.getAnnotationById(difference.id) ||
          targetAnnotationManager?.getAnnotationById(difference.id);
        if (!differenceAnnot) return;
        onClickSelect(true);

        if (isSingleFile) {
          targetAnnotationManager?.selectAnnotation(differenceAnnot);
        } else {
          sourceAnnotationManager?.selectAnnotation(differenceAnnot);
        }
      } else {
        dispatch(inspection.actions.unfocusDifference());
        dispatch(inspection.actions.setInternalAnnotationsVisibilityToDefault());
      }
    }
  };

  const toggleCommentOpen = (
    e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>,
    open: boolean,
  ) => {
    if (isSelected) {
      e.stopPropagation();
    }
    updateCommentOpen(difference.id, open);
  };

  const toggleSubdiff = (open: boolean) => {
    updateSubdiffOpen(difference.id, open);
  };

  // After when we refactor all the differences to use the new DifferenceCardLayout approach, we can move the logic stuff from DifferenceElementView here, and
  // render the type of difference view we need in each case. Or maybe even move it higher up as we don't need subdifference or comments for all the types, for example.
  return (
    <DifferenceElementView
      elementRef={ref}
      numberOfDifference={numberOfDifference}
      selectDifference={onSelectDifference}
      isSelected={isSelected}
      handleToggleComment={toggleCommentOpen}
      handleToggleDiscard={handleToggleDiscard}
      isCommentOpen={isCommentOpen}
      updateSubdiffOpen={toggleSubdiff}
      isSubdiffOpen={isSubdiffOpen}
      difference={difference}
      groupLength={group ? group.differenceList.length : 0}
    />
  );
};

export default DifferenceElement;
