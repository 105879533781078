import { Core, WebViewerInstance } from '@pdftron/webviewer';
import PDFAnnotationManager from 'components/PDFViewer/PDFAnnotationManager';
import PDFManagerFactory from 'pdftron/PDFManagerFactory';
import WebViewerWrapper from 'pdftron/core/WebViewerWrapper';
import { CropZoneCustomData, DocumentTypes, PDFTronTools } from 'types';
import { getFlagValue } from 'utils/getFlagValue/getFlagValue';

export function cropToolEnabled() {
  const graphics2 = getFlagValue('graphics2') || false;
  const graphicsPrepV2 = getFlagValue('graphics_prep_v2') || false;
  return graphics2 && graphicsPrepV2;
}

export enum CursorStyle {
  DEFAULT = 'default',
  POINTER = 'pointer',
  CROSSHAIR = 'crosshair',
  NOT_ALLOWED = 'grab',
}

export const setCursorStyle = (
  cursorStyle: string,
  iframeDoc: { style: { removeProperty: (arg0: string) => void; cursor: string } },
) => {
  if (cursorStyle === CursorStyle.DEFAULT) {
    iframeDoc.style.removeProperty('cursor');
  } else {
    iframeDoc.style.cursor = cursorStyle;
  }
};

export function resetCursors() {
  if (!cropToolEnabled()) return;

  const sourceViewer = PDFManagerFactory.getViewer(DocumentTypes.source);
  const targetViewer = PDFManagerFactory.getViewer(DocumentTypes.target);

  [sourceViewer, targetViewer].forEach((wrapper) => {
    if (!wrapper) return;
    const { instance } = wrapper as WebViewerWrapper;
    const pageCount = instance.Core.documentViewer.getPageCount();
    for (let i = 0; i < pageCount; i++) {
      const iframeDoc = getIframeDocument(wrapper, i);
      iframeDoc?.style?.removeProperty('cursor');
    }
  });
}

export function getCursorPageNumber(
  instance: WebViewerInstance,
  toolObject: { getMouseLocation: (arg0: Event | undefined) => any },
  event: Event,
) {
  if (!cropToolEnabled()) return;

  const displayMode = instance.Core.documentViewer.getDisplayModeManager().getDisplayMode();
  const windowCoordinates = toolObject.getMouseLocation(event);
  return displayMode.getSelectedPages(windowCoordinates, windowCoordinates).first;
}

export function cursorIsInsideCropZone(
  mouseEvent: { clientX: number; clientY: number },
  cropZone: { getRect: () => { x1: any; y1: any; x2: any; y2: any } },
  wrapper: WebViewerWrapper,
  currentPage: number,
  addBuffer = false,
) {
  if (!cropToolEnabled()) return;

  const { x1, y1, x2, y2 } = cropZone.getRect();
  const { instance } = wrapper;
  // Get cursor position (adjusted for scale and offsets)
  const iframeDoc = getIframeDocument(wrapper, currentPage);
  if (!iframeDoc) return;
  const zoomLevel = instance.UI.getZoomLevel();
  const rect = iframeDoc.getBoundingClientRect();
  const cursorX = (mouseEvent.clientX - rect.left + iframeDoc.scrollLeft) / zoomLevel;
  const cursorY = (mouseEvent.clientY - rect.top + iframeDoc.scrollTop) / zoomLevel;

  // Add padding if using crop tool so border and buttons remain clickable on hover.
  if (addBuffer) {
    let baseBuffer = 75;
    const buffer = baseBuffer / zoomLevel; // Adjust buffer based on zoom level

    return cursorX >= x1 - 5 && cursorX <= x2 + 5 && cursorY >= y1 - buffer && cursorY <= y2 + buffer;
  }
  return cursorX >= x1 - 5 && cursorX <= x2 + 5 && cursorY >= y1 - 5 && cursorY <= y2 + 5;
}

export function getPageCropZone(instance: WebViewerInstance | undefined, currentPage: number) {
  if (!cropToolEnabled()) return;

  if (!instance) return;
  return instance.Core.annotationManager.getAnnotationsList().find((annot: any) => {
    return annot.getCustomData(CropZoneCustomData.cropZoneAnnotation) === 'true' && annot.PageNumber === currentPage;
  });
}

export function getIframeDocument(wrapper: WebViewerWrapper, currentPage: number) {
  if (!cropToolEnabled()) return;
  return wrapper.getIframeWindow().document.getElementById(`pageContainer${currentPage.toString()}`);
}

export function disableToolOutsideCropZones(tool: any, wrapper: WebViewerWrapper) {
  if (!cropToolEnabled()) return;

  const { instance } = wrapper;

  const defaultMouseMoveEvent = tool.mouseMove.bind(tool);
  tool.mouseMove = (e: MouseEvent) => {
    // Check if there is a crop zone on the page
    const currentPage = getCursorPageNumber(instance, tool, e);
    if (!currentPage) return; // this only happens if cursor is in the space between pages

    // get crop zone
    const cropZone = getPageCropZone(instance, currentPage);
    const selectedAnnotation = instance.Core.annotationManager.getSelectedAnnotations()[0];

    if (cropZone) {
      const iframeDoc = getIframeDocument(wrapper, currentPage);
      if (!iframeDoc) return;

      // Enable/Disable tool cursor based on cursor position
      if (cropZone.Id === selectedAnnotation?.Id || cursorIsInsideCropZone(e, cropZone, wrapper, currentPage)) {
        const selectedTool = instance.Core.documentViewer.getToolMode();
        iframeDoc.style.cursor = selectedTool.cursor;
        defaultMouseMoveEvent(e);
      } else {
        iframeDoc.style.cursor = CursorStyle.NOT_ALLOWED;
      }
    } else {
      defaultMouseMoveEvent(e);
    }
  };
}

// Used to prevent accidentally selecting the crop zones while using other tools
export function setCropZonesAreListable(areListable: boolean) {
  if (!cropToolEnabled()) return;

  const getCropAnnotations = (docType: DocumentTypes) => {
    const annotMgr = PDFAnnotationManager.getInstance(docType);
    return annotMgr?.getAnnotationsList()?.filter((annot) => annot.ToolName === PDFTronTools.CROP) ?? [];
  };
  const sourceCrops = getCropAnnotations(DocumentTypes.source);
  const targetCrops = getCropAnnotations(DocumentTypes.target);
  const allCropZones = [...sourceCrops, ...targetCrops];
  allCropZones.forEach((cropZone) => {
    cropZone.Listable = areListable;
  });
}

// Checks if a crop zone contains annotations, and if so, it locks it
export function manageCropZoneLocking(cropZone: Core.Annotations.Annotation, documentType: DocumentTypes) {
  if (!cropToolEnabled()) return;

  const annots = PDFAnnotationManager.getInputAnnotations(documentType)?.filter((annot) => {
    return annot.usedTool !== PDFTronTools.CROP;
  });
  if (!annots) return;
  // if there's more than 1 annotation on the page, it means there are annotations within the crop zone. Lock it.
  cropZone.Locked = annots && annots.length > 0;
}

export function annotationIsOutsideExistingCropZone(
  annotation: Core.Annotations.Annotation,
  documentType: DocumentTypes,
) {
  if (!cropToolEnabled()) return;

  const instance = PDFManagerFactory.getViewer(documentType)?.instance;
  const cropZone = getPageCropZone(instance, annotation.PageNumber);
  if (!cropZone) return false;

  const annotationRect = annotation.getRect();
  const cropZoneRect = cropZone.getRect();

  return (
    annotationRect.x1 < cropZoneRect.x1 ||
    annotationRect.x2 > cropZoneRect.x2 ||
    annotationRect.y1 < cropZoneRect.y1 ||
    annotationRect.y2 > cropZoneRect.y2
  );
}
