import store from 'store/store';
import { getDocumentsLoaded, getPageRangeSelector } from 'store';
import { DocumentTypes } from 'types';
import PDFManagerFactory from 'pdftron/PDFManagerFactory';

/* for the criteria of full page graphics, determine whether the included pages in sequence are equal in dimensions */
export default function isIncludedPagesEqualDimension(): boolean | null {
  const state = store.getState();
  const documentsLoaded = getDocumentsLoaded(state);
  const pageRange = getPageRangeSelector(state);
  if (!documentsLoaded) {
    return null;
  }
  const sourceWebViewer = PDFManagerFactory.getViewer(DocumentTypes.source);
  const targetWebViewer = PDFManagerFactory.getViewer(DocumentTypes.target);
  if (!sourceWebViewer || !targetWebViewer) {
    return null;
  }
  const sourceTotalPageCount = sourceWebViewer.page.getTotalPages();
  const targetTotalPageCount = targetWebViewer.page.getTotalPages();

  const sourceIncludedPageNumbers = pageRange.source.length
    ? pageRange.source
    : Array.from({ length: sourceTotalPageCount }, (_, i) => i + 1);
  const targetIncludedPageNumbers = pageRange.target.length
    ? pageRange.target
    : Array.from({ length: targetTotalPageCount }, (_, i) => i + 1);

  if (sourceIncludedPageNumbers.length !== targetIncludedPageNumbers.length) return false;

  // is every included page in sequence equal in dimension
  return sourceIncludedPageNumbers.every((_, i) => {
    const sourcePage = sourceWebViewer.page.getPageSize(sourceIncludedPageNumbers[i]);
    const targetPage = targetWebViewer.page.getPageSize(targetIncludedPageNumbers[i]);
    return sourcePage.height === targetPage.height && sourcePage.width === targetPage.width;
  });
}
