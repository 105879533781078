import { LDClient } from 'launchdarkly-react-client-sdk';

class LDClientSingleton {
  private static instance: LDClient;

  private constructor() {}

  static setInstance(client: LDClient) {
    if (!LDClientSingleton.instance) {
      LDClientSingleton.instance = client;
    }
  }

  static getInstance(): LDClient {
    if (!LDClientSingleton.instance) {
      throw new Error('LDClient has not been initialized');
    }
    return LDClientSingleton.instance;
  }
}

export default LDClientSingleton;
