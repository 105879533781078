import { Action, Store } from '@reduxjs/toolkit';
import { app, getDifferencesLoadedSelector, inspection } from 'store';
import {
  DocumentTypes,
  ZoneCustomData,
  AnnotationCustomData,
  InspectedAnnotationCustomData,
  PDFTronTools,
  CropZoneCustomData,
} from 'types';
import { isInspectionUrl, isReportsUrl, isResultUrl } from 'utils/location';
import { Core } from '@pdftron/webviewer';
import { PDFManagerFactory } from 'pdftron';
import { updateDifference } from 'store/request/differences/actions';
import PDFAnnotationManager from './PDFAnnotationManager';
import { zoneSearchEventHandler } from './ZoneSearchEventHandler';
import PDFTronManager from './PDFTronManager';
import { AnnotationAction } from './Utils';
import {
  cropToolEnabled,
  getPageCropZone,
  manageCropZoneLocking,
} from 'pdftron/docManager/AnnotationTools/utils/CropToolUtils';

const handleAnnotationChanged = async (
  dispatch: React.Dispatch<Action>,
  documentType: DocumentTypes,
  annotations: Core.Annotations.Annotation[],
  action: string,
  store: Store,
) => {
  if (
    action === AnnotationAction.ADD &&
    annotations[0].getCustomData(CropZoneCustomData.cropZoneAnnotation) === 'true'
  ) {
    const annotManager = PDFAnnotationManager.getInstance(documentType);
    // prevent pdftron bug when moving crop zone to another page
    if (annotations[0].ToolName !== PDFTronTools.CROP) {
      annotManager?.deleteAnnotation(annotations[0]);
      return;
    }
    // failsafe to prevent drawing 2 crops on the same page
    const cropZonesOnPage = PDFAnnotationManager.getInputAnnotations(documentType)?.filter((annot) => {
      return annot.usedTool === PDFTronTools.CROP && annot.page === annotations[0].PageNumber;
    });
    if (cropZonesOnPage && cropZonesOnPage.length > 1) {
      annotManager?.deleteAnnotation(annotations[0]);
      return;
    }
  }

  const isDeletionMarker = annotations[0].getCustomData(AnnotationCustomData.deletionMarker) === 'true';
  const isLiveText = annotations[0].getCustomData(AnnotationCustomData.liveText) === 'true';
  if (isLiveText) return;
  // we don't want to update the annotations if we are in the result page or report page. With the exception of deletion marker
  if ((isResultUrl() && !isDeletionMarker) || isReportsUrl()) return;

  if (annotations.some((anno) => anno.getCustomData(ZoneCustomData.zoneSearch) === 'true')) {
    zoneSearchEventHandler(action, annotations, store, dispatch, documentType);
  }

  const instance = PDFTronManager.getPDFInstance(documentType);
  if (!instance) return;

  if (cropToolEnabled()) {
    // check if page has a crop zone, if so, check if it needs to be locked (if it contains annotations)
    const cropZone = getPageCropZone(instance, annotations[0].PageNumber);
    if (cropZone) {
      manageCropZoneLocking(cropZone, documentType);
    }
  }

  const newAnnotation = annotations[0];

  const inputAnnotations = PDFAnnotationManager.getInputAnnotations(documentType);
  // this prevents inputAnnotations from being null (typescript error)
  if (!inputAnnotations) return;

  if (isDeletionMarker && action === AnnotationAction.MODIFY) {
    // By the time this happens, what we are seeing as the Annotation is actually the High contrast overlay annotation.
    // That annotation, contains a refAnnotationId array which links it to any other annotation grouped with the overlay.
    // (It is possible that we highlight the whole group)
    // In the case of moving the deletion marker, despite being a group or not, we need to make sure we call updateDifference
    // passing a difference id as a string and not an array. If it's a group, updating just the first refId would be enough
    // to trigger the report update (since one of the differences changed updatedAt to a newer date)
    const refAnnotationIds = newAnnotation.getCustomData(AnnotationCustomData.refAnnotationIds).split(',');
    dispatch(
      updateDifference(refAnnotationIds[0], {
        deletionMarkPosition: {
          x: newAnnotation.X,
          y: newAnnotation.Y,
          pageHeight:
            PDFManagerFactory.getViewer(DocumentTypes.target)?.getPageSize(newAnnotation.PageNumber).height || 0,
        },
      }),
    );
    dispatch(app.actions.clearSnackMessageType('info'));
  }
};

const handleAnnotationLoaded = (
  dispatch: React.Dispatch<Action>,
  documentType: DocumentTypes,
  docViewer: Core.DocumentViewer,
  store: Store,
) => {
  const annotationManager = docViewer.getAnnotationManager();
  PDFAnnotationManager.setInstance(documentType, annotationManager);

  // Listen for new annotations
  annotationManager.addEventListener(
    'annotationChanged' as unknown as any, // TODO-@PDFTRON Fix this hack when PDFTron types are fine
    (annotations: Core.Annotations.Annotation[], action: string) => {
      handleAnnotationChanged(dispatch, documentType, annotations, action, store);
    },
  );
};

const handleAnnotationSelected = (
  annotations: Core.Annotations.Annotation[],
  store: Store,
  document: DocumentTypes,
) => {
  const pdfDocManager = PDFManagerFactory.getPDFDocManager();
  if (!pdfDocManager) return;
  // reset the popup menu each time annotation is selected
  if (isResultUrl()) {
    const selectedAnnotation = annotations[0];
    const groupId = selectedAnnotation.getCustomData('groupId');
    // clearing the annotation popup for grouped differences has been causing some issues VE-8395
    if (!groupId) pdfDocManager[document]?.instance.UI.annotationPopup.update([]);
  }

  // If the user has selected the loading indicator, do not continue.
  if (annotations[0].Id === 'loader') return;

  // inspected annotations need to have hovering capacity but not selection
  if (annotations[0].getCustomData(InspectedAnnotationCustomData.inspectedAnnotation) === 'true') {
    const annoManager = PDFAnnotationManager.getInstance(document);
    annoManager?.deselectAnnotation(annotations[0]);
    return;
  }

  if (annotations[0].ToolName === PDFTronTools.SHIFTED_GRAPHIC) {
    const annoManager = PDFAnnotationManager.getInstance(document);
    annoManager?.deselectAnnotation(annotations[0]);

    pdfDocManager[document]?.instance.UI.annotationPopup.update([]);
    return;
  }

  const differencesLoaded = getDifferencesLoadedSelector(store.getState());
  // we only want this behavior on results page
  if (differencesLoaded && isResultUrl() && annotations[0].getCustomData(AnnotationCustomData.difference) === 'true') {
    pdfDocManager.differenceAnnotationSelected(annotations[0], document);
  }
};

const handleAnnotationDeselected = (
  dispatch: React.Dispatch<Action>,
  annotation: Core.Annotations.Annotation,
  document: DocumentTypes,
) => {
  if (isInspectionUrl()) {
    // deselect all annotations when in inspection page (to make sure we dont break differences for now)
    dispatch(inspection.actions.setSelectedSubDifferenceId(''));
    PDFAnnotationManager.getInstance(DocumentTypes.source)?.deselectAllAnnotations();
    PDFAnnotationManager.getInstance(DocumentTypes.target)?.deselectAllAnnotations();
  }
  if (annotation.ToolName === PDFTronTools.CROP) {
    PDFManagerFactory?.getPDFDocManager()?.addCropOverlay(document, annotation);
    return;
  }
};

export { handleAnnotationChanged, handleAnnotationLoaded, handleAnnotationSelected, handleAnnotationDeselected };
