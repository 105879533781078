import { useState } from 'react';
import { Typography, Grid, Button, Theme } from '@mui/material';
import { DropzoneRootProps, DropzoneInputProps } from 'react-dropzone';
import { DocumentTypes } from 'types';
import BackgroundBorder from './BackgroundBorder';
import { makeStyles } from 'tss-react/mui';
import { UploadIcon } from 'components/icons';
import { getStyleVariables } from 'styles/vars';

interface DragAndDropBackground {
  document: DocumentTypes;
  title: string;
  handleShowLibrary: () => void;
  getRootProps: (props?: DropzoneRootProps | undefined) => DropzoneRootProps;
  getInputProps: (props?: DropzoneInputProps | undefined) => DropzoneInputProps;
  open: () => void;
  dragging: boolean;
}

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    dropzone: {
      display: 'flex',
      flex: 1,
      overflowY: 'auto',
    },
    button: {
      marginTop: '2vh',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      color: theme.palette.text.primary,
      border: '1px solid black',
      background: styleVariables.colors.menuLightGrey,
      boxShadow: 'none',
      '&:hover': {
        background: styleVariables.colors.lightGrey,
        boxShadow: 'none',
      },
    },
    textContainer: {
      marginTop: '2vh',
    },
    textButton: {
      textDecoration: 'underline',
      textTransform: 'none',
      opacity: '1',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    text: {
      fontSize: 12,
      fontWeight: 600,
    },
  };
});

const DragAndDropBackground = (props: DragAndDropBackground) => {
  const { getRootProps, getInputProps, open, handleShowLibrary, dragging } = props;
  const { classes, cx } = useStyles();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className={classes.dropzone} {...getRootProps()} onDoubleClick={open}>
      <input id={`upload-${props.document}`} {...getInputProps()} />
      <BackgroundBorder animate={isHovered || dragging}>
        <UploadIcon animate={isHovered || dragging} />
        <Grid
          className={classes.textContainer}
          container
          flexDirection={'row'}
          justifyContent={'center'}
          alignItems={'flex-end'}
          gap={'0.4rem'}
        >
          <Typography color="primary" className={classes.text}>
            {'Drag & drop your file(s), '}
          </Typography>
          <Button
            className={classes.textButton}
            onClick={open}
            onMouseEnter={() => {
              setIsHovered(true);
            }}
            onMouseLeave={() => {
              setIsHovered(false);
            }}
            data-testid={`${props.document}_button_browse`}
          >
            <Typography color="primary" className={classes.text}>
              Browse File(s)
            </Typography>
          </Button>
        </Grid>
        <Typography color="primary" className={classes.text}>
          or
        </Typography>
        <Button
          size="large"
          variant="contained"
          className={classes.button}
          onClick={handleShowLibrary}
          data-testid={`${props.document}_button_library`}
        >
          Open Library
        </Button>
      </BackgroundBorder>
    </div>
  );
};

export default DragAndDropBackground;
