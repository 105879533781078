import React, { useEffect, useState } from 'react';
import { Grid, Theme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  getInspectionSettings,
  inspection,
  getTextProcessStatus,
  getDocumentsLoaded,
  getPageRangeSelector,
} from 'store';
import GVCheckbox from 'components/lib/GVCheckbox/GVCheckbox';
import isIncludedPagesEqualDimension from 'pdftron/docManager/utils/isIncludedPagesEqualDimension';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { makeStyles } from 'tss-react/mui';
import GraphicsMenu from './GraphicsMenu';

const useStyles = makeStyles()((theme: Theme) => {
  return {
    container: {
      display: 'flex',
      alignItems: 'center',
    },
  };
});

const FullPageGraphicZoneSettings = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const documentProcessed = useSelector(getTextProcessStatus);
  const settings = useSelector(getInspectionSettings);
  const pageRange = useSelector(getPageRangeSelector);
  const featureFlags = useFlags();
  const fullPageSelected = settings?.fullPageGraphics === true;
  const documentsLoaded = useSelector(getDocumentsLoaded);
  const [fullPageEnabled, setFullPageEnabled] = useState<boolean>(false);
  const inspectionSettings = useSelector(getInspectionSettings);

  useEffect(() => {
    if (featureFlags.graphicsForceEnableFullPageGraphics || (featureFlags.graphics2 && featureFlags.graphicsPrepV2)) {
      setFullPageEnabled(documentsLoaded);
    } else {
      const isAllPagesEqualDimension = isIncludedPagesEqualDimension();
      setFullPageEnabled(!!isAllPagesEqualDimension);
    }
  }, [pageRange.source, pageRange.target, dispatch, documentsLoaded]);

  const toggleGraphicsCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(inspection.actions.toggleGraphics(event.target.checked));
  };

  let graphicsCheckboxClass = '';
  const checkboxDisabled = !documentProcessed || !fullPageEnabled;

  if (fullPageEnabled) {
    graphicsCheckboxClass = 'fullPageGraphicsEnabled';
  } else if (documentProcessed && !fullPageEnabled) {
    graphicsCheckboxClass = 'fullPageGraphicsDisabled';
  }

  if (featureFlags.graphics2 && featureFlags.graphicsPrepV2) {
    return (
      <Grid container direction="column">
        <Grid display="flex" justifyContent="space-between" item container direction="row" alignItems="center">
          <Grid item className={`${graphicsCheckboxClass} ${classes.container}`}>
            <GVCheckbox
              checked={!!fullPageSelected}
              onChange={toggleGraphicsCheck}
              label="Graphics"
              data-testid="fullgraph_enable"
              disabled={checkboxDisabled}
            />
          </Grid>
          {inspectionSettings.fullPageGraphics && <GraphicsMenu />}
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container direction="column">
        <Grid item className={graphicsCheckboxClass}>
          <GVCheckbox
            checked={!!fullPageSelected}
            onChange={toggleGraphicsCheck}
            label="Graphics (Full Page)"
            data-testid="fullgraph_enable"
            disabled={checkboxDisabled}
          />
        </Grid>
      </Grid>
    );
  }
};

export default FullPageGraphicZoneSettings;
