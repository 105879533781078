import { createSelector } from '@reduxjs/toolkit';
import { getTypeCounts } from 'tracker/counters';
import { CTFModuleTypes, Difference, DifferenceTypeNames, SortDifferencesOption, StoreState } from 'types';
import { getQuerySelector } from '@redux-requests/core';
import { getCommentedDifferences, getDifferences } from '../differences/selectors';
import { fetchInspectionsList } from './actions';

export const getInspectionsList = createSelector([getQuerySelector({ type: fetchInspectionsList })], (inspections) => {
  return inspections.data || [];
});

// placed inside inspection selectors to decouple filter from differences
export const getDifferenceFilters = (state: StoreState) => state.inspection.differenceViewOptions.filters;
export const getSelectedModule = (state: StoreState) => state.inspection.differenceViewOptions.selectedModule;
const getDifferenceSortOption = (state: StoreState) => state.inspection.differenceViewOptions.sortBy;
export const getSelectedBarcodeOption = (state: StoreState) => state.inspection.settings.barcode;
// Making this a Boolean so that it doesn't reset & re-trigger the No-Braille popup if a user changes the braille language
// but navigates back to Result tab without re-running the inspection
export const getIsBraille = (state: StoreState) => Boolean(state.inspection.settings.brailleLanguage);

// TODO: Repurpose this function to return source or target based on difference type
const isSourceOrTarget = (difference: Difference) => {
  return difference.source?.location.rect ? 'source' : 'target';
};

const sortDifferences = (differences: Difference[], sortOption: SortDifferencesOption | undefined) => {
  if (differences.length < 2) {
    return differences;
  }

  // Should account for undefined sort direction in which case it will default to type
  const isDiffSortType = sortOption !== SortDifferencesOption.readingOrder;

  const sortedDifferences = differences.sort((a, b) => {
    if (isDiffSortType) {
      // sort by type if needed
      if (a.displayName < b.displayName) {
        return -1;
      }
      if (a.displayName > b.displayName) {
        return 1;
      }
    }

    // sort by page
    if (a[isSourceOrTarget(a)].location.pageNumber < b[isSourceOrTarget(b)].location.pageNumber) {
      return -1;
    }
    if (a[isSourceOrTarget(a)].location.pageNumber > b[isSourceOrTarget(b)].location.pageNumber) {
      return 1;
    }

    // sort by Y.
    // [x,y,width,height]
    if (a[isSourceOrTarget(a)].location.rect[1] < b[isSourceOrTarget(b)].location.rect[1]) {
      return -1;
    }
    if (a[isSourceOrTarget(a)].location.rect[1] > b[isSourceOrTarget(b)].location.rect[1]) {
      return 1;
    }
    // sort by x.
    // Consider reading order ltr, rtl, etc. once the flag is in the job.
    if (a[isSourceOrTarget(a)].location.rect[0] < b[isSourceOrTarget(b)].location.rect[0]) {
      return -1;
    }
    if (a[isSourceOrTarget(a)].location.rect[0] > b[isSourceOrTarget(b)].location.rect[0]) {
      return 1;
    }

    return 0;
  });

  return sortedDifferences;
};

// difference which are not filtered
export const getUnfilteredDifferences = createSelector(
  [getDifferences, getDifferenceFilters],
  (differences, filters) => {
    if (filters) {
      return differences.filter((diff) => {
        return !filters[diff.type];
      });
    }
    return differences;
  },
);

export const getFilteredDifferences = createSelector(
  [getDifferences, getDifferenceFilters, getSelectedModule],
  (differences, filters) => {
    if (filters) {
      return differences.filter((diff) => {
        if (!filters[diff.type]) {
          return false;
        }
        return filters[diff.type];
      });
    }
    return [];
  },
);

// difference groups which are ordered, not filtered, and not excluded to be displayed on results tab
export const getDisplayedDifferenceGroups = createSelector(
  [getUnfilteredDifferences, getDifferenceSortOption, getSelectedModule],
  (differences, sortOption, module): Difference[] => {
    const alreadyGrouped: Array<string> = [];
    const sortDiffs = sortDifferences(differences, sortOption);

    const diffs = sortDiffs.filter((difference) => {
      // we need to keep track of the groups we already included in the differences list.
      if (difference.groupId) {
        if (alreadyGrouped.includes(difference.groupId)) {
          return false;
        }
        alreadyGrouped.push(difference.groupId);
      }

      return !difference.excluded;
    });

    return filterDifferencesByModule(diffs, module);
  },
);

// difference groups which are ordered, not filtered and excluded to be displayed on discarded results
export const getDiscardedGroups = createSelector(
  [getUnfilteredDifferences, getDifferenceSortOption, getSelectedModule],
  (differences, sortOption, module): Difference[] => {
    const alreadyGrouped: Array<string> = [];

    const sortDiffs = sortDifferences(differences, sortOption);

    const diffs = sortDiffs.filter((difference) => {
      // we need to keep track of the groups we already included in the differences list.
      if (difference.groupId) {
        if (alreadyGrouped.includes(difference.groupId)) {
          return false;
        }
        alreadyGrouped.push(difference.groupId);
      }

      return difference.excluded;
    });

    return filterDifferencesByModule(diffs, module);
  },
);

// differences which are not filtered and excluded
export const getDiscardedDifferences = createSelector(
  [getUnfilteredDifferences, getSelectedModule],
  (differences, module) => {
    const excludedDiffs = differences.filter((difference) => difference.excluded);

    return filterDifferencesByModule(excludedDiffs, module);
  },
);

function filterDifferencesByModule(
  differences: Difference[],
  module: CTFModuleTypes | 'All',
  getHidden = false, // for getting what differences are hidden by the module
) {
  if (module === 'All') {
    return getHidden ? [] : differences;
  }

  if (module === CTFModuleTypes.text) {
    const nonTextModuleTypes = [
      DifferenceTypeNames.Annotation,
      DifferenceTypeNames.Barcode,
      DifferenceTypeNames.Braille,
      DifferenceTypeNames.Graphics,
      DifferenceTypeNames.Spelling,
    ];
    return differences.filter((diff) =>
      getHidden ? nonTextModuleTypes.includes(diff.type) : !nonTextModuleTypes.includes(diff.type),
    );
  }

  const moduleTypeMapping = {
    [CTFModuleTypes.annotation]: DifferenceTypeNames.Annotation,
    [CTFModuleTypes.barcode]: DifferenceTypeNames.Barcode,
    [CTFModuleTypes.braille]: DifferenceTypeNames.Braille,
    [CTFModuleTypes.graphics]: DifferenceTypeNames.Graphics,
    [CTFModuleTypes.spelling]: DifferenceTypeNames.Spelling,
  };
  const filterType = moduleTypeMapping[module];
  if (!filterType) return differences;

  return differences.filter((diff) => (getHidden ? diff.type !== filterType : diff.type === filterType));
}

// differences which are not filtered and not excluded
export const getDisplayedDifferences = createSelector(
  [getUnfilteredDifferences, getSelectedModule],
  (differences, module) => {
    const includedDifferences = differences.filter((difference) => !difference.excluded);
    return filterDifferencesByModule(includedDifferences, module);
  },
);

// differences which are filtered or excluded
export const getHiddenDifferences = createSelector(
  [getUnfilteredDifferences, getFilteredDifferences, getSelectedModule],
  (unfilteredDifferences, filteredDifferences, module) => {
    const differencesNotInModuleSet = new Set(filterDifferencesByModule(unfilteredDifferences, module, true));

    const differencesExcludedOrNotInModule = unfilteredDifferences.filter(
      (diff) => diff.excluded || differencesNotInModuleSet.has(diff),
    );
    return [...differencesExcludedOrNotInModule, ...filteredDifferences];
  },
);

// barcode differences
export const getBarcodeDifferencesExist = createSelector([getDifferences], (differences) => {
  const barcodelist = differences.filter((difference) => difference.type === DifferenceTypeNames.Barcode);
  return barcodelist.length > 0;
});

// braille differences
export const getBrailleDifferencesExist = createSelector([getDifferences], (differences) => {
  return differences.some((difference) => difference.type === DifferenceTypeNames.Braille);
});

/* SEGMENT/AUDIT */
export const getSegmentDisplayedDifferenceCounts = createSelector([getDisplayedDifferences], (differences) => {
  return getTypeCounts(differences);
});

export const getSegmentCommentedDifferenceCounts = createSelector([getCommentedDifferences], (differences) => {
  return getTypeCounts(differences);
});

export const getSegmentDiscardedDifferenceCounts = createSelector([getDiscardedDifferences], (differences) => {
  return getTypeCounts(differences);
});
