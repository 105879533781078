import React, { useEffect, useState } from 'react';
import { Grid, Theme, Button, Tabs, Tab } from '@mui/material';
import { getStyleVariables, menuLightGrey } from 'styles/vars';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import {
  getDifferencesLoadedSelector,
  getShowDiscarded,
  inspection,
  getDiscardedDifferences,
  getBarcodeDifferencesExist,
  getSelectedBarcodeOption,
  getisBarcode,
  getBrailleDifferencesExist,
  getIsBraille,
  getAllPageLoadedSelector,
  getCtfJobs,
  getDisplayedDifferences,
  getSelectedModule,
} from 'store';
import { isResultUrl } from 'utils/location';
import FilteredList from './FilteredList';
import OrderingList from './OrderingList';
import ListOfDifferences from './ListOfDifferences';
import NoBarcodeNotification from './NoBarcodeNotification';
import NoBrailleNotification from './NoBrailleNotification';
import ResultsTabAlerts from './ResultsTabAlerts';
import { makeStyles } from 'tss-react/mui';
import { GVTypography } from 'components/lib';
import { colors } from 'components/lib/global/styles';
import { CTFModuleTypes } from 'types';

const useStyles = makeStyles()((theme: Theme) => {
  const styleVariables = getStyleVariables(theme);
  return {
    root: {
      height: `calc(100% - ${styleVariables.leftPanel.tabsHeight})`,
    },
    options: {
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(2),
    },
    buttonsContainer: {
      marginLeft: theme.spacing(6.5),
    },
    listOfDifferences: {
      height: `calc(100% - ${styleVariables.leftPanel.resultsOptionsHeight})`, // total - top options
      overflowY: 'auto',
    },
    modules: {
      backgroundColor: colors.darkText,
      borderRadius: '6px',
      maxWidth: 'fit-content',
    },
    inspectionModule: {
      padding: theme.spacing(1, 0.8),
      borderRadius: '5px',
      fontSize: '12px',
      fontWeight: 600,
      margin: theme.spacing(0.3),
      color: colors.gvWhite,
      minWidth: 0,
    },
    inspectionModuleEnabled: {
      backgroundColor: menuLightGrey,
    },
    tab: {
      minWidth: '116px',
      fontSize: '11px',
      fontWeight: 600,
    },
    tabs: {
      minHeight: '32px',
    },
  };
});

const ResultTabView = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const [showBarcodePopup, setShowBarcodePopup] = useState(false);
  const [showBraillePopup, setShowBraillePopup] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const documentsLoaded = useSelector(getAllPageLoadedSelector);
  const differencesCount = useSelector(getDisplayedDifferences).length;
  const discardedDifferencesCount = useSelector(getDiscardedDifferences).length;
  const differencesLoaded = useSelector(getDifferencesLoadedSelector);
  const showDiscarded = useSelector(getShowDiscarded);
  const barcodeDifferences = useSelector(getBarcodeDifferencesExist);
  const barcodeOption = useSelector(getSelectedBarcodeOption);
  const isBarcode = useSelector(getisBarcode);
  const isBraille = useSelector(getIsBraille);
  const brailleDifferences = useSelector(getBrailleDifferencesExist);
  const ctfJobs = useSelector(getCtfJobs);
  const selectedModule = useSelector(getSelectedModule);

  const handleToggleDiscard = (showDiscarded: boolean) => {
    dispatch(inspection.actions.setShowDiscarded(showDiscarded));
  };

  const toggleNoBarcodeDisplay = () => {
    setShowBarcodePopup(false);
    // isBarcode is only ever set when fetching inspection. It is set to false here so it only shows once per inspection load.
    dispatch(inspection.actions.setIsBarcode(false));
  };

  const toggleNoBrailleDisplay = () => {
    setShowBraillePopup(false);
  };

  /** In this useEffect:
   *  Wrapping this grouping pop-up around a grouping existing condition
   *  Counting the number of differences in the group list
   * */

  useEffect(() => {
    if (!barcodeDifferences && barcodeOption && differencesLoaded && !isBarcode) {
      setShowBarcodePopup(true);
    } else {
      setShowBarcodePopup(false);
    }
  }, [barcodeDifferences, barcodeOption, differencesLoaded, isBarcode]);

  useEffect(() => {
    if (isBraille && !brailleDifferences && differencesLoaded) {
      setShowBraillePopup(true);
    } else {
      setShowBraillePopup(false);
    }
  }, [isBraille, brailleDifferences, differencesLoaded]);

  const handleTabs = (_event: React.ChangeEvent<{}>, newSelectedTab: number) => {
    setSelectedTab(newSelectedTab); // @todo add loading?
  };

  const displayPopups = () => {
    /*
     *  since we only have 2 (soon to be 3) popups I have just done if else.
     *  When we get to a point that there will be different types of popups we can implement numbered priority.
     */
    let popup;
    // const tableEhancement = notificationPopUps.find(noti => noti.type === DialogPopupTypes.TableEnhancementPopup); VE-1393
    // if (tableEhancement && !tableEhancement.dismissed) {
    //   popup = <TableEnhancementsNotification open={showTableEnhancementPopup} />;
    // } else VE-1393

    if (showBarcodePopup && isResultUrl(location)) {
      popup = <NoBarcodeNotification open={showBarcodePopup} onClose={toggleNoBarcodeDisplay} />;
    }

    if (showBraillePopup && isResultUrl(location)) {
      popup = <NoBrailleNotification open={showBraillePopup} onClose={toggleNoBrailleDisplay} />;
    }

    return popup;
  };

  useEffect(() => {
    if (ctfJobs.length === 1) {
      dispatch(inspection.actions.setDifferenceViewOptions({ selectedModule: ctfJobs[0] }));
    }
  }, [ctfJobs]);

  const moduleVisibility = () => {
    if (ctfJobs.length === 1) {
      return <GVTypography fontWeight={600} fontSize="18px" lineHeight="26px">{`${ctfJobs[0]} Results`}</GVTypography>;
    } else if (ctfJobs.length > 1) {
      const desiredModuleOrder = [
        'All',
        CTFModuleTypes.text,
        CTFModuleTypes.spelling,
        CTFModuleTypes.graphics,
        CTFModuleTypes.barcode,
        CTFModuleTypes.braille,
      ];
      return (
        <Grid item container direction="row" className={classes.modules}>
          {(['All', ...ctfJobs] as CTFModuleTypes[])
            .sort((a, b) => {
              const indexA = desiredModuleOrder.indexOf(a);
              const indexB = desiredModuleOrder.indexOf(b);
              return indexA - indexB;
            })
            .map((name) => {
              const onClick = () => {
                dispatch(inspection.actions.setDifferenceViewOptions({ selectedModule: name }));
                switch (name) {
                  case CTFModuleTypes.spelling:
                  case CTFModuleTypes.barcode:
                  case CTFModuleTypes.braille:
                    dispatch(
                      inspection.actions.setDifferenceViewOptions({
                        hideDisplayPanels: { source: true, target: false },
                      }),
                    );
                    break;
                  default:
                    dispatch(
                      inspection.actions.setDifferenceViewOptions({
                        hideDisplayPanels: { source: false, target: false },
                      }),
                    );
                    break;
                }
              };
              return (
                <Button
                key={name}
                  className={`${classes.inspectionModule} ${
                    selectedModule === name ? classes.inspectionModuleEnabled : ''
                  }`}
                  disableRipple
                  onClick={onClick}
                >
                  {name}
                </Button>
              );
            })}
        </Grid>
      );
    }
    return null;
  };

  return (
    <Grid item container direction="column" wrap="nowrap" className={classes.root} id="resultTab">
      {!differencesLoaded || !documentsLoaded ? (
        <Grid item className={classes.options}>
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <>
          {displayPopups()}
          <ResultsTabAlerts />
          {
            <>
              <Grid item container direction="column" className={classes.options}>
                <Grid item>{moduleVisibility()}</Grid>
                <Grid display="flex">
                  <Tabs indicatorColor="secondary" value={selectedTab} onChange={handleTabs} className={classes.tabs}>
                    <Tab
                      className={classes.tab}
                      label={`Results (${differencesCount})`}
                      onClick={() => handleToggleDiscard(false)}
                    />
                    <Tab
                      className={classes.tab}
                      label={`Discarded (${discardedDifferencesCount})`}
                      onClick={() => handleToggleDiscard(true)}
                    />
                  </Tabs>
                  <Grid
                    item
                    className={classes.buttonsContainer}
                    flexDirection="row"
                    display="flex"
                    alignItems="center"
                  >
                    <OrderingList disabled={selectedModule !== 'All' && selectedModule !== CTFModuleTypes.text} />
                    <FilteredList disabled={selectedModule !== 'All' && selectedModule !== CTFModuleTypes.text} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.listOfDifferences} id="listOfDifferences">
                <ListOfDifferences onlyDiscarded={showDiscarded} selectedModule={selectedModule} />
              </Grid>
            </>
          }
        </>
      )}
    </Grid>
  );
};
export default ResultTabView;
